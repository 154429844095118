import React, { FC, useState } from 'react';
import {  MyAccountStatus } from "services";
import { FidelityForm, WidgetButton, WidgetHead } from "components";
import { useTranslation } from "react-i18next";

interface AccountFidelityProps {
}

const AccountFidelity: FC<AccountFidelityProps> = () => {
    const {t} = useTranslation();
    const [fidelity, setFidelity] = useState(MyAccountStatus.person.fidelity ?? false);

    const handleSave = () => {
        MyAccountStatus.updatePersonData_fidelity(fidelity);
    }
    return (
        <div className="account-fidelity">
            {MyAccountStatus.person.fidelity === false &&
              <>
                  <FidelityForm fidelity={fidelity} setFidelity={setFidelity}/>
                  <WidgetButton onClick={handleSave}>
                    {t("my-account-main-view.account.save")}
                  </WidgetButton>
              </>
            }
            {MyAccountStatus.person.fidelity === true &&
              <>
                <WidgetHead cssIcon="edm-icon-award-solid color-grey-dark-1">
                  {t("my-account-main-view.account.account-fidelity.title_subscribed")}
                </WidgetHead>
                <div>
                  {t("my-account-main-view.account.account-fidelity.text_subscribed")}
                </div>
              </>
            }
        </div>
    );
};

export default AccountFidelity;
