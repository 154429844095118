import React, { FC } from 'react';
import { useTranslation } from "react-i18next";
import {  WidgetButton, Notification, PersonFormFormik } from "components";
import { MyAccountStatus, PersonSchema } from "services";
import { NotificationKnowledge } from "knowledge";
import { useNotification } from "lib";
import { useFormik } from "formik";


interface AccountDataProps {
}

const validationSchema= PersonSchema.validationSchema_Form();

const AccountData: FC<AccountDataProps> = () => {
    const {t} = useTranslation();


    const {notification, setNotification, handleHideNotification} = useNotification();

    const handleSave = () => {
        const person = PersonSchema.valuesToPerson(formik.values);
        MyAccountStatus.savePerson(person).then((error) => {
            if (error) {
                setNotification("my-account-main-view.account.save-fail", NotificationKnowledge.NOTIFICATION_LEVELS.ERROR);
            } else {
                setNotification("my-account-main-view.account.save-success", NotificationKnowledge.NOTIFICATION_LEVELS.SUCCESS);
            }
        }).catch((error) => {
            console.error(error);
            setNotification("my-account-main-view.account.save-fail", NotificationKnowledge.NOTIFICATION_LEVELS.ERROR);
        });
    }
    const formik = useFormik({
        initialValues: PersonSchema.initialValuesForm(structuredClone(MyAccountStatus.person)),
        validationSchema:validationSchema,
        onSubmit: handleSave,

    });

    return (
        <div className="account-data">
            <PersonFormFormik formik={formik} isEditing={true}/>
            <div className="my-account-align-center my-account-margin-30">
                <WidgetButton disabled = {Object.keys(formik.errors).length > 0} onClick={handleSave} className="my-account-global-btn-02">{t("my-account-main-view.account.save")}</WidgetButton></div>
            <Notification input={notification} handleHideNotification={handleHideNotification}/>
        </div>
    );

}
export default AccountData;
