import { StaticParams } from "../classes/static-params";
import { URL_SOURCE } from "../url-source";

class StaticParamsService {

  private _staticParams: StaticParams;

  constructor() {
    this._staticParams = new StaticParams({});
  }

  get mode(): string {
    return this._staticParams.mode;
  }

  get idProperty(): number {
    return this._staticParams.idProperty;
  }

  get viewMode(): string {
    return this._staticParams.viewMode;
  }

  get urlNewBooking(): string {
    return this._staticParams.urlNewBooking;
  }

  get urlCheckin(): string {
    return this._staticParams.urlCheckin;
  }

  get language(): string {
    return this._staticParams.language;
  }

  get customStyle(): string {
    return this._staticParams.customStyle;
  }

  get hideNewBookingButton(): boolean {
    return this._staticParams.hideNewBookingButton;
  }

  get hideCompanionsEdition(): boolean {
    return this._staticParams.hideCompanionsEdition;
  }

  get hideVehiclesEdition(): boolean {
    return this._staticParams.hideVehiclesEdition;
  }

  get hideStayDetailEdition(): boolean {
    return this._staticParams.hideStayDetailEdition;
  }

  get hideWelcomeCustomerArea(): boolean {
    return this._staticParams.hideWelcomeCustomerArea;
  }

  async loadParams(tenantId: number) {
    try {
      const response = await fetch(`${URL_SOURCE}/rest/params/${tenantId}.json`);
      const data = await response.json()
      this._staticParams = new StaticParams(data);
    } catch (err) {
      console.error(err);
    }
  }
}

const StaticParamsInstance = new StaticParamsService();

export { StaticParamsInstance };
