import { useState } from "react";
import { NotificationInput } from "../components";

export function useNotification() {
    const [notification, setNotificationObj] = useState<NotificationInput>({tag: ''});
    const handleHideNotification = () => {
        setNotificationObj({tag: ""});
    }
    const setNotification = (tag: string, level: string | null = null) => {
        const notification: NotificationInput = level == null ? {tag} : {tag, level};
        setNotificationObj(notification);
    };
    return {notification, handleHideNotification, setNotification};
}
