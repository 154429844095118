import React, {FC} from 'react';
import {BookingDetailsDto, BookingDto} from "services/openapi";
import {DateUtils} from "lib";
import {useTranslation} from "react-i18next";
import { BookingGroupCache, MyAccountStatus } from "services";
import {WidgetButton} from "components";

interface BookingDetailDataProps {
    booking: BookingDto;
    bookingDetails: BookingDetailsDto
}


const BookingDetailData: FC<BookingDetailDataProps> = ({booking, bookingDetails}) => {

    const {t} = useTranslation();

    const begin: string | null = booking.begin != null ? DateUtils.formatDate(booking.begin) : null;
    const end: string | null = booking.end != null ? DateUtils.formatDate(booking.end) : null;

    const hasErrors = bookingDetails.precheckinErrors != null && bookingDetails.precheckinErrors.length > 0;

    const handleCheckIn = () => {
        MyAccountStatus.goToCheckin(booking);
    };

    return (
        <div className="booking-detail-data">
            {hasErrors &&
                <div className="booking-detail-data__errors booking-detail-header">
                    <i className="fa edm-icon-user-xmark-solid"></i>
                    &nbsp;&nbsp;
                    {t("my-account-main-view.stays.future.incomplete")}
                </div>
            }
            {!hasErrors &&
                <div className="booking-detail-data__sucess booking-detail-header">
                    <i className="edm-icon-user"></i>
                    {t("my-account-main-view.stays.future.complete")}
                </div>
            }
            {
                bookingDetails.site != null &&
                <div className="booking-detail-data__site my-account-global-title-and-icon">
                    <i className="edm-icon-campground"></i>
                    <strong>{bookingDetails.site}</strong>
                </div>
            }
            {
                booking.idOrder != null &&
                <div className="booking-detail-data__idOrder my-account-global-title-and-icon">
                    <i className="edm-icon-file"></i>
                    {booking.idOrder}
                </div>
            }
            {
                bookingDetails.libcateg != null &&
                <div className="booking-detail-data__libcateg my-account-global-title-and-icon">
                    <i className="edm-icon-file"></i>
                    {bookingDetails.libcateg}
                </div>
            }
            {
                begin != null && end != null &&
                <div className="booking-detail-data__calendar my-account-global-title-and-icon">
                    <i className="edm-icon-date"></i>
                    {t("my-account-main-view.stays.form.calendar", {begin: begin, end: end})}
                </div>
            }
            {
                bookingDetails.nbPers != null && bookingDetails.nbPers > 0 &&
                <div className="booking-detail-data__nbPers my-account-global-title-and-icon">
                    <i className="edm-icon-users2"></i>
                    {t("my-account-main-view.stays.form.persons", {count: bookingDetails.nbPers})}
                </div>
            }
            <div className="booking-detail-data__price my-account-global-title-and-icon">
                <i className="edm-icon-euro"></i>
                {t("my-account-main-view.stays.detail.total-amount", {price: bookingDetails.price})}
            </div>

            <div className="booking-detail-data__deposit my-account-global-title-and-icon">
                <i className="edm-icon-euro"></i>
                {t("my-account-main-view.stays.detail.deposit-pending", {deposit: (bookingDetails.balance ?? 0)})}
            </div>

            <div className="booking-detail-data__payed my-account-global-title-and-icon">
                <i className="edm-icon-euro"></i>
                {t("my-account-main-view.stays.detail.payed", {payed: (bookingDetails.payed ?? 0)})}
            </div>
            <div className="booking-detail-data__to-pay my-account-global-title-and-icon">
                <i className="edm-icon-euro"></i>
                {t("my-account-main-view.stays.detail.to-pay", {toPay: (bookingDetails.price ?? 0) - (bookingDetails.payed ?? 0)})}
            </div>

            {booking.idOrder != null && BookingGroupCache.getBookingCheckInEnabled(booking.idOrder)  &&
                <WidgetButton onClick={handleCheckIn} className="my-account-global-btn-02">
                    {t("my-account-main-view.stays.detail.check-in-online")}
                </WidgetButton>
            }
        </div>
    );
}
//  the condition to display checkin button was bookingDetails.precheckinStatus != "ok"
export default BookingDetailData;
