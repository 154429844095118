import React from 'react';
import { PersonKnowledge, UniversalNaming } from "knowledge";
import { useTranslation } from "react-i18next";



const LanguageOptions = PersonKnowledge.LANGUAGE_CODES;
const LanguageSelectorFormik = (props) => {

    const {t} = useTranslation();
    const tag = props['tag'] ?? null;
    const field = UniversalNaming.LANGUAGE;
    const isRequiredClass = PersonKnowledge.REQUIRED_FIELDS.includes(field)?' required':'' ;

    return (
        <div  className={`language-selector ${isRequiredClass}`}>
            <select {...props} >
                {LanguageOptions.map((option) =>
                    <option key={option} value={option}>{t("languages." + option)}</option>)}
            </select>
            {tag!=null  && <label className={isRequiredClass}>{t(tag)}</label>}
        </div>
    );
};

export default LanguageSelectorFormik;
