export class MonCompteApiKnowledge{

  public static readonly API_APP_NAME :string ='monCompte';
  public static readonly MY_ACCOUNT_SERVICE_PROVIDER_ID :string ='MyAccountService';

  public static readonly HTTP_HEADER_XTENANTID :string ='X-TENANT-ID';
  public static readonly HTTP_HEADER_XSESSIONID :string ='X-Session-Id';
  public static readonly HTTP_HEADER_AUTHORIZATION :string ='Authorization';

  public static readonly SESSION_STORAGE_PREFIX :string ='myaccount:';
  public static readonly SESSION_STORAGE_SESSION_KEY :string =MonCompteApiKnowledge.SESSION_STORAGE_PREFIX + 'session';
  public static readonly SESSION_STORAGE_PERSON_KEY :string =MonCompteApiKnowledge.SESSION_STORAGE_PREFIX + 'person';

  public static readonly CUSTOM_EVENTS = {
    LOGOUT: 'myaccount-logout',
    LOGIN: 'myaccount-login'
  }

}