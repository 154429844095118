import { URL_SOURCE } from "../services/url-source";

export class AssetsKnowledge {
    static IMAGES_PATH = {
        PASSWORD: `${URL_SOURCE}/assets/images/password.svg`,
        USER: `${URL_SOURCE}/assets/images/user.svg`,
        USERS: `${URL_SOURCE}/assets/images/users.svg`,
        DEFAULT: `${URL_SOURCE}/assets/images/default.png`,
        CLOSE: `${URL_SOURCE}/assets/images/close.svg`,
        LOGOUT: `${URL_SOURCE}/assets/images/logout.svg`,
        TRASH: `${URL_SOURCE}/assets/images/trash.svg`,
        AREA_SECTION_A: `${URL_SOURCE}/assets/images/areaSectionA.svg`,
        AREA_SECTION_B: `${URL_SOURCE}/assets/images/areaSectionB.svg`,
        AREA_SECTION_C: `${URL_SOURCE}/assets/images/areaSectionC.svg`,
        AREA_SECTION_D: `${URL_SOURCE}/assets/images/areaSectionD.svg`,
    }
    static FONTS_PATH = {
        WOFF2: `${URL_SOURCE}/assets/fonts/myaccount.woff2`
    }

    static MY_ACCOUNT_FONT_NAME = 'myaccount'

    static F_ACCOMMODATION_IMAGES_URL = (tenantId, accommodation) => `${URL_SOURCE}/data/${tenantId}/images/${encodeURIComponent(accommodation)}`
}
