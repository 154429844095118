import {
    BookingDetailsDto, PrecheckInDto,
    type RqDeleteShareOrder,
    RqShareOrder,
    RsCpgBookingDetails, RsCpgChangeBooking, type RsDeleteShareOrder,
    RsShareOrder,
} from "../openapi";
import i18next from "i18next";
import { MonCompteApiKnowledge } from "../../knowledge";
import { URL_SOURCE } from "../url-source";
import { MonCompteApiUtils } from "../../lib";
import { StaticParamsInstance } from "./static-params.service";

type ResponseRsv4BookingData = {
    autoCheckin: {
        checkInKey:string,
        autoCheckin: boolean,
        checkinDone: boolean,
        checkinState: number
    }
};

const SymbErrorLoaded = Symbol('errorLoaded');

export class BookingGroupCacheService {

    private _bookingDetailDataCache: { [idOder: string]: BookingDetailsDto | Symbol }
    private _bookingRsv4DataCache: { [idOder: string]: ResponseRsv4BookingData }
    private _selectedBooking: string;

    constructor() {
        this._bookingDetailDataCache = {};
        this._bookingRsv4DataCache = {};
        this._selectedBooking = null as any as string;
    }

    clearAllData() {
        this._bookingDetailDataCache = {};
        this._selectedBooking = null as any as string;
    }


    get hasSelectedBooking(): boolean {
        if (this._selectedBooking == null) {
            return false;
        }
        return this._bookingDetailDataCache[this._selectedBooking] != null
            && this._bookingDetailDataCache[this._selectedBooking] != SymbErrorLoaded;
    }

    get selectedBooking(): string {
        return this._selectedBooking;
    }

    set selectedBooking(value: string) {
        this._selectedBooking = value;
    }

    hasError(orderId: string): boolean {
        return this._bookingDetailDataCache[orderId] === SymbErrorLoaded;
    }

    isLoading(orderId: string): boolean {
        return this._bookingDetailDataCache[orderId] == null;
    }

    getBookingDetailsData(orderId: string): BookingDetailsDto {
        if (this.hasError(orderId)) {
            console.error(new Error(`booking detail ${orderId} has error`));
            return null as any as BookingDetailsDto;
        }
        return this._bookingDetailDataCache[orderId] as BookingDetailsDto;
    }

    getBookingCheckInEnabled(orderId:string):boolean{
        if (this.hasError(orderId)) {
            console.error(new Error(`booking detail ${orderId} has error`));
            return false;
        }
        return this._bookingRsv4DataCache[orderId]?.autoCheckin?.autoCheckin == true;
    }

    async preloadBookingDetails(tenantId: string, sessionId: string, orderIds: string[], reloadedFunctor: () => void) {
        for (const orderId of orderIds) {
            this.loadBookingDetails(tenantId, sessionId, orderId)//intentionally deferred
            .then(() => {
                if (reloadedFunctor != null) {
                    reloadedFunctor();
                }
            });

        }
    }

    async loadBookingDetails(tenantId: string, sessionId: string, orderId: string) {
        const language = i18next.language;
        const response: Response = await fetch(`${URL_SOURCE}/rest/order/booking/detail/${orderId}?lg=${language}`, {
            "headers": {
                [MonCompteApiKnowledge.HTTP_HEADER_XTENANTID]: tenantId,
                "content-type": "application/json",
                "x-session-id": sessionId
            },
            "method": "GET",
        });

        MonCompteApiUtils.processPossibleApiError(response);
        const responseBookingDetail: RsCpgBookingDetails = await response.json() as RsCpgBookingDetails;
        if (responseBookingDetail.error != 0) {
            console.error(new Error(responseBookingDetail.messError));
            return;
        }
        const details = responseBookingDetail.responseCpgBookingDetails;
        if (details == null || Object.keys(details).length == 0) {
            this._bookingDetailDataCache[orderId] = SymbErrorLoaded;
        } else {
            this._bookingDetailDataCache[orderId] = details;
        }

        const idProperty = StaticParamsInstance.idProperty;
        const responseFromRsv4 = await fetch(`https://rsv4.mastercamping.com/api/myAccount/stay/${orderId}?idProperty=${idProperty}`);
        const responseRsv4Booking: ResponseRsv4BookingData = await responseFromRsv4.json() as ResponseRsv4BookingData;
        this._bookingRsv4DataCache[orderId] = responseRsv4Booking;

    }

    async reloadSelected(tenantId: string, sessionId: string) {
        if (this._selectedBooking != null) {
            await this.loadBookingDetails(tenantId, sessionId, this._selectedBooking);
        }
    }


    async shareSelectedBooking(tenantId: string, sessionId: string, email: string): Promise<null | Error> {

        const body: RqShareOrder = {
            email: email,
            idOrder: this._selectedBooking
        };
        const response = await fetch(`${URL_SOURCE}/rest/order/share`, {
            "headers": {
                [MonCompteApiKnowledge.HTTP_HEADER_XTENANTID]: tenantId,
                "content-type": "application/json",
                "x-session-id": sessionId
            },
            "method": "POST",
            "body": JSON.stringify(body)
        });
        MonCompteApiUtils.processPossibleApiError(response);
        const responseUpdateVehicles: RsShareOrder = await response.json() as RsShareOrder;
        if (responseUpdateVehicles.error != 0) {
            return new Error(responseUpdateVehicles.messError);
        }
        return null;
    }

    async revokeShareBooking(tenantId: string, sessionId: string, email: string) {
        const body: RqDeleteShareOrder = {
            email: email,
            idOrder: this._selectedBooking
        };
        const response = await fetch(`${URL_SOURCE}/rest/order/share`, {
            "headers": {
                [MonCompteApiKnowledge.HTTP_HEADER_XTENANTID]: tenantId,
                "content-type": "application/json",
                "x-session-id": sessionId
            },
            "method": "DELETE",
            "body": JSON.stringify(body)
        });
        MonCompteApiUtils.processPossibleApiError(response);
        const responseUpdateVehicles: RsDeleteShareOrder = await response.json() as RsDeleteShareOrder;
        if (responseUpdateVehicles.error != 0) {
            return new Error(responseUpdateVehicles.messError);
        }
        return null;
    }


    async saveBookingDetails(tenantId: string, sessionId: string, idOrder: string , bookingDetails: BookingDetailsDto) {
        const body: PrecheckInDto = {
            friends: bookingDetails.friends,
            vehicles: bookingDetails.vehicles,
        };
        const language = i18next.language;
        const response = await fetch(`${URL_SOURCE}/rest/order/booking/detail/${idOrder}?lg=${language}`, {
            "headers": {
                [MonCompteApiKnowledge.HTTP_HEADER_XTENANTID]: tenantId,
                "content-type": "application/json",
                "x-session-id": sessionId
            },
            "method": "POST",
            "body": JSON.stringify(body)
        });

        MonCompteApiUtils.processPossibleApiError(response);
        const responseUpdateBookingDetails: RsCpgChangeBooking = await response.json() as RsCpgChangeBooking;
        if (responseUpdateBookingDetails.error != 0) {
            return new Error(responseUpdateBookingDetails.messError);
        }
        await this.loadBookingDetails(tenantId, sessionId,idOrder);
        return null;
    }
}

const BookingGroupCache = new BookingGroupCacheService();
export { BookingGroupCache };

