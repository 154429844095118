import React, { FC } from 'react';
import { useTranslation } from "react-i18next";

interface FormFieldErrorProps {
    formik: any;
    field: string;
}

const FormFieldError: FC<FormFieldErrorProps> = ({formik, field}) => {
    const {t} = useTranslation();
    const meta = formik.getFieldMeta(field);
    const hasError = (meta.touched) && meta.error != null;
    if(!hasError){
        return null;
    }
    const error = meta.error;

    return (
        <div className="form-field-error">
            {t(error)}
        </div>
    );
};

export default FormFieldError;
