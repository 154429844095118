import React, { FC, useState } from 'react';
import { useTranslation } from "react-i18next";
import { MyAccountStatus, PasswordGroup } from "services";
import { Notification, PasswordInput, WidgetButton, WidgetHead } from "components";
import { NotificationKnowledge } from "knowledge";
import { useNotification } from "lib";


interface AccountChangePasswordProps {
}


const AccountChangePassword: FC<AccountChangePasswordProps> = () => {
    const {t} = useTranslation();
    const [state, setstate] = useState<PasswordGroup>(PasswordGroup.generateInitialState());
    const {notification, setNotification, handleHideNotification} = useNotification();

    const handleCurrentPasswordChange = (newValue) => {
        const newState = state.setCurrentPassword(newValue);
        setstate(newState);
    };

    const handleNewPasswordChange = (newValue) => {
        const newState = state.setNewPassword(newValue);
        setstate(newState);
    };

    const handleConfirmPasswordChange = (newValue) => {
        const newState = state.setConfirmPassword(newValue);
        setstate(newState);
    };
    const handleSave = () => {
        MyAccountStatus.changePassword(state).then((error) => {
            if (error) {
                setNotification("my-account-main-view.account.save-fail", NotificationKnowledge.NOTIFICATION_LEVELS.ERROR);
            } else {
                setNotification("my-account-main-view.account.save-success", NotificationKnowledge.NOTIFICATION_LEVELS.SUCCESS);
            }
        }).catch((error) => {
            setNotification("my-account-main-view.account.save-fail", NotificationKnowledge.NOTIFICATION_LEVELS.ERROR);
        });
    };

    return (
        <div className="account-change-password">
            <WidgetHead cssIcon="edm-icon-password color-grey-dark-1">
                {t("my-account-main-view.account.account-password.title")}
            </WidgetHead>
            <div className="account-change-password__advise">
                {t("my-account-main-view.account.account-password.text")}
            </div>
            <div className="account-change-password__form">
                <div className="account-change-password__form-inputs">
                    <PasswordInput tag={"my-account-main-view.account.account-password.current-password"}
                                status={state.currentPasswordStatus}
                                value={state.currentPassword} errors={state.currentPasswordErrors}
                                onChange={handleCurrentPasswordChange}/>

                    <PasswordInput tag={"my-account-main-view.account.account-password.new-password"}
                                status={state.newPasswordStatus}
                                value={state.newPassword} errors={state.newPasswordErrors}
                                onChange={handleNewPasswordChange}/>

                    <PasswordInput tag={"my-account-main-view.account.account-password.confirm-password"}
                                status={state.confirmPasswordStatus}
                                value={state.confirmPassword} errors={state.confirmPasswordErrors}
                                onChange={handleConfirmPasswordChange}/>
                </div>
                <div className="account-change-password__buttons my-account-margin-30">
                    <WidgetButton disabled={!state.isOk}
                                onClick={handleSave} className="my-account-global-btn-02">{t("my-account-main-view.account.save")}</WidgetButton>
                </div>
            </div>
            <Notification input={notification} handleHideNotification={handleHideNotification}/>
        </div>
    );
}

export default AccountChangePassword;
