import React, { FC } from 'react';
import { BookingDetailsDto } from "services/openapi";
import { useTranslation } from "react-i18next";
import { BookingKnowledge } from "knowledge";

interface BookingDetailProfileErrorsProps {
    bookingDetails: BookingDetailsDto
}

const BookingDetailProfileErrors: FC<BookingDetailProfileErrorsProps> = ({bookingDetails}) => {

    const {t} = useTranslation();

    const hasError:boolean = bookingDetails.precheckinErrors != null && bookingDetails.precheckinErrors.length > 0;
    if (!hasError) {
        return null;
    }
    const fHasError = (error)=>(bookingDetails.precheckinErrors || []).some((e)=>e.error === error);

    return (
        <div className="booking-detail-profile-errors">
            <div className="booking-detail-profile-errors__text color-red">
                {t("my-account-main-view.stays.detail.profile-data.error-text")}
            </div>
            <div className="booking-detail-profile-errors__list">
                <ul>
                {
                fHasError(BookingKnowledge.PRE_CHECKIN_ERRORS.BALANCE_UNPAID) &&
                <li className="booking-detail-profile-errors__item">
                    {t("my-account-main-view.stays.detail.profile-data.stay-section")}
                </li>
                }
                </ul>
            </div>
        </div>
    );
}

export default BookingDetailProfileErrors;
