import React, { FC } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { ContextCampingDto } from "services/openapi";
import { useTranslation } from "react-i18next";
import ContactRequest from "./components/ContactRequest/ContactRequest";
import { MyAccountStatus } from "services";
import { WidgetHead } from "components";

interface ContactProps {
}

const Contact: FC<ContactProps> = () => {

    const {t} = useTranslation();

    const campingData: ContextCampingDto = MyAccountStatus.propertyInfo;

    const latitude: number | null = campingData.latitude ?? null;
    const longitude: number | null = campingData.longitude ?? null;

    const email: string | null = campingData.email != null && campingData.email !== '' ? campingData.email : null;
    const phone: string | null = campingData.phone != null && campingData.phone !== '' ? campingData.phone : null;


    return (
        <div className="contact padding-standard-1">
          <div className="contact-column">
            <div className="contact-data">
                {campingData.name != null && <div className="contact-data-address">
                    <WidgetHead 
                      className="contact-data-address_tittle" 
                      cssIcon="edm-icon-location-1 color-grey-dark-1"
                      >
                      {campingData.name}
                    </WidgetHead>
                    <div className="contact-data-address_address">{campingData.address}</div>
                    <div className="contact-data-address_address">{campingData.address2}</div>
                    <div className="contact-data-address_town">
                        <span>{campingData.postcode}</span>
                        <span>{' ' + campingData.city}</span>
                    </div>
                </div>}
                {latitude != null && longitude != null &&
                  <div className="contact-data-coordinates">
                      <WidgetHead
                        className="contact-data-coordinates_tittle"
                        cssIcon="edm-icon-map-marked-alt color-grey-dark-1"
                        >
                        {t("my-account-main-view.contact.coordinates")}
                      </WidgetHead>
                      <div><span>{t("my-account-main-view.contact.latitude")}</span><span>{latitude}</span></div>
                      <div><span>{t("my-account-main-view.contact.longitude")}</span><span>{longitude}</span></div>
                  </div>
                }
            </div>
            {(phone != null || email != null) &&
              <div className="contact-data-contact">
                  <WidgetHead 
                    className="contact-data-coordinates_tittle"
                    cssIcon="edm-icon-phone-square-alt color-grey-dark-1"
                    >
                    {t("my-account-main-view.contact.contact")}
                  </WidgetHead>
                  {phone != null &&
                    <div><span>{t("my-account-main-view.contact.phone")}</span><span>{phone}</span></div>}
                  {email != null &&
                    <div><span>{t("my-account-main-view.contact.email")}</span><span>{email}</span></div>}
              </div>
            }

            <ContactRequest/>

          </div>
          <div className="contact-column">
          {latitude != null && longitude != null &&
              <div className="contact-data-map">
                  <MapContainer center={[latitude, longitude]} zoom={13} scrollWheelZoom={false}>
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      <Marker position={[latitude, longitude]}>
                          {campingData.name != null &&
                            <Popup>
                                {campingData.name}
                            </Popup>}
                      </Marker>
                  </MapContainer>
              </div>
          }
          </div>
        </div>
    );
};

export default Contact;
