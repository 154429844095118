import { PasswordKnowledge as PwdK } from "knowledge";
import { FormUtils } from "lib";


export class PasswordGroup {

    private _currentPassword: string;
    private _newPassword: string;
    private _confirmPassword: string;

    private _currentPasswordErrors: string[];
    private _newPasswordErrors: string[];
    private _confirmPasswordErrors: string[];

    private _currentPasswordStatus: string;
    private _newPasswordStatus: string;
    private _confirmPasswordStatus: string;


    constructor() {
        this._currentPassword = '';
        this._newPassword = '';
        this._confirmPassword = '';

        this._currentPasswordErrors = [];
        this._newPasswordErrors = [];
        this._confirmPasswordErrors = [];

        this._currentPasswordStatus = '';
        this._newPasswordStatus = '';
        this._confirmPasswordStatus = '';
    }


    //<editor-fold desc="Getters">
    get currentPassword(): string {
        return this._currentPassword;
    }

    get newPassword(): string {
        return this._newPassword;
    }

    get confirmPassword(): string {
        return this._confirmPassword;
    }

    get currentPasswordErrors(): string[] {
        return this._currentPasswordErrors;
    }

    get newPasswordErrors(): string[] {
        return this._newPasswordErrors;
    }

    get confirmPasswordErrors(): string[] {
        return this._confirmPasswordErrors;
    }


    get currentPasswordStatus(): string {
        return this._currentPasswordStatus;
    }

    get newPasswordStatus(): string {
        return this._newPasswordStatus;
    }

    get confirmPasswordStatus(): string {
        return this._confirmPasswordStatus;
    }

    //</editor-fold>

    private processErrors() {
        //process errors
        this._currentPasswordErrors = PasswordGroup.processErrors_singlePassword(this._currentPassword);
        this._newPasswordErrors = PasswordGroup.processErrors_singlePassword(this._newPassword);
        this._confirmPasswordErrors = PasswordGroup.processErrors_singlePassword(this._confirmPassword);

        if (this._newPassword !== this._confirmPassword) {
            if (this._newPassword.length > 0) {
                this._newPasswordErrors.push(PwdK.PASSWORD_ERRORS.NON_MATCHING);
            }

            if (this._confirmPassword.length > 0) {
                this._confirmPasswordErrors.push(PwdK.PASSWORD_ERRORS.NON_MATCHING);
            }

        }

        //process status
        this._currentPasswordStatus = PasswordGroup.processStatus_singlePassword(this._currentPassword);
        this._newPasswordStatus = PasswordGroup.processStatus_singlePassword(this._newPassword);
        this._confirmPasswordStatus = PasswordGroup.processStatus_singlePassword(this._confirmPassword);
    }


    setCurrentPassword(value: string) {
        const result = this.clone();
        result._currentPassword = value;
        result.processErrors();
        return result;
    }

    setNewPassword(value: string) {
        const result = this.clone();
        result._newPassword = value;
        result.processErrors();
        return result;
    }

    setConfirmPassword(value: string) {
        const result = this.clone();
        result._confirmPassword = value;
        result.processErrors();
        return result;
    }

    get isOk(): boolean {
        return this._currentPasswordErrors.length === 0 && this._newPasswordErrors.length === 0 && this._confirmPasswordErrors.length === 0;
    }
    get isOkOnlyNew(): boolean {
        return  this._newPasswordErrors.length === 0 && this._confirmPasswordErrors.length === 0;
    }

    clone() {
        const dolly = new PasswordGroup();

        dolly._currentPassword = this._currentPassword;
        dolly._newPassword = this._newPassword;
        dolly._confirmPassword = this._confirmPassword;
        dolly._currentPasswordErrors = [...this._currentPasswordErrors];
        dolly._newPasswordErrors = [...this._newPasswordErrors];
        dolly._confirmPasswordErrors = [...this._confirmPasswordErrors];
        dolly._currentPasswordStatus = this._currentPasswordStatus;
        dolly._newPasswordStatus = this._newPasswordStatus;
        dolly._confirmPasswordStatus = this._confirmPasswordStatus;

        return dolly;
    }

    static generateInitialState(): PasswordGroup {
        return new PasswordGroup();
    }

    private static processErrors_singlePassword(password: string): string[] {
        const result: string[] = [];
        if (password.length < PwdK.PASSWORD_MIN_LENGTH) {
            result.push(PwdK.PASSWORD_ERRORS.TOO_SHORT);
        }
        if (FormUtils.countLowerCase(password) < PwdK.PASSWORD_MIN_LOWERCASE) {
            result.push(PwdK.PASSWORD_ERRORS.MISSING_LOWERCASE);
        }
        if (FormUtils.countUpperCase(password) < PwdK.PASSWORD_MIN_UPPERCASE) {
            result.push(PwdK.PASSWORD_ERRORS.MISSING_UPPERCASE);
        }
        if (FormUtils.countNumber(password) < PwdK.PASSWORD_MIN_NUMBER) {
            result.push(PwdK.PASSWORD_ERRORS.MISSING_NUMBER);
        }
        if (FormUtils.countSymbol(password) < PwdK.PASSWORD_MIN_SYMBOL) {
            result.push(PwdK.PASSWORD_ERRORS.MISSING_SYMBOL);
        }
        return result;
    }

    private static processStatus_singlePassword(password: string): string {

        if (password.length == 0) {
            return PwdK.PASSWORD_STATUS.EMPTY;
        }

        if (password.length < PwdK.PASSWORD_MIN_LENGTH) {
            return PwdK.PASSWORD_STATUS.ERROR;
        }

        if (
            (FormUtils.countLowerCase(password) < PwdK.PASSWORD_MIN_LOWERCASE) ||
            (FormUtils.countUpperCase(password) < PwdK.PASSWORD_MIN_UPPERCASE) ||
            (FormUtils.countNumber(password) < PwdK.PASSWORD_MIN_NUMBER) ||
            (FormUtils.countSymbol(password) < PwdK.PASSWORD_MIN_SYMBOL)
        ) {
            return PwdK.PASSWORD_STATUS.WARNING;
        }


        return PwdK.PASSWORD_STATUS.CORRECT;
    }
}

