import React, { FC } from 'react';
import { MyAccountStatus } from "services";
import { ModalLoader } from "components";
import ModalHeader from "./ModalHeader/ModalHeader";
import ModalNoLogged from "./NonLogged/ModalNoLogged";
import ModalLogged from "./Logged/ModalLogged";


import { WidgetConfig } from "services";

interface MyAccountPrincipalModalProps {
    isOpen: boolean
    isLogged: boolean
}

const MyAccountModal: FC<MyAccountPrincipalModalProps> = ({isOpen, isLogged}) => {
    const image = WidgetConfig.backgroundPictureUrl ?? '';
    const [imageState, setImageState] = React.useState(image);
    const [hideBackgroundImg, setHideBackgroundImg] = React.useState(false);
    if(imageState !== image) {
        setImageState(image);
        setHideBackgroundImg(false);
    }
    /*
    const styles = {};
    if (!isOpen) {
        styles['display'] = "none";
    }
    */



    const onImageError = () => {
        setHideBackgroundImg(true);
    }
    return (
        <div 
            className={`my-account-principal-modal${!isOpen ? ' display-opacity-hidden' : ''}`} 
            // style={styles}
            >
            <ModalHeader isLogged={isLogged}></ModalHeader>
            {!hideBackgroundImg &&
              <div className="principal-modal__background-picture-url">
                  <img src={image} onError={onImageError}/>
              </div>
            }
            {MyAccountStatus.isLoading && <ModalLoader/>}
            {!MyAccountStatus.isLoading && isLogged && <ModalLogged/>}
            {!MyAccountStatus.isLoading && !isLogged && <ModalNoLogged/>}


        </div>
    );
}

export default MyAccountModal;
