import React, { FC } from 'react';
import { useTranslation } from "react-i18next";
import { WidgetHead } from "components";
import { AssetsKnowledge } from 'knowledge';
import { MyAccountStatus } from "services";

interface PrincipalModalCustomerAreaProps {
}

const CustomerAreaSection = ({title, css, image, text}) => (
    <section className="principal-modal-customer-area__item">
        <i className={css}></i>
        {/*<ImageWithValidator picture={image} ></ImageWithValidator>-*/}
        <WidgetHead size={3}>{title}</WidgetHead>
        <div>{text}</div>
    </section>
);
const PrincipalModalCustomerArea: FC<PrincipalModalCustomerAreaProps> = () => {
    const {t} = useTranslation();

    const customerAreaSections = [
        {
            title: t('my-account-main-view.customer-area.sections.my-dossier.title'),
            css: 'edm-icon-laptop-house-solid widget-font-color-2',
            image: AssetsKnowledge.IMAGES_PATH.AREA_SECTION_A,
            text: t("my-account-main-view.customer-area.sections.my-dossier.text")
        },
        {
            title: t('my-account-main-view.customer-area.sections.my-history.title'),
            css: 'edm-icon-history-solid widget-font-color-2',
            image: AssetsKnowledge.IMAGES_PATH.AREA_SECTION_B,
            text: t("my-account-main-view.customer-area.sections.my-history.text")
        },
        {
            title: t('my-account-main-view.customer-area.sections.my-details.title'),
            css: 'edm-icon-user-edit-solid widget-font-color-2',
            image: AssetsKnowledge.IMAGES_PATH.AREA_SECTION_C,
            text: t("my-account-main-view.customer-area.sections.my-details.text")
        }
    ];

    if (MyAccountStatus.isFidelitySubscribed) {
        customerAreaSections.push({
            title: t('my-account-main-view.customer-area.sections.my-advantages.title'),
            css: 'edm-icon-award-solid widget-font-color-2',
            image: AssetsKnowledge.IMAGES_PATH.AREA_SECTION_D,
            text: t("my-account-main-view.customer-area.sections.my-advantages.text")
        })
    }


    return (
        <>
            <div className="principal-modal-customer-area-separator">
                <div className="principal-modal-customer-area-separator__dot"></div>
            </div>
            <div className="principal-modal-customer-area">
                <WidgetHead size={2}>{t('my-account-main-view.customer-area.title')}</WidgetHead>
                <div className="principal-modal-customer-area__items">
                    {customerAreaSections.map((item) => <CustomerAreaSection key={item.title} {...item}/>)}
                </div>
            </div>
        </>
    );
}

export default PrincipalModalCustomerArea;
