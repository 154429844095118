import React, { FC } from 'react';
import { useTranslation } from "react-i18next";
import { WidgetButton, WidgetHead } from "components";
import { MyAccountStatus } from "services";

interface ModalNoStaysProps {}

const ModalNoStays: FC<ModalNoStaysProps> = () => {
    const {t} = useTranslation();
    const handleSearch = () => {
        MyAccountStatus.searchNewBooking();
    };

    return (
        <div className="modal-no-stays padding--1">
            <i className="edm-icon-caravan"></i>
            <WidgetHead size={4}>{t("my-account-main-view.stays.no-stays.text")}</WidgetHead>
            {MyAccountStatus.hasUrlNewBooking && <WidgetButton className="my-account-global-btn-02" onClick={handleSearch}>{t("my-account-main-view.stays.no-stays.button")}</WidgetButton>}
        </div>
    );
};

export default ModalNoStays;
