import React, { FC } from 'react';
import { useTranslation } from "react-i18next";
import { WidgetHead } from "components";
import { MyAccountStatus } from "services";
import { FidStreamDto } from "services/openapi";
import { DateUtils } from "lib";


interface FidelityHistoryProps {
}

const FidelityHistory: FC<FidelityHistoryProps> = () => {
    const {t} = useTranslation();

    const fidelityData = MyAccountStatus.fidelityData;
    const list: FidStreamDto[] = fidelityData.list || [];

    const parseStayDate = (date: string | undefined) => date == null || date === "" ? "" : DateUtils.formatDate(date);
    const parseRangeDate = (stay: FidStreamDto):string => {
        const begin = stay.begin == null || stay.end === "" ? null : DateUtils.formatDate(stay.begin);
        const end = stay.end == null || stay.end === "" ? null : DateUtils.formatDate(stay.end);
        if(begin == null || end == null) {
            return "";
        }
        return t("my-account-main-view.fidelity.history.date-range", {begin, end});
    };

    return (
        <div className="fidelity-history">
            <WidgetHead cssIcon="edm-icon-list color-grey-dark-1">{t("my-account-main-view.fidelity.history.title")}</WidgetHead>
            <div className="fidelity-history-table">
                <table>
                    <thead>
                    <tr className="idelity-history-table__header-row">
                        <th>{t("my-account-main-view.fidelity.history.gain-date")}</th>
                        <th>{t("my-account-main-view.fidelity.history.gain-point")}</th>
                        <th>{t("my-account-main-view.fidelity.history.gain-place")}</th>
                        <th>{t("my-account-main-view.fidelity.history.stay-date")}</th>
                        <th>{t("my-account-main-view.fidelity.history.accommodation")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {list.map((item, index) => (
                        <tr key={index}>
                            <td>{parseStayDate(item.date)}</td>
                            <td>{item.value ?? 0}</td>
                            <td>{item.site ?? ''}</td>
                            <td>{parseRangeDate(item)}</td>
                            <td>{item.lodging ?? ''}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default FidelityHistory;
