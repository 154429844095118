import React, { FC } from 'react';
import { BookingGroupCache, MyAccountStatus } from "services";
import { ImageWithValidator } from "../index";

interface AccommodationTypePictureProps {
    idOrder: string | undefined;
}

const AccommodationTypePicture: FC<AccommodationTypePictureProps> = ({idOrder}) => {
    if (idOrder == null || idOrder == '') {
        return null;
    }
    const isLoading = BookingGroupCache.isLoading(idOrder);
    if (isLoading) {
        return <div className="accommodation-picture-loading">
            <div className="accommodation-picture-loading__loader"></div>
        </div>
    }


    const picture =  MyAccountStatus.generateImagePath(idOrder);
    return (
        <ImageWithValidator picture={picture}></ImageWithValidator>
    );
};

export default AccommodationTypePicture;
