import React, { FC, useState } from 'react';
import { useTranslation } from "react-i18next";
import { type FriendDto } from "services/openapi";
import { BookingDetailFriendItem, WidgetHead } from "components";

interface BookingDetailEditFriendsProps {
    friends: FriendDto[];
    hasAll:boolean;
    onChange:(friend:FriendDto[])=>void;
}

const BookingDetailEditFriends: FC<BookingDetailEditFriendsProps> = ({friends,hasAll,onChange}) => {

    const {t} = useTranslation();


    const handleDeleteFriend = (id: number) => () => {
        friends[id] = {};
        onChange([...friends]);
    }
    const handleChange = () => {
        onChange([...friends]);
    }

    return (
        <div className="booking-detail-edit-friends">
            <div className="booking-detail-edit-friends__title">
                <WidgetHead size={3}>{t("my-account-main-view.stays.detail.profile-data.friends-section")}
                    <span
                        className="booking-detail-edit-friend__asterisk">*</span>
                    {hasAll ? <i className="edm-icon-circle-check-solid"></i> :
                        <i className="edm-icon-circle-xmark-solid"></i>}
                </WidgetHead>
            </div>
            {friends.map((f, i) => <BookingDetailFriendItem key={i} friend={f} handleChange={handleChange}
                                                                handleDelete={handleDeleteFriend(i)}/>)}
        </div>
    );
};

export default BookingDetailEditFriends;
