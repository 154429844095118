import React from 'react';
import { useTranslation } from "react-i18next";
import { PersonKnowledge, UniversalNaming } from "knowledge";


const CountryOptions = PersonKnowledge.COUNTRY_CODES;

const CountrySelectorFormik = (props) => {
    const tag = props['tag'] ?? null;
    const {t} = useTranslation();

    const field = UniversalNaming.COUNTRY;
    const isRequiredClass = PersonKnowledge.REQUIRED_FIELDS.includes(field)?' required':'' ;

    return (
        <div className={`country-selector ${isRequiredClass}`}>
            <select {...props}>

                {CountryOptions.map((option) =>
                    <option key={option}
                            value={option}>{t("counties." + option)}</option>)}
            </select>
            {tag != null && <label className={isRequiredClass}>{t(tag)}</label>}
        </div>
    );
}

export default CountrySelectorFormik;
