import React, { FC, useState } from 'react';
import { useTranslation } from "react-i18next";
import { MyAccountStatus, StaticParamsInstance } from "services";
import { StatusKnowledge } from "knowledge";
import { BookingDetailsDto, BookingDto } from "services/openapi";
import BookingDetailData from "./components/BookingDetailData/BookingDetailData";
import BookingDetailComposition from "./components/BookingDetailComposition/BookingDetailComposition";
import BookingDetailShare from "./components/BookingDetailShare/BookingDetailShare";
import BookingDetailProfile from "./components/BookingDetailProfile/BookingDetailProfile";

interface BookingDetailProps {
}

const BookingDetail: FC<BookingDetailProps> = () => {
    const {t} = useTranslation();
    const [bookingDetail, setBookingDetail] = useState<BookingDetailsDto>(MyAccountStatus.selectedBookingDetailsData);
    const [booking, setBooking] = useState<BookingDto>(MyAccountStatus.selectedBooking);
    if (bookingDetail == null || booking == null) {
        console.error("bookingDetail is null");
        MyAccountStatus.navigate(StatusKnowledge.VIEW_SECTIONS.LOGGED.STAYS);
    }
    const handleGoBack = () => {
        MyAccountStatus.navigate(StatusKnowledge.VIEW_SECTIONS.LOGGED.STAYS);
    };
    const handleUpdateBookingDetail = () => {
        setBookingDetail(MyAccountStatus.selectedBookingDetailsData);
    }

    return (
        <div className="booking-detail">
            <div className="booking-detail-wrapper padding-standard-1">
                <div onClick={handleGoBack} className="booking-detail-return widget-font-color-2">
                    <i className="edm-icon-left-dir"></i>
                    <span>
                        {t("my-account-main-view.stays.detail.button-go-back")}
                    </span>
                </div>
                <div className="booking-detail-booking-data">
                    <BookingDetailData booking={booking} bookingDetails={bookingDetail}/>
                </div>
            </div>
            <div className="booking-detail__content">
                {StaticParamsInstance.hideStayDetailEdition==false && <BookingDetailProfile bookingDetails={bookingDetail} booking={booking}/>}
                <BookingDetailComposition bookingDetails={bookingDetail}/>
                <BookingDetailShare handleUpdateBookingDetail={handleUpdateBookingDetail} bookingDetails={bookingDetail}/>
            </div>

        </div>
    );
};

export default BookingDetail;
