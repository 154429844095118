import React from 'react';
import { WidgetConfig } from "services";



const SiteInfoButton = () => {
    if(WidgetConfig.hasInfoButton!=true){
        return null;
    }
    return (
        <div className="site-info-button position-relative">
            <a href={WidgetConfig.infoButtonUrl} target="_blank" className="my-account-global-btn-02">
                <i className="edm-icon-link-ext-1 padding-right-5px"></i>
                {WidgetConfig.infoButtonLabel}
            </a>
        </div>
    );
};

export default SiteInfoButton;
