import React, {FC, useState} from 'react';
import {useTranslation} from "react-i18next";
import {NotificationKnowledge, StatusKnowledge} from "knowledge";
import {useNotification} from "lib";
import {
    FidelityForm,
    Notification,
    PasswordInput,
    PersonFormFormik,
    WidgetButton,
    WidgetHead
} from "components";
import {MyAccountStatus, PasswordGroup, PersonSchema} from "services";
import {useFormik} from "formik";


interface PrincipalModelRegisterAccountProps {
}

const validationSchema = PersonSchema.validationSchema_Form();
const PrincipalModalRegisterAccount: FC<PrincipalModelRegisterAccountProps> = () => {

    const {t} = useTranslation();
    const [fidelity, setFidelity] = useState<boolean>(false);
    const [password, setPassword] = useState<PasswordGroup>(PasswordGroup.generateInitialState());
    const {notification, setNotification, handleHideNotification} = useNotification();


    const handleSave = () => {

        const person = PersonSchema.valuesToPerson(formik.values);
        MyAccountStatus.registerAccount(person, fidelity, password).then((error) => {
            if (error) {
                setNotification("my-account-main-view.account.save-fail", NotificationKnowledge.NOTIFICATION_LEVELS.ERROR);
            } else {
                setNotification("my-account-main-view.account.save-success", NotificationKnowledge.NOTIFICATION_LEVELS.SUCCESS);
            }
        }).catch((error) => {
            setNotification("my-account-main-view.account.save-fail", NotificationKnowledge.NOTIFICATION_LEVELS.ERROR);
        });
    };

    const formik = useFormik({
        initialValues: PersonSchema.initialValuesForm(),
        validationSchema: validationSchema,
        onSubmit: handleSave,

    });


    const handleNewPasswordChange = (newValue) => {
        const newState = password.setNewPassword(newValue);
        setPassword(newState);
    };

    const handleConfirmPasswordChange = (newValue) => {
        const newState = password.setConfirmPassword(newValue);
        setPassword(newState);
    };


    const handleCancel = () => {
        MyAccountStatus.navigate(StatusKnowledge.VIEW_SECTIONS.NON_LOGGED.NON_LOGGED_MAIN);
    };

    return (
        <div className="principal-modal-register-account">
            <PersonFormFormik formik={formik} isEditing={false}/>
            {MyAccountStatus.isFidelitySubscribed && <FidelityForm fidelity={fidelity} setFidelity={setFidelity}/>}
            <div className="principal-modal-register-account-password">
                <WidgetHead cssIcon="edm-icon-vehicle color-grey-dark-1">
                    {t("my-account-main-view.account.account-password.title")}
                </WidgetHead>
                <div
                    className="account-change-password__advise">{t("my-account-main-view.account.account-password.text")}</div>
                <div className="account-change-password__inputs">
                    <PasswordInput tag={"my-account-main-view.account.account-password.new-password"}
                                   status={password.newPasswordStatus}
                                   value={password.newPassword} errors={password.newPasswordErrors}
                                   onChange={handleNewPasswordChange}/>

                    <PasswordInput tag={"my-account-main-view.account.account-password.confirm-password"}
                                   status={password.confirmPasswordStatus}
                                   value={password.confirmPassword} errors={password.confirmPasswordErrors}
                                   onChange={handleConfirmPasswordChange}/>
                </div>
            </div>
            <div className="principal-modal-register-account-buttons">
                <button onClick={handleCancel}
                        className="my-account-global-btn-01">{t("my-account-main-view.new-account.cancel")}</button>
                <WidgetButton disabled={Object.keys(formik.errors).length > 0 || !password.isOkOnlyNew}
                              onClick={handleSave}
                              className="my-account-global-btn-02">{t("my-account-main-view.new-account.save")}</WidgetButton>
            </div>
            <Notification input={notification} handleHideNotification={handleHideNotification}/>
        </div>
    );
}

export default PrincipalModalRegisterAccount;
