import React, { FC, useState } from 'react';
import { useTranslation } from "react-i18next";
import { FriendsGroup, MyAccountStatus } from 'services';
import { NotificationKnowledge, StatusKnowledge } from "knowledge";
import { BookingDetailsDto, BookingDto, FriendDto } from "services/openapi";
import { BookingDetailProfileBalance, WidgetButton, Notification } from "components";
import { useNotification } from "lib";
import BookingDetailEditData from "./components/BookingDetailEditData/BookingDetailEditData";
import BookingDetailEditFriends from "./components/BookingDetailEditFriends/BookingDetailEditFriends";


const BookingDetailEdit = () => {
    const {t} = useTranslation();

    const {notification, setNotification, handleHideNotification} = useNotification();
    const [bookingDetails, setBookingDetails] = useState<BookingDetailsDto>(structuredClone(MyAccountStatus.selectedBookingDetailsData));
    const booking: BookingDto = MyAccountStatus.selectedBooking;

    const {
        friends,
        hasAll
    } = FriendsGroup.generateBookingDetailsFriendsGroup(bookingDetails.friends ?? [], bookingDetails?.nbPers || 0);

    const handleChangeFriends = (friends: FriendDto[]) => {
        bookingDetails.friends = friends;
        setBookingDetails({...bookingDetails});
    }
    const handleBack = () => {
        MyAccountStatus.navigate(StatusKnowledge.VIEW_SECTIONS.LOGGED.BOOKING_DETAILS);
    };

    const handleSave = () => {
        MyAccountStatus.saveBookingDetails(booking.idOrder as string, bookingDetails)
        .then((error) => {
            if (error) {
                setNotification("my-account-main-view.account.save-fail", NotificationKnowledge.NOTIFICATION_LEVELS.ERROR);
            } else {
                setNotification("my-account-main-view.account.save-success", NotificationKnowledge.NOTIFICATION_LEVELS.SUCCESS);
            }
        }).catch((error) => {
            setNotification("my-account-main-view.account.save-fail", NotificationKnowledge.NOTIFICATION_LEVELS.ERROR);
        });
    }
    return (
        <div className="booking-detail-edit">
            <div className="padding-standard-1">
                <div className="booking-detail-edit-profile__header">
                    {t("my-account-main-view.stays.detail.profile-data-edit.profile.title")}
                </div>
                <button onClick={handleBack}
                        className="booking-detail-edit__back-button">
                            <i className="edm-icon-left-dir"></i>
                            {t("my-account-main-view.stays.detail.profile-data-edit.button-return-text")}
                        </button>
                <BookingDetailEditData bookingDetails={bookingDetails} booking={booking}/>
                <div className="booking-detail-edit-profile__prepare-text">
                    <p>{t("my-account-main-view.stays.detail.profile-data-edit.profile.prepare-1")}</p>
                    <p>{t("my-account-main-view.stays.detail.profile-data-edit.profile.prepare-2")}</p>
                </div>
                <BookingDetailEditFriends friends={friends} hasAll={hasAll} onChange={handleChangeFriends}/>
                <BookingDetailProfileBalance bookingDetails={bookingDetails}/>
                <div className="booking-detail-edit-profile__footer-text">
                    <p>{t("my-account-main-view.stays.detail.profile-data-edit.profile.footer-1")}</p>
                    <p>{t("my-account-main-view.stays.detail.profile-data-edit.profile.footer-2")}</p>
                </div>
                <div className="booking-detail-edit-profile__save-button">
                    <WidgetButton
                        onClick={handleSave}>{t("my-account-main-view.stays.detail.profile-data-edit.profile.save")}</WidgetButton>
                </div>
                <Notification input={notification} handleHideNotification={handleHideNotification}/>
            </div>
        </div>
    );
};

export default BookingDetailEdit;
