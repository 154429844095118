import React, { FC, useState } from 'react';
import { useTranslation } from "react-i18next";
import { InputText, Notification, WidgetButton, WidgetHead } from "components";
import { FormUtils, useNotification } from "lib";
import { MyAccountStatus } from "services";
import { BookingDetailsDto, BookingDto } from "services/openapi";
import { NotificationKnowledge } from "knowledge";

interface BookingDetailShareProps {
    bookingDetails: BookingDetailsDto;
    handleUpdateBookingDetail:()=>void;
}

const BookingDetailShare: FC<BookingDetailShareProps> = ({ bookingDetails,handleUpdateBookingDetail}) => {
    const {t} = useTranslation();
    const [email, setEmail] = useState('');
    const {notification, setNotification, handleHideNotification} = useNotification();

    return null;// by the moment ( 19/03/2024) this is disabled
    const handleShare = () => {
        MyAccountStatus.shareBooking(email)
        .then((error) => {
            if (error) {
                setNotification("my-account-main-view.account.save-fail", NotificationKnowledge.NOTIFICATION_LEVELS.ERROR);
            } else {
                handleUpdateBookingDetail();
            }
        })
        .catch((error) => {
            setNotification("my-account-main-view.account.save-fail", NotificationKnowledge.NOTIFICATION_LEVELS.ERROR);
        });
    };

    const handleRevokeShare = (email: string) => {
        MyAccountStatus.revokeShareBooking_onBookingDetails(email).then((error) => {
            if (error) {
                setNotification("my-account-main-view.account.save-fail", NotificationKnowledge.NOTIFICATION_LEVELS.ERROR);
            } else {
                handleUpdateBookingDetail();
            }
        })
        .catch((error) => {
            setNotification("my-account-main-view.account.save-fail", NotificationKnowledge.NOTIFICATION_LEVELS.ERROR);
        });
    };

    const formValid = FormUtils.emailIsValid(email);
    return (
        <div className="booking-detail-share">
            <div className="booking-detail-wrapper padding-standard-1">
                <div className="booking-detail-wrapper-first">
                    <i className="edm-icon-share widget-font-color-2 font-size-54px"></i>
                </div>
                <div className="booking-detail-composition-data">
                    <WidgetHead>{t("my-account-main-view.stays.detail.share-booking.title")}</WidgetHead>
                    <p className="booking-detail-share__text">
                        <strong>
                        {t("my-account-main-view.stays.detail.share-booking.text")}
                        </strong>
                    </p>
                    <div className="booking-detail-share__email-text">
                        {t("my-account-main-view.stays.detail.share-booking.input-email")}
                    </div>
                    <div className="booking-detail-share__email-input-wrapper">
                        <InputText 
                            tag="my-account-main-view.stays.detail.share-booking.email-address"
                            value={email}
                            onChange={setEmail}
                            />
                            {(bookingDetails.emails || []).map((email) => (
                                <div className='booking-detail-share__list-item' key={email}>
                                    <span className="booking-detail-share__list-item__email">{email}</span>
                                    <button 
                                        onClick={() => handleRevokeShare(email)}
                                        className="booking-detail-share__list-item__email-button"
                                        >
                                        {t("my-account-main-view.stays.detail.share-booking.revoke-share")}
                                    </button>
                                </div>
                            ))}
                        <WidgetButton 
                            disabled={!formValid} onClick={handleShare}
                            className="booking-detail-share__share-button my-account-global-btn-02"
                            >
                            {t("my-account-main-view.stays.detail.share-booking.share")}
                        </WidgetButton>
                    </div>
                    <Notification input={notification} handleHideNotification={handleHideNotification}/>
                </div>
            </div>
        </div>
    );
};

export default BookingDetailShare;
