import { StaticParamsKnowledge as SpK } from "../../knowledge";

export class StaticParams {
  private _mode: string;
  private _idProperty: number;

  private _viewMode: string;
  private _urlNewBooking: string;
  private _urlCheckin: string;
  private _language: string;
  private _customStyle: string;

  private _hideNewBookingButton: boolean;
  private _hideCompanionsEdition: boolean;
  private _hideVehiclesEdition: boolean;
  private _hideStayDetailEdition: boolean;
  private _hideWelcomeCustomerArea: boolean;

  constructor(jsonData: any) {

    this._mode = (jsonData[SpK.MODE] != null) ? jsonData[SpK.MODE] : SpK.DEFAULT_PARAMETERS.mode;
    this._idProperty = (jsonData[SpK.ID_PROPERTY] != null) ? jsonData[SpK.ID_PROPERTY] : SpK.DEFAULT_PARAMETERS.idProperty;
    this._viewMode = (jsonData[SpK.VIEW_MODE] != null) ? jsonData[SpK.VIEW_MODE] : SpK.DEFAULT_PARAMETERS.viewMode;

    const urlNewBooking = (jsonData[SpK.URL_NEW_BOOKING] != null) ? jsonData[SpK.URL_NEW_BOOKING] : SpK.DEFAULT_PARAMETERS.urlNewBooking;
    this._urlNewBooking = urlNewBooking;

    const urlCheckin = (jsonData[SpK.URL_CHECKIN] != null) ? jsonData[SpK.URL_CHECKIN] : SpK.DEFAULT_PARAMETERS.urlCheckin;
    this._urlCheckin = urlCheckin;

    this._language = (jsonData[SpK.LANGUAGE] != null) ? jsonData[SpK.LANGUAGE] : SpK.DEFAULT_PARAMETERS.language;
    this._customStyle = (jsonData[SpK.CUSTOM_STYLE] != null) ? jsonData[SpK.CUSTOM_STYLE] : SpK.DEFAULT_PARAMETERS.customStyle;

    this._hideNewBookingButton = (jsonData[SpK.HIDE_NEW_BOOKING_BUTTON] != null) ? jsonData[SpK.HIDE_NEW_BOOKING_BUTTON] : SpK.DEFAULT_PARAMETERS.hideNewBookingButton;
    this._hideCompanionsEdition = (jsonData[SpK.HIDE_COMPANIONS_EDITION] != null) ? jsonData[SpK.HIDE_COMPANIONS_EDITION] : SpK.DEFAULT_PARAMETERS.hideCompanionsEdition;
    this._hideVehiclesEdition = (jsonData[SpK.HIDE_VEHICLES_EDITION] != null) ? jsonData[SpK.HIDE_VEHICLES_EDITION] : SpK.DEFAULT_PARAMETERS.hideVehiclesEdition;
    this._hideStayDetailEdition = (jsonData[SpK.HIDE_STAY_DETAIL_EDITION] != null) ? jsonData[SpK.HIDE_STAY_DETAIL_EDITION] : SpK.DEFAULT_PARAMETERS.hideStayDetailEdition;
    this._hideWelcomeCustomerArea = (jsonData[SpK.HIDE_WELCOME_CUSTOMER_AREA] != null) ? jsonData[SpK.HIDE_WELCOME_CUSTOMER_AREA] : SpK.DEFAULT_PARAMETERS.hideWelcomeCustomerArea;
  }


  get mode(): string {
    return this._mode;
  }

  get idProperty(): number {
    return this._idProperty;
  }

  get viewMode(): string {
    return this._viewMode;
  }

  get urlNewBooking(): string {
    return this._urlNewBooking;
  }

  get urlCheckin(): string {
    return this._urlCheckin;
  }

  get language(): string {
    return this._language;
  }

  get customStyle(): string {
    return this._customStyle;
  }

  get hideNewBookingButton(): boolean {
    return this._hideNewBookingButton;
  }

  get hideCompanionsEdition(): boolean {
    return this._hideCompanionsEdition;
  }

  get hideVehiclesEdition(): boolean {
    return this._hideVehiclesEdition;
  }

  get hideStayDetailEdition(): boolean {
    return this._hideStayDetailEdition;
  }

  get hideWelcomeCustomerArea(): boolean {
    return this._hideWelcomeCustomerArea;
  }
}
