import React, { FC, useState } from 'react';
import { useTranslation } from "react-i18next";
import { ContactRequestService } from "services";
import { WidgetButton, Notification } from "components";
import { useNotification } from "lib";
import { NotificationKnowledge } from "knowledge";

interface ContactRequestClaimProps {
    handleSuccessSent: () => void;
}

const ContactRequestClaim: FC<ContactRequestClaimProps> = ({handleSuccessSent}) => {
    const {t} = useTranslation()
    const [bookingId, setBookingId] = useState('');
    const [comment, setComment] = useState('');
    const {notification, setNotification, handleHideNotification} = useNotification();
    const [isFocused, setFocused] = useState(false);


    const handleBookingIdChange = (event) => {
        setBookingId(event.target.value);
    };
    const formCorrect = bookingId !== '' && comment !== '';

    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };
    const handleFocus = (event) => {
        setFocused(true);
    };
    const handleBlur = () => {
        setFocused(false);
    };

    const handleSend = () => {

        ContactRequestService.contactRequestClaim(bookingId, comment).then((error) => {
            if (error) {
                setNotification("my-account-main-view.account.save-fail", NotificationKnowledge.NOTIFICATION_LEVELS.ERROR);
            } else {
                setNotification("my-account-main-view.account.save-success", NotificationKnowledge.NOTIFICATION_LEVELS.SUCCESS);
            }
        }).catch((error) => {
            setNotification("my-account-main-view.account.save-fail", NotificationKnowledge.NOTIFICATION_LEVELS.ERROR);
        });
    };


    return (
        <div className="contact-request-claim">
            <div className="contact-request-bookingId input-text">
                <input
                    type="text"
                    id="inputBookingId"
                    value={bookingId}
                    onChange={handleBookingIdChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    className={bookingId || isFocused ? 'label-active' : ''}
                />
                <label htmlFor="inputBookingId">{t("my-account-main-view.contact.contact-form.booking-id")}</label>
            </div>
            <div className="contact-request-comment input-text">
                <textarea
                    id="commentInput"
                    value={comment}
                    onChange={handleCommentChange}
                    rows={4}
                    cols={50}
                    className="label-active"
                />
                <label>{t("my-account-main-view.contact.contact-form.comment")}</label>
            </div>
            <WidgetButton disabled={!formCorrect}
                          onClick={handleSend} className="my-account-global-btn-02">{t("my-account-main-view.contact.contact-form.send")}</WidgetButton>
            <Notification input={notification} handleHideNotification={handleHideNotification}/>
        </div>
    );
};

export default ContactRequestClaim;
