import React, { FC } from 'react';
import { useTranslation } from "react-i18next";
import { WidgetHead } from "components";
import { BookingDetailsDto } from "services/openapi";
import { FriendsGroup } from "services";
import {BookingDetailFriendItem} from "components";


interface BookingDetailProfileFriendProps {
    bookingDetails: BookingDetailsDto
}

const BookingDetailProfileFriend: FC<BookingDetailProfileFriendProps> = ({bookingDetails}) => {
    const {t} = useTranslation();

    const numPersons = bookingDetails?.nbPers || 0;
    if (numPersons == 0) {
        return null;
    }

    const {friends, hasAll} = FriendsGroup.generateBookingDetailsFriendsGroup(bookingDetails.friends ?? [], numPersons);

    return (
        <div className="booking-detail-profile-friend">
            <div className="booking-detail-profile-friend__title">
                <WidgetHead size={3}>{t("my-account-main-view.stays.detail.profile-data.friends-section")}
                    <span
                        className="booking-detail-profile-friend__asterisk">*</span>
                    {hasAll ? <i className="edm-icon-circle-check-solid"></i> :
                        <i className="edm-icon-circle-xmark-solid"></i>}
                </WidgetHead>
            </div>
            {friends.map((f, i) => <BookingDetailFriendItem disabled={true} key={i} friend={f}/>)}
        </div>
    );
};


export default BookingDetailProfileFriend;
