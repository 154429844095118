import React, { FC } from 'react';
import { BookingDto } from "services/openapi";
import { useTranslation } from "react-i18next";
import { DateUtils } from "lib";
import { AssetsKnowledge } from 'knowledge';
import { AccommodationTypePicture, ImageWithValidator } from 'components';



interface PastStayItemProps {
    booking: BookingDto;
}

const PastStayItem: FC<PastStayItemProps> = ({booking}) => {
    const {t} = useTranslation();

    const hasPicture: boolean = booking.categPictures != null && booking.categPictures.length > 0;
    const image =  booking.categPictures != null ? booking.categPictures[0]: AssetsKnowledge.IMAGES_PATH.DEFAULT; 
    const alt: string  = booking.site != null ? booking.site : '';
    const title: string  = booking.site != null ? booking.site : '';
    
    const begin: string | null = booking.begin != null ? DateUtils.formatDate(booking.begin) : null;
    const end: string | null = booking.end != null ? DateUtils.formatDate(booking.end) : null;
   

    return (
        <div className="past-stay-item">
            {hasPicture && <div className="past-stay-item__picture-container">
                <AccommodationTypePicture idOrder={booking.idOrder}/>
            </div>}
            <div className="past-stay-item__container">
                {
                    booking.site != null && 
                    <div className="past-stay-item__site my-account-global-title-and-icon">
                        <i className="edm-icon-campground"></i>
                        {booking.site}
                    </div>
                }
                {
                    booking.idOrder != null && 
                    <div className="past-stay-item__idOrder my-account-global-title-and-icon">
                    <i className="edm-icon-file"></i>
                    {booking.idOrder}
                    </div>
                }
                {
                    begin != null && end != null &&
                    <div className="past-stay-item__calendar my-account-global-title-and-icon">
                    <i className="edm-icon-date"></i>
                    {t("my-account-main-view.stays.form.calendar", {begin: begin, end: end})}
                    </div>
                }
                {
                    booking.lodging != null && 
                    <div className="past-stay-item__lodging my-account-global-title-and-icon">
                    <i className="edm-icon-home"></i>
                    {booking.lodging}
                    </div>
                }
                {
                    booking.nbPers != null && booking.nbPers > 0 && 
                    <div className="past-stay-item__nbPers my-account-global-title-and-icon">
                    <i className="edm-icon-users2"></i>
                    {t("my-account-main-view.stays.form.persons", {count: booking.nbPers})}
                    </div>
                }
            </div>
        </div>
    );
}

export default PastStayItem;
