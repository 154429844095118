import { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';


class ComponentRefreshClass {
  private _listeners: { [name: string]: () => void };

  constructor() {
    this._listeners = {};
  }

  registerListener(name: string, listener: () => void) {
    this._listeners[name] = listener;
  }

  unregisterListener(name: string) {
    if (this._listeners[name] != null) {
      delete this._listeners[name];
    }
  }

  refreshAllComponents(): void {
    Object.keys(this._listeners).forEach((name) => {
      if (this._listeners[name] != null) {
        this._listeners[name]();
      }
    });
  }
}

const ComponentRefresh = new ComponentRefreshClass();


function useRefresh() {
  const [, refresh] = useState(0);
  //register listener
  useEffect(() => {
    const uuid = uuidv4();
    ComponentRefresh.registerListener(uuid, () => refresh((value) => value + 1));
    return () => ComponentRefresh.unregisterListener(uuid);
  });
}

export { ComponentRefresh, useRefresh }