import React, { FC, useState } from 'react';
import { useTranslation } from "react-i18next";
import type { CustomerVehicleDto } from "services/openapi";
import { useNotification } from "lib";
import { MyAccountStatus } from "services";
import { Notification, WidgetButton, WidgetHead } from "components";
import { NotificationKnowledge } from "knowledge";
import AccountVehicleItem from "./components/AccountVehicleItem/AccountVehicleItem";


interface AccountVehiclesProps {
}

const fGenKey = (v: CustomerVehicleDto, index: number) =>
    [v.color, v.hashVehicle, v.idCustVehicle, v.licensePlate, v.type,
        v.year, index]
    .map((x) => x == null ? '' : '' + x).join("$");

const AccountVehicles: FC<AccountVehiclesProps> = () => {
    const {t} = useTranslation();
    const [vehicleData, setVehicleData] = useState<CustomerVehicleDto[]>(structuredClone(MyAccountStatus.vehicles));
    const {notification, setNotification, handleHideNotification} = useNotification();


    const handleSave = () => {
        MyAccountStatus.saveVehicles(vehicleData).then((error) => {
            if (error) {
                setNotification("my-account-main-view.account.save-fail", NotificationKnowledge.NOTIFICATION_LEVELS.ERROR);
            } else {
                setNotification("my-account-main-view.account.save-success", NotificationKnowledge.NOTIFICATION_LEVELS.SUCCESS);
            }
        }).catch((error) => {
            setNotification("my-account-main-view.account.save-fail", NotificationKnowledge.NOTIFICATION_LEVELS.ERROR);
        });
    }

    const handleAddVehicle = () => {
        const emptyVehicle: CustomerVehicleDto = {visible: true};
        setVehicleData([...vehicleData, emptyVehicle]);
    };
    const handleDeleteVehicle = (id: number) => () => {
        vehicleData[id].visible = false;
        setVehicleData([...vehicleData]);
    }

    const handleRevert = () => {
        setVehicleData(structuredClone(MyAccountStatus.vehicles));
    }

    return (
        <div className="account-vehicles">
            <WidgetHead cssIcon="edm-icon-vehicle">
                {t("my-account-main-view.account.account-vehicles.title")}
            </WidgetHead>
            <div className="account__advise">
                <i className="edm-icon-info-circled"></i>
                {t("my-account-main-view.account.account-vehicles.advice")}
            </div>
            {vehicleData.map((vehicle, index) => <AccountVehicleItem key={fGenKey(vehicle, index)} vehicle={vehicle}
                                                                     handleDelete={handleDeleteVehicle(index)}/>)}

            <div className="account-vehicles-buttons my-account-margin-30">
                <button className="account-vehicles-buttons__add my-account-global-btn-01"
                        onClick={handleAddVehicle}>{t("my-account-main-view.account.account-vehicles.add")}</button>
                <WidgetButton className="account-vehicles-buttons__save my-account-global-btn-02"
                              onClick={handleSave}>{t("my-account-main-view.account.save")}</WidgetButton>
                <button className="account-vehicles-buttons__revert my-account-global-btn-01"
                        onClick={handleRevert}>{t("my-account-main-view.account.revert")}</button>

            </div>
            <Notification input={notification} handleHideNotification={handleHideNotification}/>
        </div>
    );
}

export default AccountVehicles;
