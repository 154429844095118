import { WidgetConfigKnowledge } from "./widget-config.knowledge";

export class StaticParamsKnowledge {

  static MODE = "mode";
  static ID_PROPERTY = "idProperty";
  static VIEW_MODE = "viewMode";
  static URL_NEW_BOOKING = "urlNewBooking";
  static URL_CHECKIN = "urlCheckin";
  static LANGUAGE = "language";
  static CUSTOM_STYLE = "customStyle";
  static HIDE_NEW_BOOKING_BUTTON = "hideNewBookingButton";
  static HIDE_COMPANIONS_EDITION = "hideCompanionsEdition";
  static HIDE_VEHICLES_EDITION = "hideVehiclesEdition";
  static HIDE_STAY_DETAIL_EDITION = "hideStayDetailEdition";
  static HIDE_WELCOME_CUSTOMER_AREA = "hideWelcomeCustomerArea";

  static DEFAULT_PARAMETERS={
    mode: WidgetConfigKnowledge.DEFAULT_MODE,
    idProperty: 0,
    viewMode: 'light',
    urlNewBooking: null,
    urlCheckin: null,
    language:'es',
    customStyle: null,
    hideNewBookingButton: false,
    hideCompanionsEdition: false,
    hideVehiclesEdition: false,
    hideStayDetailEdition: false,
    hideWelcomeCustomerArea:false
  };

}
