import React, { FC } from 'react';
import { BookingDto } from "services/openapi";
import { useTranslation } from "react-i18next";
import FutureStayItem from "./components/FutureStayItem/FutureStayItem";
import { WidgetHead } from "components";

interface ModalStaysFutureStaysProps {
    bookings: BookingDto[];
}

const ModalStaysFutureStays: FC<ModalStaysFutureStaysProps> = ({bookings}) => {
    const {t} = useTranslation();
    if (bookings.length === 0) {
        return <></>;
    }
    const first = bookings[0];
    const daysToArrival = first.nbDay ;
    return (
        <div className="modal-stays-future-stays">
            <WidgetHead className="modal-stays-future-stays__title">{t("my-account-main-view.stays.future.title")}</WidgetHead>
            {daysToArrival!=undefined && daysToArrival > 0 && <div
              className="modal-stays-future-stays__days">{t("my-account-main-view.stays.future.days-to-arrival", {days: daysToArrival})}</div>}
            {daysToArrival!=undefined && daysToArrival == 0 && <div
              className="modal-stays-future-stays__days">{t("my-account-main-view.stays.future.arrival-today")}</div>}
            <div className="modal-stays-future-stays__list">
                {bookings.map((booking, index) => <FutureStayItem key={booking.idOrder ?? index} booking={booking}/>)}
            </div>
        </div>
    );
};

export default ModalStaysFutureStays;
