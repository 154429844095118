import React, { FC } from 'react';
import { UniversalNaming as Uni, AssetsKnowledge} from "knowledge";
import { CustomerVehicleDto } from "services/openapi";
import { ImageWithValidator, InputText } from "components";

interface AccountVehicleItemProps {
    vehicle: CustomerVehicleDto;
    handleDelete: () => void;
}

const AccountVehicleItem: FC<AccountVehicleItemProps> = ({vehicle,handleDelete}) => {

    if (vehicle.visible != true) {
        return null; // do not display non visible friends
    }
    const changePropertyFunc = (property) => (value) => {
        vehicle[property] = value;
    }
    return (
        <div className="account-vehicle-item">
            <button onClick={handleDelete} className="account-friend-item__delete"><ImageWithValidator picture={AssetsKnowledge.IMAGES_PATH.TRASH} width="25" /></button>
            <InputText tag="my-account-main-view.person-form.license-plate" value={vehicle.licensePlate}
                       onChange={changePropertyFunc(Uni.LICENSE_PLATE)}/>
        </div>
    );
};

export default AccountVehicleItem;
