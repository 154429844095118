import i18next from "i18next";
import { PersonDto, RqContact, type RsContact } from "../openapi";
import { ContactKnowledge, MonCompteApiKnowledge } from "knowledge";
import { MonCompteApiUtils } from "lib";
import { URL_SOURCE } from "../url-source";
import { SessionService } from "./session.service";
import { MyAccountStatus } from "./my-account-status";

export class ContactRequestService {
    constructor() {
    }

    static async contactRequestAvailability(accommodationType: string, checkin: string, checkout: string, comment: string): Promise<Error | null> {

        const tenantId: string = MyAccountStatus.tenantId;
        const sessionId: string = SessionService.sessionId;
        const person: PersonDto = MyAccountStatus.person;

        const body: RqContact = {
            person: person,
            askType: ContactKnowledge.CONTACT_REQUEST_OPTIONS_API.AVAILABILITY_REQUEST,
            askChoice: accommodationType,
            from: window.location.href,
            comment: `Fecha de llegada : ${checkin}               Fecha de salida : ${checkout}    ${comment}`,
        };
        return await ContactRequestService.sendContactRequest(body);
    }

    static async contactRequestBooking(bookingId: string, comment: string) {


        const person: PersonDto = MyAccountStatus.person;

        const body: RqContact = {
            person: person,
            askType: ContactKnowledge.CONTACT_REQUEST_OPTIONS_API.BOOKING,
            askChoice: bookingId,
            from: window.location.href,
            comment: comment
        };

        return await ContactRequestService.sendContactRequest(body);

    }



    static async contactRequestClaim(bookingId: string, comment: string) {

        const person: PersonDto = MyAccountStatus.person;
        const body: RqContact = {
            person: person,
            askType: ContactKnowledge.CONTACT_REQUEST_OPTIONS_API.CLAIM,
            askChoice: bookingId,
            from: window.location.href,
            comment: comment
        };

        return await ContactRequestService.sendContactRequest(body);

    }

    static async contactRequestPhoneNumber(comment: string) {

        const person: PersonDto = MyAccountStatus.person;
        const body: RqContact = {
            person: person,
            askType: ContactKnowledge.CONTACT_REQUEST_OPTIONS_API.CLAIM,
            from: window.location.href,
            comment: comment
        };

        return await ContactRequestService.sendContactRequest(body);

    }

    private static async sendContactRequest(body: RqContact): Promise<Error | null> {

        const tenantId: string = MyAccountStatus.tenantId;
        const sessionId: string = SessionService.sessionId;

        body['lg'] = i18next.language;
        const response = await fetch(`${URL_SOURCE}/rest/contact`, {
            "headers": {
                [MonCompteApiKnowledge.HTTP_HEADER_XTENANTID]: tenantId,
                "content-type": "application/json",
                "x-session-id": sessionId
            },
            "method": "POST",
            "body": JSON.stringify(body)
        });
        MonCompteApiUtils.processPossibleApiError(response);
        const contact: RsContact = await response.json() as RsContact;
        if (contact.error != 0) {
            return new Error(contact.messError);
        }
        return null;
    }
}


