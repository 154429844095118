import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import {URL_SOURCE} from "./url-source";

i18next
    .use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng: 'es',
        backend: {
            loadPath: `${URL_SOURCE}/locales/{{lng}}.json`
        }
    });

