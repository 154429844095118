export class DateUtils{
    static formatDate(date: Date | string): string {
        if (typeof date === 'string') {
            date = new Date(date);
        }
        const day = date.getDate();
        const month = date.getMonth()+1;
        const year = date.getFullYear();
        return `${day<10?'0'+day:day}/${month<10?'0'+month:month}/${year}`;
    }

    static formatDate_ToApi(date: Date | string): string {
        if (typeof date === 'string') {
            date = new Date(date);
        }
        const day = date.getDate();
        const month = date.getMonth()+1;
        const year = date.getFullYear();
        return `${year}-${month<10?'0'+month:month}-${day<10?'0'+day:day}`;
    }
}
