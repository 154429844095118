export class PasswordKnowledge {
    static PASSWORD_MIN_LENGTH = 6;
    static PASSWORD_MIN_LOWERCASE = 1;
    static PASSWORD_MIN_UPPERCASE = 1;
    static PASSWORD_MIN_NUMBER = 1;
    static PASSWORD_MIN_SYMBOL = 1;

    static PASSWORD_ERRORS = {
        TOO_SHORT: "too-short",
        MISSING_LOWERCASE: "missing-lowercase",
        MISSING_UPPERCASE: "missing-uppercase",
        MISSING_NUMBER: "missing-number",
        MISSING_SYMBOL: "missing-symbol",
        NON_MATCHING: "non-matching"
    }
    static PASSWORD_STATUS = {
        CORRECT: "correct",
        WARNING: "warning",
        ERROR: "error",
        EMPTY: "empty"
    }
}
