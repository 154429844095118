import {
    PersonDto,
    RqCreateAccount,
    RqLogin,
    RqUpdatePw, type RsCreateAccount,
    type RsLogin,
    type RsResetPassword,
    RsUpdatePw
} from "../openapi";
import {MonCompteApiKnowledge} from "knowledge";
import {MonCompteApiUtils} from "lib";
import {PasswordGroup} from "../classes/password-group";
import {URL_SOURCE} from "../url-source";

type SessionInLocalStorage = {
    session: string;
    eta: Date;
}

//Singleton
class SessionServiceClass {

    private _isLogged: boolean;
    private _sessionId: string;

    constructor() {
        this._isLogged = false;
        this._sessionId = '';
        try {
            const storedSessionCode = window.localStorage.getItem(MonCompteApiKnowledge.SESSION_STORAGE_SESSION_KEY);
            if (storedSessionCode != null) {

                const sessionStored: SessionInLocalStorage = JSON.parse(storedSessionCode);
                const expirationDate = new Date(sessionStored.eta);
                const now = new Date();
                if (now.getTime() < expirationDate.getTime()) {
                    this._isLogged = true;
                    this._sessionId = sessionStored.session;
                }
            }
        } catch (err) {
            console.error(err);
        }
    }

    get isLogged(): boolean {
        return this._isLogged;
    }

    get sessionId(): string {
        return this._sessionId;
    }

    set sessionId(value: string) {
        this._sessionId = value;
    }

    async login(tenantId: string, email: string, password: string): Promise<null | Error> {
        const requestLogin: RqLogin = {
            app: MonCompteApiKnowledge.API_APP_NAME,
            id: email,
            pw: password
        };
        /*
            const responseLogin: RsLogin = await MonCompteResourceService.loginUsingPost(tenantId, requestLogin);
        */
        const response: Response = await fetch(`${URL_SOURCE}/rest/login`, {
            "headers": {
                [MonCompteApiKnowledge.HTTP_HEADER_XTENANTID]: tenantId,
                "Content-Type": "application/json",
            },
            "body": JSON.stringify(requestLogin),
            "method": "POST",
        });
        MonCompteApiUtils.processPossibleApiError(response);
        const responseLogin: RsLogin = await response.json() as RsLogin;
        if (responseLogin.error != 0) {
            return new Error(responseLogin.messError);
        }

        const durationInMinutes = responseLogin.duration ?? 525600;
        const durationInMillisecons = durationInMinutes * 60 * 1000;

        const eta = new Date((new Date()).getTime() + durationInMillisecons);
        const sessionCode: string = responseLogin.idSession as string;
        const sessStorage: SessionInLocalStorage = {
            session: sessionCode,
            eta: eta
        }
        window.localStorage.setItem(MonCompteApiKnowledge.SESSION_STORAGE_SESSION_KEY, JSON.stringify(sessStorage));
        this._isLogged = true;
        this._sessionId = sessionCode;
        this.sendCustomEvent(MonCompteApiKnowledge.CUSTOM_EVENTS.LOGIN, {
            session: {
                session: sessionCode,
                eta: eta
            },
            tenantId: tenantId
        });
        return null;
    }

    sendCustomEvent(eventName: string, eventValue: any = null) {
        const customEvent = new CustomEvent(eventName, eventValue != null ? {detail: eventValue} : undefined);
        window.dispatchEvent(customEvent);
    }

    get sessionObject_inLocalStorage(): SessionInLocalStorage | null {
        const storedSessionCode = window.localStorage.getItem(MonCompteApiKnowledge.SESSION_STORAGE_SESSION_KEY);
        if (storedSessionCode != null) {
            return JSON.parse(storedSessionCode);
        }
        return null;
    }

    async logout() {
        window.localStorage.removeItem(MonCompteApiKnowledge.SESSION_STORAGE_SESSION_KEY);
        this._isLogged = false;
        this._sessionId = '';
        this.sendCustomEvent(MonCompteApiKnowledge.CUSTOM_EVENTS.LOGOUT);
    }

    async changePassword(state: PasswordGroup, tenantId: string) {
        const body: RqUpdatePw = {
            oldPw: state.currentPassword,
            newPw: state.newPassword
        };
        const response: any = await fetch(`${URL_SOURCE}/rest/account/password`, {
            "headers": {
                [MonCompteApiKnowledge.HTTP_HEADER_XTENANTID]: tenantId,
                "content-type": "application/json",
                "x-session-id": this._sessionId
            },
            "method": "PUT",
            "body": JSON.stringify(body)
        });
        MonCompteApiUtils.processPossibleApiError(response);
        const responseVehicles: RsUpdatePw = await response.json() as RsUpdatePw;
        if (responseVehicles.error != 0) {
            throw new Error(responseVehicles.messError);
        }
    }

    async sendRecoveryEmail(tenantId: string, email: string) {
        const emailEscaped = encodeURI(email);
        const origin = window.location.href.split('?')[0];
        const urlCallback: string = encodeURI(`${origin}?my-account-lost-password={{TOKEN}}`);
        const response: any = await fetch(`${URL_SOURCE}/rest/account/reset-password?email=${emailEscaped}&urlcallback=${urlCallback}`, {
            "headers": {
                [MonCompteApiKnowledge.HTTP_HEADER_XTENANTID]: tenantId,
                "content-type": "application/json",
            },
            "method": "GET",
        });
        MonCompteApiUtils.processPossibleApiError(response);
        const responseResetPwd: RsResetPassword = await response.json() as RsResetPassword;
        if (responseResetPwd.error != 0) {
            throw new Error(responseResetPwd.messError);
        }
    }

    async setNewPassword(tenantId: string, newPassword: string) {
        const body: RqUpdatePw = {
            newPw: newPassword
        };
        const response: any = await fetch(`${URL_SOURCE}/rest/account/password`, {
            "headers": {
                [MonCompteApiKnowledge.HTTP_HEADER_XTENANTID]: tenantId,
                "content-type": "application/json",
                "x-session-id": this._sessionId
            },
            "method": "PUT",
            "body": JSON.stringify(body)
        });
        MonCompteApiUtils.processPossibleApiError(response);
        const responseJson: RsUpdatePw = await response.json() as RsUpdatePw;
        if (responseJson.error != 0) {
            throw new Error(responseJson.messError);
        }
    }

    async registerAccount(tenantId: string, personData: PersonDto, fidelity: boolean, password: PasswordGroup): Promise<Error | null> {
        const body: RqCreateAccount = {
            pw: password.newPassword,
            person: personData,
            subscribeToFidelity: fidelity
        };
        const response: any = await fetch(`${URL_SOURCE}/rest/account`, {
            "headers": {
                [MonCompteApiKnowledge.HTTP_HEADER_XTENANTID]: tenantId,
                "content-type": "application/json",
            },
            "method": "POST",
            "body": JSON.stringify(body)
        });
        MonCompteApiUtils.processPossibleApiError(response);

        const responseCreateAcc: RsCreateAccount = await response.json() as RsCreateAccount;
        if (responseCreateAcc.error != 0) {
            return new Error(responseCreateAcc.messError);
        }
        return null;
    }
}

const SessionService = new SessionServiceClass();
export {SessionService};
