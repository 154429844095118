import {  FriendDto } from "../openapi";

export class FriendsGroup {
    static generateBookingDetailsFriendsGroup(existingFriends: FriendDto[], numPersons: number): {
        friends: FriendDto[],
        hasAll: boolean
    } {
        const friends = existingFriends;
        const missing = Math.max((numPersons - friends.length), 0);
        const result: FriendDto[] = [];
        const hasAll = missing === 0;
        if (!hasAll) {
            for (let i = 0; i < missing; i++) {
                result.push({});
            }
        }
        if (result.length < numPersons) {
            const nonMissing = numPersons - result.length;
            for (let i = 0; i < nonMissing; i++) {
                result.push(friends[i]);
            }
        }
        return {friends: structuredClone(result), hasAll: hasAll};
    }
}
