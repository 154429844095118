import i18next from "i18next";
import {
    PrecheckinSettingsDto, RsGetWidgetParams,
    RsGetWidgetParamsLight,
    WidgetCustomParamsDto,
    WidgetCustomParamsLightDto
} from "../openapi";
import { MonCompteApiKnowledge, WidgetConfigKnowledge, WidgetConfigKnowledge as WcK } from "knowledge";
import { MonCompteApiUtils } from "lib";
import { URL_SOURCE } from "../url-source";

class WidgetConfigService {

    private _precheckinSettings: PrecheckinSettingsDto;
    private _widgetParam: WidgetCustomParamsLightDto | WidgetCustomParamsDto;
    private _subscriptions: string[];
    private _translations: any;
    private _viewMode: string;
    private _mode: string;

    constructor() {
        this._precheckinSettings = {};
        this._widgetParam = {};
        this._translations = {};
        this._subscriptions = [];
        this._viewMode = WcK.DEFAULT_VIEW_MODE;
        this._mode = WcK.DEFAULT_VIEW_MODE;
    }


    get precheckinSettings(): PrecheckinSettingsDto {
        return this._precheckinSettings;
    }

    get widgetParam(): WidgetCustomParamsLightDto {
        return this._widgetParam;
    }

    get fontColorInHeaders(): string | null {
        if (this._widgetParam.fontColor2 != null) {
            return this._widgetParam.fontColor2;
        } else {
            return null;
        }
    }

    get backgroundColor(): string | null {
        if (this._widgetParam.backgroundColor != null) {
            return this._widgetParam.backgroundColor;
        } else {
            return null;
        }
    }

    get fontColor2(): string | null {
        if (this._widgetParam.fontColor2 != null) {
            return this._widgetParam.fontColor2;
        } else {
            return null;
        }
    }

    get backgroundPictureUrl(): string | null {
        if (this._widgetParam.backgroundPictureUrl != null) {
            return this._widgetParam.backgroundPictureUrl;
        } else {
            return null;
        }
    }

    hasSubscription(subscription: string): boolean {
        return this._subscriptions.includes(subscription);
    }

    get viewMode(): string {
        return this._viewMode;
    }

    set viewMode(value: string) {
        if (value != WcK.VIEW_MODE_DARK && value != WcK.VIEW_MODE_LIGHT) {
            this._viewMode = WcK.DEFAULT_VIEW_MODE;
        }
        this._viewMode = value;
    }


    get mode(): string {
        return this._mode;
    }

    set mode(value: string) {
        if (Object.values(WidgetConfigKnowledge.WIDGET_MODES).includes(value) != false) {
            this._mode = value;
        }
    }

    get isMode_masterCamping(): boolean {
        return this._mode.toUpperCase() == WidgetConfigKnowledge.WIDGET_MODES.MASTER_CAMPING.toUpperCase();
    }

    get isMode_unixData(): boolean {
        return this._mode.toUpperCase() == WidgetConfigKnowledge.WIDGET_MODES.UNIX_DATA.toUpperCase();
    }


    get hasInfoButton(): boolean {
        return this._translations[WcK.URL_SITE_PASS] != null && this._translations[WcK.LIBELLE_SITE_PASS] != ''
            && this._translations[WcK.LIBELLE_SITE_PASS] != null && this._translations[WcK.LIBELLE_SITE_PASS] != '';
    }

    get infoButtonLabel(): string {
        return this._translations[WcK.LIBELLE_SITE_PASS];
    }

    get infoButtonUrl(): string {
        return this._translations[WcK.URL_SITE_PASS];
    }

    clearAllData() {
        this._precheckinSettings = {};
        this._widgetParam = {};
        this._translations = {};
        this._subscriptions = [];
        this._viewMode = WcK.DEFAULT_VIEW_MODE;
    }

    async loadWidgetSettings(tenantId: string) {
        const language = i18next.language;
        const mode = this._viewMode == WcK.VIEW_MODE_LIGHT ? '/' + this._viewMode : '';
        const url = `${URL_SOURCE}/rest/context/widget/params${mode}?lg=${language}`;
        const response: any = await fetch(url, {
            "headers": {
                [MonCompteApiKnowledge.HTTP_HEADER_XTENANTID]: tenantId,
                "content-type": "application/json"
            },
            "method": "GET",
        });

        MonCompteApiUtils.processPossibleApiError(response);
        if (this._viewMode == WcK.VIEW_MODE_LIGHT) {
            const responseConfig: RsGetWidgetParamsLight = await response.json() as RsGetWidgetParamsLight;
            if (responseConfig.error != 0) {
                throw new Error(responseConfig.messError);
            }
            this._precheckinSettings = responseConfig.precheckinSettings || {};
            this._translations = responseConfig.translations || {};
            this._subscriptions = responseConfig.subscriptions || [];
            this._widgetParam = responseConfig.widgetParam || {};
        }
        if (this._viewMode == WcK.VIEW_MODE_DARK) {
            const responseConfig: RsGetWidgetParams = await response.json() as RsGetWidgetParams;
            if (responseConfig.error != 0) {
                throw new Error(responseConfig.messError);
            }
            this._precheckinSettings = {};
            this._translations = {};
            this._subscriptions = [];
            this._widgetParam = responseConfig.widgetParam || {};
        }
    }
}


const WidgetConfig = new WidgetConfigService();

export { WidgetConfig };
