import React, { FC } from 'react';
import { FriendDto } from "services/openapi";
import { AssetsKnowledge, UniversalNaming as Uni } from "knowledge";
import InputText from "../form-fields/InputText/InputText";
import InputDate from "../form-fields/InputDate/InputDate";


interface BookingDetailFriendItemProps {
    friend: FriendDto;
    disabled?: boolean;
    handleChange?: () => void;
    handleDelete?: () => void;
}

const BookingDetailFriendItem: FC<BookingDetailFriendItemProps> = ({friend, disabled, handleChange, handleDelete}) => {

    const changePropertyFunc = (property) => (value) => {
        friend[property] = value;
        if (handleChange != null) {
            handleChange();
        }
    }
    return (
        <div className="account-friend-item ">
            {handleDelete != null && <button onClick={handleDelete} className="account-friend-item__delete"><img
              src={AssetsKnowledge.IMAGES_PATH.TRASH} width="25"/></button>}
            <InputText disabled={disabled} tag="my-account-main-view.person-form.lastname" value={friend.lastName}
                       onChange={changePropertyFunc(Uni.LAST_NAME)}/>
            <InputText disabled={disabled} tag="my-account-main-view.person-form.firstname" value={friend.firstName}
                       onChange={changePropertyFunc(Uni.FIRST_NAME)}/>
            <InputDate disabled={disabled} tag="my-account-main-view.person-form.birthdate" value={friend.birthDate}
                       onChange={changePropertyFunc(Uni.BIRTH_DATE)}/>
        </div>
    );
};

export default BookingDetailFriendItem;
