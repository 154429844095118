import React from 'react';
import { useTranslation } from "react-i18next";
import { UniversalNaming as Uni, PersonKnowledge as PesK } from "knowledge";
import WidgetHead from "../widget-customizable/WidgetHead/WidgetHead";
import { CountrySelectorFormik } from 'components';
import InputFormik from "../form-fields/InputFormik/InputFormik";
import LanguageSelectorFormik from "../form-fields/LanguageSelector/LanguageSelectorFormik";
import TreatmentSelectorFormik from "../form-fields/TreatmentSelector/TreatmentSelectorFormik";
import { PersonDto } from "services/openapi";
import { FormikConfig } from "formik";


interface PersonFormFormikProps {
    formik: FormikConfig<PersonDto>,
    isEditing: boolean;
}

const PersonFormFormik = ({formik, isEditing}) => {
    const {t} = useTranslation();

    return (
        <div className="person-form">
            <WidgetHead cssIcon="edm-icon-user color-grey-dark-1">
                {t("my-account-main-view.account.account-data.title")}
            </WidgetHead>
            <div className="person-form-field__container">
                <div className="person-form-field__container-column">
                    <TreatmentSelectorFormik className='label-active' tag="my-account-main-view.person-form.treatment"
                                             {...formik.getFieldProps(Uni.CIVILITY)}/>

                    <InputFormik maxlength={PesK.MAX_LENGTH_FIELDS[Uni.LAST_NAME]} type="text"
                                 tag="my-account-main-view.person-form.lastname" field={Uni.LAST_NAME} formik={formik}
                                 disabled={isEditing}/>
                    <InputFormik maxlength={PesK.MAX_LENGTH_FIELDS[Uni.FIRST_NAME]} type="text"
                                 tag="my-account-main-view.person-form.firstname" field={Uni.FIRST_NAME}
                                 formik={formik}/>
                    <CountrySelectorFormik className='label-active' tag="my-account-main-view.person-form.country"
                                           {...formik.getFieldProps(Uni.COUNTRY)} />

                    <LanguageSelectorFormik className='label-active' tag="my-account-main-view.person-form.language"
                                            {...formik.getFieldProps(Uni.LANGUAGE)} />

                    <InputFormik type="date" tag="my-account-main-view.person-form.birthdate" field={Uni.BIRTH_DATE}
                                 formik={formik}/>

                    <InputFormik type="text" tag="my-account-main-view.person-form.email" field={Uni.EMAIL}
                                 formik={formik}/>

                </div>
                <div className="person-form-field__container-column">
                    <InputFormik maxlength={PesK.MAX_LENGTH_FIELDS[Uni.ADDRESS_1]} type="text"
                                 tag="my-account-main-view.person-form.address"
                                 field={Uni.ADDRESS_1} formik={formik}/>

                    <InputFormik maxlength={PesK.MAX_LENGTH_FIELDS[Uni.ADDRESS_2]} type="text"
                                 tag="my-account-main-view.person-form.address-complement"
                                 field={Uni.ADDRESS_2} formik={formik}/>

                    <InputFormik type="text" tag="my-account-main-view.person-form.postal-code"
                                 field={Uni.POST_CODE} formik={formik}/>

                    <InputFormik maxlength={PesK.MAX_LENGTH_FIELDS[Uni.CITY]} type="text"
                                 tag="my-account-main-view.person-form.city"
                                 field={Uni.CITY} formik={formik}/>

                    <InputFormik type="text" tag="my-account-main-view.person-form.phone"
                                 field={Uni.PHONE} formik={formik}/>
                    <InputFormik type="text" tag="my-account-main-view.person-form.mobile"
                                 field={Uni.MOBILE_PHONE} formik={formik}/>

                </div>
            </div>
        </div>
    );
};

export default PersonFormFormik;
