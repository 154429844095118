import { AssetsKnowledge, BookingKnowledge, StatusKnowledge, WidgetConfigKnowledge } from "knowledge";
import { ComponentRefresh } from "lib";
import { SessionService } from "./session.service";
import { AccountResources } from "./account-resources.service";
import { PropertyResources } from "./property-resources.service";
import { WidgetConfig } from "./widget-config.service";
import {
    BookingDetailsDto,
    BookingDto,
    ContextCampingDto,
    CustomerFriendDto,
    CustomerVehicleDto,
    PersonDto, RsGetFidStream
} from "../openapi";
import { PasswordGroup } from "../classes/password-group";
import i18next from "i18next";
import { BookingGroupCache } from "./booking-group-cache.service";

class MyAccountStatusClass {

    private _sessionService: typeof SessionService;
    private _accountService: typeof AccountResources;
    private _propertyService: typeof PropertyResources;
    private _bookingGroupCache: typeof BookingGroupCache;
    private _widgetConfigService: typeof WidgetConfig;
    private _isMainViewOpen: boolean;
    private _tenantId: string;
    private _hideNewBookingButton: boolean;
    private _urlNewBooking: string;
    private _urlCheckIn: string;
    private _currentView: string;
    private _isLoading: boolean = false;

    //to refresh on changes
    private _refresher: typeof ComponentRefresh;


    constructor() {
        this._sessionService = SessionService;
        this._accountService = AccountResources;
        this._propertyService = PropertyResources;
        this._bookingGroupCache = BookingGroupCache;
        this._widgetConfigService = WidgetConfig;
        this._isMainViewOpen = false;

        this._tenantId = '';
        this._urlNewBooking = '';
        this._urlCheckIn = '';
        this._hideNewBookingButton = false;
        this._currentView = this.defaultView;
        this._isLoading = false;

        this._refresher = ComponentRefresh;
        this.refreshData();
    }

    get isLoading(): boolean {
        return this._isLoading;
    }

    get isFidelityEnabled(): boolean {
        const subscriptionIsEnabled = this._widgetConfigService.hasSubscription(WidgetConfigKnowledge.SUBSCRIPTIONS.FIDELITY);
        const accountFidelityIsEnabled = this._accountService.person?.fidelity == true;
        return subscriptionIsEnabled && accountFidelityIsEnabled;
    }

    get isFidelitySubscribed(): boolean {
        return this._widgetConfigService.hasSubscription(WidgetConfigKnowledge.SUBSCRIPTIONS.FIDELITY);

    }

    get isLogged(): boolean {
        return this._sessionService.isLogged;
    }

    get hideNewBookingButton(): boolean {
        return this._hideNewBookingButton;
    }

    get defaultView(): string {
        return this.isLogged ? StatusKnowledge.VIEW_SECTIONS.LOGGED.STAYS : StatusKnowledge.VIEW_SECTIONS.NON_LOGGED.NON_LOGGED_MAIN;
    }

    get currentView(): string {
        return this._currentView;
    }

    get isMainViewOpen(): boolean {
        return this._isMainViewOpen;
    }

    get hasTenantId(): boolean {
        return this._tenantId != null && this._tenantId != '';
    }

    get tenantId(): string {
        return this._tenantId;
    }

    get hasUrlNewBooking(): boolean {
        return this._urlNewBooking != null && this._urlNewBooking != '';
    }

    get hasUrlCheckIn(): boolean {
        return this._urlCheckIn != null && this._urlCheckIn != '';
    }


    setComponentConfig(tenantId: string, viewMode: string, mode:string , selectedLanguage: string, urlNewBooking: string, urlCheckIn: string,
                       hideNewBookingButton = false) {
        this._tenantId = tenantId
        this._widgetConfigService.viewMode = viewMode;
        this._widgetConfigService.mode = mode;
        this._urlNewBooking = urlNewBooking;
        this._urlCheckIn = urlCheckIn;
        this._hideNewBookingButton = hideNewBookingButton;
        if (i18next.language !== selectedLanguage) {
            i18next.changeLanguage(selectedLanguage);
        }
        this.refreshData();
    }


    get person(): PersonDto {
        return this._accountService.person;
    }

    get fidelityData(): RsGetFidStream {
        return this._accountService.fidelityData;
    }

    get bookings(): BookingDto[] {
        return this._accountService.bookings;
    }

    get vehicles(): CustomerVehicleDto[] {
        return this._accountService.vehicles;
    }

    get friends(): CustomerFriendDto[] {
        return this._accountService.friends;
    }

    get propertyInfo(): ContextCampingDto {
        return this._propertyService.propertyInfo;
    }

    mainButtonText(): string {
        if (!this.isLogged) {
            return "my-account-button.no-logged-text";
        }
        const person = this._accountService.person;
        const name = person.firstName == null ? '' : person.firstName.trim();
        const lastName = person.lastName == null ? '' : person.lastName.trim();
        const fullName = (name + " " + lastName).trim();
        if (fullName.length > 0) {
            return fullName;
        }
        return "my-account-button.logged-no-name-text";
    }

    closeMainView() {
        if (!this._isMainViewOpen) {
            return;// do nothing
        }
        this._isMainViewOpen = false;
        this._currentView = this.isLogged ? StatusKnowledge.VIEW_SECTIONS.LOGGED.STAYS : StatusKnowledge.VIEW_SECTIONS.NON_LOGGED.NON_LOGGED_MAIN;
        this.refreshAppComponents();
    }

    openMainView() {
        if (this._isMainViewOpen) {
            return;// do nothing
        }
        this._isMainViewOpen = true;
        this.refreshAppComponents();
    }



    private refreshAppComponents(): void {
        this._refresher.refreshAllComponents();
    }

    private setIsLoading(isLoading: boolean) {
        if (this._isLoading == isLoading) {
            return; // do nothing
        }
        this._isLoading = isLoading;
        this.refreshAppComponents();
    }

    async refreshData() {
        if (this.hasTenantId == false) {
            return; // do nothing
        }


        this.setIsLoading(true);
        try {
            await this._widgetConfigService.loadWidgetSettings(this._tenantId);
            if (this._sessionService.isLogged == true) {
                const sessionId = this._sessionService.sessionId;
                await this._accountService.loadPersonData(this._tenantId, sessionId);
                if (this.isFidelityEnabled) {
                    await this._accountService.loadFidelityData(this._tenantId, sessionId);
                }
                await this._accountService.loadBookingsData(this._tenantId, sessionId);
                const bookingIdOrders: string[ ] = this._accountService.bookingIdOrders;
                await this._bookingGroupCache.preloadBookingDetails(this._tenantId, sessionId, bookingIdOrders, () => this.refreshAppComponents());
                await this._accountService.loadVehicleData(this._tenantId, sessionId);
                await this._accountService.loadFriendsData(this._tenantId, sessionId);
                await this._propertyService.loadPropertyInfo(this._tenantId, sessionId);
            }
            this.evaluateQueryParams();
            this.setIsLoading(false);
        } catch (err) {
            this.setIsLoading(false);
            this.logout();
            console.error(err);
        }
    }

    evaluateQueryParams(): void {
        this.isResetPasswordTokenSet();
        this.isViewChangeRequested();
        this.cleanQueryParams();
    }

    cleanQueryParams(): void {
        // const url = new URL(window.location.href);
        // url.searchParams.delete('my-account-view');
        // url.searchParams.delete('my-account-lost-password');
        // window.history.replaceState({}, document.title, url.toString());
    }

    isViewChangeRequested(): void {
        const view = new URLSearchParams(window.location.search).get('my-account-view');
        if (view == null) {
            return;
        }
        const notLoggedViews = Object.values(StatusKnowledge.VIEW_SECTIONS.NON_LOGGED);
        const loggedViews = Object.values(StatusKnowledge.VIEW_SECTIONS.LOGGED);
        const upperView = view.toUpperCase();
        if (!notLoggedViews.includes(upperView) && !loggedViews.includes(upperView)) {
            console.error("Invalid navigation when non logged: " + view);
            return;
        }
        this._currentView = upperView;
        this.openMainView();
    }

    isResetPasswordTokenSet(): void {
        const lastPasswordToken = new URLSearchParams(window.location.search).get('my-account-lost-password');
        if (lastPasswordToken == null) {
            return;
        }
        if (this._sessionService.isLogged == true) {
            this._currentView = StatusKnowledge.VIEW_SECTIONS.LOGGED.PROFILE;
        }else {
            this._sessionService.sessionId = atob(lastPasswordToken);
            this._currentView = StatusKnowledge.VIEW_SECTIONS.NON_LOGGED.SET_NEW_PASSWORD;
        }
        this.openMainView();
    }

    async login(email: string, password: string): Promise<null | Error> {
        const error = await this._sessionService.login(this._tenantId, email, password);
        if (error != null) {
            console.error(error);
            return error;
        }
        try {
            this.setIsLoading(true);
            await this.refreshData();
            this._currentView = this.defaultView;
            this.setIsLoading(false);
        } catch (err) {
            this.setIsLoading(false);
            return error;
            console.error(err);
        }
        return null;
    }

    logout() {
        this._sessionService.logout();
        this._accountService.clearAllData();
        this._bookingGroupCache.clearAllData();
        this._propertyService.clearAllData();
        this._bookingGroupCache.clearAllData();
        this._currentView = StatusKnowledge.VIEW_SECTIONS.NON_LOGGED.NON_LOGGED_MAIN;
        this.closeMainView();
    }


    navigate(tag) {
        if (this.isLogged) {
            if (Object.values(StatusKnowledge.VIEW_SECTIONS.LOGGED).includes(tag) == false) {
                console.error("Invalid navigation when logged: " + tag);
            }
        } else {
            if (Object.values(StatusKnowledge.VIEW_SECTIONS.NON_LOGGED).includes(tag) == false) {
                console.error("Invalid navigation when non logged: " + tag);
            }
        }
        this._currentView = tag;
        this.refreshAppComponents();
    }

    async updatePersonData_fidelity(fidelity: boolean) {
        //###code me
        this.refreshAppComponents();
    }


    async reloadSelectedBooking() {
        const sessionId = this._sessionService.sessionId;
        //intentionally deferred
        this._bookingGroupCache.reloadSelected(this._tenantId, sessionId).then(() => this.refreshAppComponents()).then(() => this.refreshAppComponents());

    }

    async shareBooking(email: string) {
        try {
            const sessionId = this._sessionService.sessionId;
            const error = await this._bookingGroupCache.shareSelectedBooking(this._tenantId, sessionId, email);
            if (error != null) {
                console.error(error);
                return error;
            }
            await this.reloadSelectedBooking();
        } catch (err) {
            console.error(err);
            return err;
        }
        return null;
    }

    async revokeShareBooking_onBookingDetails(email: string) {
        try {
            const sessionId = this._sessionService.sessionId;
            const error = await this._bookingGroupCache.revokeShareBooking(this._tenantId, sessionId, email);
            if (error != null) {
                console.error(error);
                return error;
            }
            await this.reloadSelectedBooking();
        } catch (err) {
            console.error(err);
            return err;
        }
        return null;
    }

    async savePerson(personData: PersonDto) {
        const sessionId = this._sessionService.sessionId;
        const error = await this._accountService.savePerson(personData, this._tenantId, sessionId);
        if (error != null) {
            console.error(error);
            return error;
        }
        return null;

    }

    async saveFriends(friendsData: CustomerFriendDto[]): Promise<null | Error> {
        const sessionId = this._sessionService.sessionId;
        const error = await this._accountService.saveFriends(friendsData, this._tenantId, sessionId);
        if (error != null) {
            console.error(error);
            return error;
        }
        return null;
    }

    async saveVehicles(vehiclesData: CustomerVehicleDto[]): Promise<null | Error> {
        const sessionId = this._sessionService.sessionId;
        const error = await this._accountService.saveVehicles(vehiclesData, this._tenantId, sessionId);
        if (error != null) {
            console.error(error);
            return error;
        }
        return null;
    }

    async saveBookingDetails(idOrder: string, bookingDetails: BookingDetailsDto) {
        const sessionId = this._sessionService.sessionId;
        if (idOrder == null) {
            console.error(`saving booking details, but idOrder is null`);
            return new Error(`saving booking details, but idOrder is null`);
        }
        const error = await this._bookingGroupCache.saveBookingDetails(this._tenantId, sessionId, idOrder, bookingDetails);
        this.refreshAppComponents();
        if (error != null) {
            console.error(error);
            return error;
        }
        return null;
    }

    async changePassword(state: PasswordGroup) {

        const error = await this._sessionService.changePassword(state, this._tenantId);
        if (error != null) {
            console.error(error);
            return error;
        }
        return null;
    }

    async sendRecoveryEmail(email: string) {
        await this._sessionService.sendRecoveryEmail(this._tenantId, email);
    }

    async registerAccount(personData: PersonDto, fidelity: boolean, password: PasswordGroup) {
        const error = await this._sessionService.registerAccount(this._tenantId, personData, fidelity, password);
        if (error != null) {
            console.error(error);
            return error;
        }
        return null;
    }


    goToCheckin(booking: BookingDto) {
        if(booking.idOrder==null || booking.idOrder==''){
            console.error("No idOrder for  checkin");
            return;
        }
        if (this._widgetConfigService.isMode_masterCamping) {
            if (this.hasUrlCheckIn == false) {
                console.error("No url for checkin");
                return;
            } else {
                const url = `${this._urlCheckIn}/${booking.idOrder}`;
                window.open(url, "_blank");
                return;
            }
        } else if (this._widgetConfigService.isMode_unixData) {
            const bookingData = this._bookingGroupCache.getBookingDetailsData(booking.idOrder);
            const checkInUrl = bookingData.precheckinUrl;
            if(checkInUrl==null || checkInUrl=='' ){
                console.error("No url for  checkIn");
                return;
            }
            window.open(checkInUrl, "_blank");
        }
    }

    get selectedBookingDetailsData(): BookingDetailsDto {
        if (this._bookingGroupCache.selectedBooking == null) {
            console.error(`getting selected booking detail when hasn't`);
            return null as any as BookingDetailsDto;
        }
        if (this._bookingGroupCache.hasError(this._bookingGroupCache.selectedBooking)) {
            console.error(`getting selected booking, but error occurs on retrieving data form server`);
            return null as any as BookingDetailsDto;
        }
        return this._bookingGroupCache.getBookingDetailsData(this._bookingGroupCache.selectedBooking);
    }

    get selectedBooking(): BookingDto {
        if (this._bookingGroupCache.selectedBooking == null) {
            console.error(`getting selected booking when hasn't`);
        }
        const result = this._accountService.bookings.find((book) => book.idOrder == this._bookingGroupCache.selectedBooking);
        if (result == null) {
            console.error(`getting selected booking when hasn't`);
            return {};
        }
        return result;
    }

    async goBookingDetails(idOrder: string) {
        this.setIsLoading(true);
        try {
            this._bookingGroupCache.selectedBooking = idOrder;
            this._currentView = StatusKnowledge.VIEW_SECTIONS.LOGGED.BOOKING_DETAILS;
            if (this._bookingGroupCache.hasSelectedBooking) {
                this.reloadSelectedBooking().then(() => this.refreshAppComponents());
            } else {
                await this.reloadSelectedBooking();
            }

        } catch (err) {
            this.setIsLoading(false);
            console.error(err);
        }
        this.setIsLoading(false);
    }

    searchNewBooking() {
        if(this._widgetConfigService.isMode_masterCamping) {
            if (this.hasUrlNewBooking == false) {
                console.error("No url for new booking");
                return;
            }


            let bookingEngindeUrl = this._urlNewBooking;
            if (bookingEngindeUrl.endsWith("/")) {
                bookingEngindeUrl = bookingEngindeUrl.substring(0, bookingEngindeUrl.length - 1);
            }
            if (!bookingEngindeUrl.endsWith("/search")) {
                bookingEngindeUrl = bookingEngindeUrl + "/search";
            }
            const url = new URL(bookingEngindeUrl);
            const monCompteSessionBase64 = btoa(JSON.stringify(this._sessionService.sessionObject_inLocalStorage));
            url.searchParams.set("monCompteToken", monCompteSessionBase64);
            window.open(url.href, "_blank");

        }else if (this._widgetConfigService.isMode_unixData) {
            const searchStayUrl = this._widgetConfigService.widgetParam?.searchStayUrl;
            if(searchStayUrl==null || searchStayUrl==''){
                console.error("No  url for  new booking");
                return;
            }
            const sessionObj = this._sessionService.sessionObject_inLocalStorage;
            if(sessionObj==null){
                console.error("No session for url new booking");
                return;
            }
            const tokenForUrl = sessionObj.session.replace('|','%7C')
            const url =searchStayUrl.replace('{monCompteToken}','&monCompteToken='+tokenForUrl);
            window.open(url, "_blank");

        }
    }


    generateImagePath(idOrder: string) {
        if (this._widgetConfigService.isMode_masterCamping) {
            if (this._bookingGroupCache.hasError(idOrder)) {
                return AssetsKnowledge.IMAGES_PATH.DEFAULT;
            }
            const bookingDetail = this._bookingGroupCache.getBookingDetailsData(idOrder);
            if (bookingDetail.libcateg == null || bookingDetail.libcateg == '') {
                return AssetsKnowledge.IMAGES_PATH.DEFAULT;
            }
            // const libCateg = bookingDetail.libcateg.toUpperCase();
            const libCateg = this.normalizeFilename(bookingDetail.libcateg.toLowerCase());
            return AssetsKnowledge.F_ACCOMMODATION_IMAGES_URL(this._tenantId, libCateg);
        } else if (this._widgetConfigService.isMode_unixData) {
            const booking = this._accountService.bookings.find((book) => book.idOrder == idOrder);
            if (booking == null || booking.categPictures == null || booking.categPictures.length == 0) {
                return AssetsKnowledge.IMAGES_PATH.DEFAULT;
            }
            return booking.categPictures[0];
        }
    }

    normalizeFilename(filename) {
        return filename.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9.]/g, "_");
    }


    async setNewPassword(newPassword: string) {
        return await this._sessionService.setNewPassword(this._tenantId, newPassword);
    }
}

const MyAccountStatus = new MyAccountStatusClass();
export { MyAccountStatus };
