import React, { FC } from 'react';
import { useTranslation } from "react-i18next";
import { MyAccountStatus } from "services";
import { StatusKnowledge } from "knowledge";
import { WidgetButton, WidgetHead } from "components";

interface PrincipalModalMyAccountRegisterProps {
}

const PrincipalModalMyAccountRegister: FC<PrincipalModalMyAccountRegisterProps> = () => {
  const {t} = useTranslation();
  const handleRegister=()=>{
    MyAccountStatus.navigate(StatusKnowledge.VIEW_SECTIONS.NON_LOGGED.REGISTER);
  }
  return (
    <div className="principal-modal-my-account-register">
      <WidgetHead size={2}>{t("my-account-main-view.account-register.title")}</WidgetHead>
      <div>{t("my-account-main-view.account-register.text")}</div>
      <WidgetButton onClick={handleRegister} className="my-account-global-btn-02">{t("my-account-main-view.account-register.button")}</WidgetButton>
    </div>
  );
}

export default PrincipalModalMyAccountRegister;
