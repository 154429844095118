import * as Yup from "yup";
import { PersonKnowledge, UniversalNaming as Uni } from "knowledge";
import { PersonDto } from "../openapi";
import { DateUtils } from "../../lib";


const phoneRegExp = /^[0-9/ ]+$/

const functorRequired = (yupSchema, field: string) =>
    PersonKnowledge.REQUIRED_FIELDS.includes(field) ?
        yupSchema.required('form-errors.required')
        : yupSchema;

export class PersonSchema {

    static validationSchema_Form(): Yup.ObjectSchema<any> {

        const validationSchema: Yup.ObjectSchema<any> = Yup.object().shape({
            [Uni.CIVILITY]: functorRequired(Yup.string().default(PersonKnowledge.DEFAULT_TREATMENT), Uni.CIVILITY),
            [Uni.LAST_NAME]: functorRequired(Yup.string().trim(), Uni.LAST_NAME),
            [Uni.FIRST_NAME]: functorRequired(Yup.string().trim(), Uni.FIRST_NAME),
            [Uni.COUNTRY]: functorRequired(Yup.string().trim().default(PersonKnowledge.DEFAULT_COUNTRY), Uni.COUNTRY),
            [Uni.LANGUAGE]: functorRequired(Yup.string().trim(), Uni.LANGUAGE),
            [Uni.BIRTH_DATE]: functorRequired(Yup.date(), Uni.BIRTH_DATE),
            [Uni.EMAIL]: functorRequired(Yup.string().email('form-errors.invalid'), Uni.EMAIL),
            [Uni.ADDRESS_1]: functorRequired(Yup.string().trim(), Uni.ADDRESS_1),
            [Uni.ADDRESS_2]: functorRequired(Yup.string().trim(), Uni.ADDRESS_2),
            [Uni.POST_CODE]: functorRequired(Yup.string().trim(), Uni.POST_CODE),
            [Uni.CITY]: functorRequired(Yup.string().trim(), Uni.CITY),
            [Uni.PHONE]: functorRequired(Yup.string().trim().matches(phoneRegExp, 'form-errors.invalid'), Uni.PHONE),
            [Uni.MOBILE_PHONE]: functorRequired(Yup.string().trim().matches(phoneRegExp, 'form-errors.invalid'), Uni.MOBILE_PHONE)
        });
        return validationSchema;
    }

    static initialValuesForm(data: PersonDto = {}) {
        return ({
            [Uni.CIVILITY]: data.civility?.value ?? PersonKnowledge.DEFAULT_TREATMENT,
            [Uni.LAST_NAME]: data.lastName ?? '',
            [Uni.FIRST_NAME]: data.firstName ?? '',
            [Uni.COUNTRY]: data.country ?? PersonKnowledge.DEFAULT_COUNTRY,
            [Uni.LANGUAGE]: data.language ?? PersonKnowledge.DEFAULT_LANGUAGE,
            [Uni.BIRTH_DATE]: (data.birthDate != null ? new Date(data.birthDate) : new Date()).toISOString().slice(0, 10),
            [Uni.EMAIL]: data.email ?? '',
            [Uni.ADDRESS_1]: data.address1 ?? '',
            [Uni.ADDRESS_2]: data.address2 ?? '',
            [Uni.POST_CODE]: data.postCode ?? '',
            [Uni.CITY]: data.city ?? '',
            [Uni.PHONE]: data.phone ?? '',
            [Uni.MOBILE_PHONE]: data.mobilePhone ?? ''
        })
    }

    static valuesToPerson(values: { [p: string]: string }): PersonDto {
        const result: PersonDto = {
            address1: values[Uni.ADDRESS_1],
            address2: values[Uni.ADDRESS_2],
            birthDate: DateUtils.formatDate_ToApi(values[Uni.BIRTH_DATE]),
            city: values[Uni.CITY],
            email: values[Uni.EMAIL],
            firstName: values[Uni.FIRST_NAME],
            language: values[Uni.LANGUAGE],
            lastName: values[Uni.LAST_NAME],
            mobilePhone: values[Uni.MOBILE_PHONE],
            phone: values[Uni.PHONE],
            postCode: values[Uni.POST_CODE],
            country: values[Uni.COUNTRY]


        };
        if (values[Uni.CIVILITY] != null) {
            result.civility = {value: values[Uni.CIVILITY]};

        }
        return result;
    }
}
