import React, { FC, useState } from 'react';
import { AssetsKnowledge } from 'knowledge';


interface ImageWithValidatorProps {

}

const ImageWithValidator = (props) => {
    const [picture, setPicture] = useState(props['picture'] || null ? props['picture'] : '');
    const handleImagenError = () => {
        if(picture != AssetsKnowledge.IMAGES_PATH.DEFAULT) {
            setPicture(AssetsKnowledge.IMAGES_PATH.DEFAULT);
        }
    };


    return (
        <img 
        src={picture} 
          {...props}
          onError={handleImagenError}
        />
    );
};

export default ImageWithValidator;
