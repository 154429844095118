import {UniversalNaming as Uni} from "./universal-naming";

export class PersonKnowledge {
    static COUNTRY_CODES = ["AD", "AE", "AF", "AG", "AI", "AL", "AM", "AN", "AO", "AQ", "AR", "AS", "AT", "AU",
        "AW", "AZ", "BA", "BB", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BM", "BN", "BNL", "BO", "BR", "BS", "BT",
        "BV", "BW", "BY", "BZ", "CA", "CC", "CD", "CF", "CG", "CH", "CI", "CK", "CL", "CM", "CN", "CO", "CR", "CU",
        "CV", "CX", "CY", "CZ", "DE", "DJ", "DK", "DM", "DO", "DZ", "EC", "EE", "EG", "EH", "ER", "ES", "ET", "FI",
        "FJ", "FK", "FM", "FO", "FR", "GA", "GB", "GD", "GE", "GF", "GH", "GI", "GL", "GM", "GN", "GP", "GQ", "GR",
        "GS", "GT", "GU", "GW", "GY", "HK", "HM", "HN", "HR", "HT", "HU", "ID", "IE", "IL", "IN", "IO", "IQ", "IR",
        "IS", "IT", "JM", "JO", "JP", "KE", "KG", "KH", "KI", "KM", "KN", "KP", "KR", "KW", "KY", "KZ", "LA", "LB",
        "LC", "LI", "LK", "LR", "LS", "LT", "LU", "LV", "LY", "MA", "MC", "MD", "MG", "MH", "MK", "ML", "MM", "MN",
        "MO", "MP", "MQ", "MR", "MS", "MT", "MU", "MV", "MW", "MX", "MY", "MZ", "NA", "NC", "NE", "NF", "NG", "NI",
        "NL", "NO", "NP", "NR", "NU", "NZ", "OM", "PA", "PE", "PF", "PG", "PH", "PK", "PL", "PM", "PN", "PR", "PS",
        "PT", "PW", "PY", "QA", "RE", "RO", "RU", "RW", "SA", "SB", "SC", "SD", "SE", "SG", "SH", "SI", "SK", "SL",
        "SM", "SN", "SO", "SR", "ST", "SV", "SY", "SZ", "TC", "TD", "TF", "TG", "TH", "TJ", "TK", "TL", "TM", "TN",
        "TO", "TR", "TT", "TV", "TW", "TZ", "UA", "UG", "UM", "US", "UY", "UZ", "VA", "VC", "VE", "VG", "VI", "VN",
        "VU", "WF", "WS", "YE", "YT", "YU", "ZA", "ZM", "ZW"];

    static LANGUAGE_CODES = ["fr", "en", "es", "nl", "de", "it", "pt", "ca", "da"];
    static TREATMENT_CODES = ["Mr.","Ms.", "Miss", "Ms.,Mr."];
    static DEFAULT_TREATMENT = "Mr.";
    static DEFAULT_COUNTRY = "ES";
    static DEFAULT_LANGUAGE = "es";

    static REQUIRED_FIELDS = [Uni.LAST_NAME,Uni.FIRST_NAME,Uni.COUNTRY,Uni.LANGUAGE,Uni.EMAIL,Uni.ADDRESS_1,Uni.CITY];
    static MAX_LENGTH_FIELDS = {
        [Uni.LAST_NAME]:49,
        [Uni.FIRST_NAME]:49,
        [Uni.CITY]:49,
        [Uni.ADDRESS_1]:38,
        [Uni.ADDRESS_2]:38
    }

}
