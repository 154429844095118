import React, { FC, useEffect, useState } from 'react';
import { PersonKnowledge, UniversalNaming } from "knowledge";
import { useTranslation } from "react-i18next";


const TreatmentOptions = PersonKnowledge.TREATMENT_CODES;

const TreatmentSelectorFormik = (props) => {
    const {t} = useTranslation();
    const tag = props['tag'] ?? null;

    const field = UniversalNaming.CIVILITY;
    const isRequiredClass = PersonKnowledge.REQUIRED_FIELDS.includes(field) ? ' required' : '';


    return (
        <div className={`treatment-selector ${isRequiredClass}`}>
            <select {...props}>

                {TreatmentOptions.map((option) =>
                    <option key={option}
                            value={option}>{t("my-account-main-view.person-form.treatment-options." + option)}</option>)}
            </select>
            {tag != null && <label className={isRequiredClass}>{t(tag)}</label>}
        </div>
    );
};

export default TreatmentSelectorFormik;
