import React, { FC, useState } from 'react';
import { WidgetConfig } from "services";

interface WidgetHeadProps {
    size?: number
    className?: string
    children?: React.ReactNode
    icon?: string
    cssIcon?: string
}

const WidgetHead: FC<WidgetHeadProps> = ({size, className, children, icon, cssIcon}) => {
    const Tag = `h${size || 2}`;
    const props = {};
    
    if (className != null && className.length > 0) {
        props['className'] = className;
    }
    const fontColorInHeaders = WidgetConfig.fontColorInHeaders;
    if(fontColorInHeaders != null && fontColorInHeaders.length > 0) {
        props['style'] = {color: fontColorInHeaders};
    }
    const head = React.createElement(Tag, props, children);
    if (cssIcon != null) {
        return (
            <div className="my-account-global-title-and-icon">
                {cssIcon && <i className={cssIcon}></i>}
                {head}
            </div>
            )
    }

    return head;

}

export default WidgetHead;
