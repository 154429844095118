import React, { FC } from 'react';
import { useTranslation } from "react-i18next";
import { BookingDetailsDto } from "services/openapi";
import WidgetHead from "../widget-customizable/WidgetHead/WidgetHead";


interface BookingDetailProfileBalanceProps {
    bookingDetails: BookingDetailsDto
}

const BookingDetailProfileBalance: FC<BookingDetailProfileBalanceProps> = ({bookingDetails}) => {

    const {t} = useTranslation();

    const hasUnpaidBalance = (bookingDetails?.balance || 0) > 0;
    return (
        <div className="booking-detail-profile-balance">
            <div className="booking-detail-profile-balance__title">
                <WidgetHead size={3}>{t("my-account-main-view.stays.detail.profile-data.stay-section")}<span
                    className="booking-detail-profile-balance__asterisk">*</span>
                    {hasUnpaidBalance ? <i className="edm-icon-circle-xmark-solid"></i> :
                        <i className="edm-icon-circle-check-solid"></i>}
                </WidgetHead>
            </div>

            <div className="booking-detail-profile-balance__body">
                <span className="booking-detail-profile-balance__body__text">
                    {t("my-account-main-view.stays.detail.profile-data.stay-payment")}
                </span>
                {hasUnpaidBalance ? 
                    <span className="booking-detail-profile-balance__body__unpaid">
                        {bookingDetails.balance} €
                    </span> 
                    :
                    <span className="booking-detail-profile-balance__body__paid">
                        {t("my-account-main-view.stays.detail.profile-data.balance-paid")}
                    </span>
                }
            </div>
        </div>
    );
};

export default BookingDetailProfileBalance;
