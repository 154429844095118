let URL_SOURCE = '';
try {
    const url = (document.currentScript as any)['src'];

    const UrlObj = new URL(url);
    URL_SOURCE=UrlObj.origin;
} catch (err) {
    console.error(err);
}
export { URL_SOURCE };
