export class ContactKnowledge {
    static CONTACT_REQUEST_OPTIONS = {
        AVAILABILITY_REQUEST: "availability-request",
        BOOKING:"booking",
        CLAIM:"claim",
        PHONE_NUMBER:"phone-number"
    }
    static CONTACT_REQUEST_OPTIONS_API= {
        AVAILABILITY_REQUEST: 1,
        BOOKING:2,
        CLAIM:3
    }

    static ACCOMMODATION_TYPES={
        RENTING:"location",
        PLACE:"camping"
    }
}
