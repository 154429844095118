import React, { FC } from 'react';
import { useTranslation } from "react-i18next";
import { MyAccountStatus } from "services";
import { BookingDetailsDto, BookingDto } from "services/openapi";
import { StatusKnowledge } from "knowledge";
import { BookingDetailProfileBalance, WidgetButton, WidgetHead } from "components";
import BookingDetailProfileErrors from "./components/BookingDetailProfileErrors/BookingDetailProfileErrors";
import BookingDetailProfileFriend from "./components/BookingDetailProfileFriend/BookingDetailProfileFriend";


interface BookingDetailProfileProps {
    booking: BookingDto;
    bookingDetails: BookingDetailsDto
}

const BookingDetailProfile: FC<BookingDetailProfileProps> = ({booking, bookingDetails}) => {
    const {t} = useTranslation();

    const handleEdit = () => {
        MyAccountStatus.navigate(StatusKnowledge.VIEW_SECTIONS.LOGGED.BOOKING_DETAILS_EDIT);
    }
    return (
        <div className="booking-detail-profile">
            <div className="padding-standard-1">
                <WidgetHead>{t("my-account-main-view.stays.detail.profile-data.title")}</WidgetHead>
                <BookingDetailProfileErrors bookingDetails={bookingDetails}/>
                <BookingDetailProfileFriend bookingDetails={bookingDetails}/>
                <BookingDetailProfileBalance bookingDetails={bookingDetails}/>
                <WidgetButton
                    onClick={handleEdit}>{t("my-account-main-view.stays.detail.profile-data-edit.button-text")}</WidgetButton>
            </div>
        </div>
    );
};

export default BookingDetailProfile;
