import React, { FC } from 'react';
import { MyAccountStatus } from "services";
import FidelityData from "./components/FidelityData/FidelityData";
import FidelityHistory from "./components/FidelityHistory/FidelityHistory";

interface FidelityProps {
}

const Fidelity: FC<FidelityProps> = () => (
    <div className="fidelity padding-standard-1">
        <FidelityData/>
        <FidelityHistory/>
    </div>
);


export default Fidelity;
