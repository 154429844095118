export class StatusKnowledge {
    static VIEW_SECTIONS = {
        NON_LOGGED: {
            NON_LOGGED_MAIN: 'NON_LOGGED_MAIN',
            REGISTER: 'REGISTER',
            RECOVERY_PASSWORD: 'RECOVERY_PASSWORD',
            SET_NEW_PASSWORD: 'SET_NEW_PASSWORD'
        },
        LOGGED: {
            STAYS: 'STAYS',
            BOOKING_DETAILS: 'BOOKING_DETAILS',
            BOOKING_DETAILS_EDIT: 'BOOKING_DETAILS_EDIT',
            PROFILE: 'PROFILE',
            FIDELITY: 'FIDELITY',
            CONTACT: 'CONTACT'
        }
    }

    static DEFAULT_LANGUAGE = 'en';
    static ALLOWED_LANGUAGES = ['ca', 'de', 'en', 'es', 'fr', 'nl', 'pt'];
}
