import { AssetsKnowledge } from "../knowledge";

async function loadAssetsFromSource(myAccountElement) {
    await loadFontsFromSource(myAccountElement);

}

async function loadFontsFromSource(myAccountElement) {
    const fontPaths = Object.values(AssetsKnowledge.FONTS_PATH);
    for (const fontPath of fontPaths) {
        const myFont = new FontFace(AssetsKnowledge.MY_ACCOUNT_FONT_NAME, `url(${fontPath})`);
        try {
            await myFont.load();
            document.fonts.add(myFont);

        } catch (e) {
            console.error(`myAccount from ${fontPath} icons font failed to load`);
            throw e;
        }
    }
    myAccountElement.style.fontFamily = AssetsKnowledge.MY_ACCOUNT_FONT_NAME;
}

export {loadAssetsFromSource};
