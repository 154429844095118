const EMAIL_REGEXP = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export class FormUtils {
    static countLowerCase: (string) => number = (s) => s.split('').filter((char) => (char >= 'a' && char <= 'z')).length;
    static countUpperCase: (string) => number = (s) => s.split('').filter((char) => (char >= 'A' && char <= 'Z')).length;
    static countNumber: (string) => number = (s) => s.split('').filter((char) => (char >= '0' && char <= '9')).length;
    static countSymbol: (string) => number = (s) => s.split('').filter((char) => (char >= '!' && char <= '/')).length;

    static emailIsValid = (email:string) => EMAIL_REGEXP.test(email);

}
