import React, { FC, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { NotificationKnowledge } from "knowledge";

interface NotificationProps {
    input: NotificationInput;
    handleHideNotification?: () => void;
}

export type NotificationInput = {
    tag: string;
    level?: string;
}

const NotificationInternal: FC<NotificationProps> = ({input, handleHideNotification}) => {
    const {t} = useTranslation();
    const [show, setShow] = React.useState<boolean>(true);

    const handleClose = () => {
        setShow(false);
        if (handleHideNotification != null) {
            handleHideNotification();
        }
    }
/*
    useEffect(() => {
        const timeoutId = setTimeout(() => handleClose(), NotificationKnowledge.ALERT_TIME_EXPIRATION);
        return () => clearTimeout(timeoutId);
    },[input.tag]);
*/
    if (!show || input?.tag == null || input?.tag.length == 0) {
        return null;
    }

    const classLevel = input.level ?? '';
    return (
        <div className={`notification notification-2 ${classLevel}`}>
            <div>
                <i className={classLevel == 'error' ? 'edm-icon-warning' : 'edm-icon-check-1'}></i>
                &nbsp;
                {t(input.tag)}
            </div>
            <button onClick={handleClose} className="notification__close">x</button>
        </div>
    );
};

const Notification: FC<NotificationProps> = ({input, handleHideNotification}) => {
    if (input?.tag == null || input?.tag.length == 0) {
        return null;
    }
    return (
        <NotificationInternal input={input} handleHideNotification={handleHideNotification}/>
    );
};
export default Notification ;
