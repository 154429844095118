import React, { FC } from 'react';
import { UniversalNaming as Uni , AssetsKnowledge } from "knowledge";
import type { CustomerFriendDto } from "services/openapi";
import { InputDate, InputText , ImageWithValidator} from "components";

interface AccountFriendItemProps {
    friend: CustomerFriendDto;
    handleDelete: () => void;
}

const AccountFriendItem: FC<AccountFriendItemProps> = ({friend, handleDelete}) => {

    if (friend.visible != true) {
        return null; // do not display non visible friends
    }
    const changePropertyFunc = (property) => (value) => {
        friend[property] = value;
    }
    return (
        <div className="account-friend-item ">
            {handleDelete!=null && <button onClick={handleDelete} className="account-friend-item__delete"><ImageWithValidator picture={AssetsKnowledge.IMAGES_PATH.TRASH} width="25" /></button>}
            <InputText tag="my-account-main-view.person-form.lastname" value={friend.lastName}
                       onChange={changePropertyFunc(Uni.LAST_NAME)}/>
            <InputText tag="my-account-main-view.person-form.firstname" value={friend.firstName}
                       onChange={changePropertyFunc(Uni.FIRST_NAME)}/>
            <InputDate tag="my-account-main-view.person-form.birthdate" value={friend.birthdate}
                       onChange={changePropertyFunc(Uni.BIRTH_DATE_m)}/>
        </div>
    );
};

export default AccountFriendItem;
