import React, { FC } from 'react';
import AccountData from "./components/AccountData/AccountData";
import AccountFidelity from "./components/AccountFidelity/AccountFidelity";
import AccountFriends from "./components/AccountFriends/AccountFriends";
import AccountVehicles from "./components/AccountVehicles/AccountVehicles";
import AccountChangePassword from "./components/AccountChangePassword/AccountChangePassword";
import { MyAccountStatus, StaticParamsInstance } from "services";

interface AccountProps {}

const Account: FC<AccountProps> = () => {
    return (
        <div className="account padding-standard-1">
            <AccountData/>
            {MyAccountStatus.isFidelitySubscribed &&  <AccountFidelity/>}
          {StaticParamsInstance.hideCompanionsEdition == false && <AccountFriends/>}
          {StaticParamsInstance.hideVehiclesEdition == false && <AccountVehicles/>}
            <AccountChangePassword/>
        </div>
    );
};

export default Account;
