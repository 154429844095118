import React, { FC } from 'react';
import ModalStaysFutureStays from "./components/ModalStaysFutureStays/ModalStaysFutureStays";
import ModalStaysPastStays from "./components/ModalStaysPastStays/ModalStaysPastStays";
import { MyAccountStatus } from "services";
import ModalNoStays from "./components/ModalNoStays/ModalNoStays";
import SiteInfoButton from "./components/SiteInfoButton/SiteInfoButton";

interface ModalStaysProps {
}

const ModalStays: FC<ModalStaysProps> = () => {

    const allBookings = MyAccountStatus.bookings;
    const futureBookings = allBookings.filter((booking) => booking.nbDay == null || booking.nbDay >= 0)
    .sort((a, b) => (a.nbDay??Number.MAX_SAFE_INTEGER) - (b.nbDay??Number.MAX_SAFE_INTEGER));
    const pastStays = allBookings.filter((booking) => booking.nbDay != null && booking.nbDay < 0);

    const noStays = (futureBookings.length === 0 && pastStays.length === 0);

    return (
        <div className="modal-stays padding-standard-1">
            {MyAccountStatus.hideNewBookingButton == false && <ModalNoStays/>}
            <SiteInfoButton/>
            {noStays == false && <>
                <ModalStaysFutureStays bookings={futureBookings}/>
                <ModalStaysPastStays bookings={pastStays}/>
            </>
            }
        </div>
    );
}

export default ModalStays;
