import React, { FC, useState } from 'react';
import { useTranslation } from "react-i18next";
import { MyAccountStatus } from "services";
import { WidgetButton, WidgetHead,Notification } from "components";
import { NotificationKnowledge, StatusKnowledge } from "knowledge";
import { useNotification } from "lib";


interface PrincipalModalLoginProps {
}

const PrincipalModalLogin: FC<PrincipalModalLoginProps> = () => {

    const {t} = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const {notification, setNotification, handleHideNotification} = useNotification();

    const [showPassword, setShowPassword] = useState<boolean>(false);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleResetPassword = (event) => {
        event.preventDefault();
        MyAccountStatus.navigate(StatusKnowledge.VIEW_SECTIONS.NON_LOGGED.RECOVERY_PASSWORD);
    }

    const handleLogin = () => {
        MyAccountStatus.login(email, password).then((error) => {
            if (error) {
                setNotification("my-account-main-view.no-logged.login-error", NotificationKnowledge.NOTIFICATION_LEVELS.ERROR);
            }
        }).catch((error) => {
            console.error(error);
            setNotification("my-account-main-view.no-logged.login-error", NotificationKnowledge.NOTIFICATION_LEVELS.ERROR);
        });
    }


    const iconClass = showPassword ? 'edm-icon-eye-slash-regular' : 'edm-icon-eye-regular';

    const [hasEmailFocus, setEmailHasFocus] = useState(false);
    const [hasPasswordFocus, setPasswordHasFocus] = useState(false);

    const handleEmailFocus = (e) => {
        setEmailHasFocus(true);
    }

    const handleEmailBlur = (e) => {
        setEmailHasFocus(false);
    }

    const handlePasswordFocus = (e) => {
        setPasswordHasFocus(true);
    }

    const handlePasswordBlur = (e) => {
        setPasswordHasFocus(false);
    }

    const handleKeyDown = (event) => {
        // Verificar si la tecla presionada es Enter
        if (event.key === 'Enter') {
            handleLogin();
        }
    };

    const isEmailFilled = email.trim().length > 0;
    const isPasswordFilled = password.trim().length > 0;

    return (
        <div className="principal-modal-login">
            <WidgetHead size={2}>{t("my-account-main-view.no-logged.has_account")}</WidgetHead>

            <div className="position-relative principal-modal-login-group">
                <input
                    type="text"
                    value={email}
                    onChange={handleEmailChange}
                    onFocus={handleEmailFocus}
                    onBlur={handleEmailBlur}
                    //placeholder={t("my-account-main-view.no-logged.email")}
                />
                <label className={`login-label ${hasEmailFocus || isEmailFilled ? 'label-active' : ''}`}>
                    {t("my-account-main-view.no-logged.email")}
                </label>
            </div>

            <div className="position-relative principal-modal-login-group">
                <i 
                    className={`password-input__show-icon ${iconClass}`}
                    onClick={() => setShowPassword(!showPassword)}
                    ></i>
                <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={handlePasswordChange}
                    //placeholder={t("my-account-main-view.no-logged.password")}
                    onFocus={handlePasswordFocus}
                    onBlur={handlePasswordBlur}
                    onKeyDown={handleKeyDown}
                />
                <label className={`login-label ${hasPasswordFocus || isPasswordFilled ? 'label-active' : ''}`}>
                    {t("my-account-main-view.no-logged.password")}
                </label>
            </div>
            <label onClick={handleResetPassword} className="widget-font-color-1 label-forgot-password">
                {t("my-account-main-view.no-logged.forgot-password")}
            </label>
            <WidgetButton onClick={handleLogin} className="my-account-global-btn-02">{t("my-account-main-view.no-logged.login")}</WidgetButton>
            <Notification input={notification} handleHideNotification={handleHideNotification}/>
        </div>
    );
}

export default PrincipalModalLogin;
