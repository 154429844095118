import React, { FC } from 'react';
import { MyAccountStatus } from "services";
import { StatusKnowledge } from "knowledge";
import SetNewPassword from "./RecoveryPassword/SetNewPassword";
import PrincipalModalRegisterAccount from "./PrincipalModalRegisterAccount/PrincipalModalRegisterAccount";
import NonLoggedMainView from "./NonLoggedMainView/NonLoggedMainView";
import RecoveryPassword from "./SetNewPassword/RecoveryPassword";


interface ModalNoLoggedProps {
}

const ModalNoLogged: FC<ModalNoLoggedProps> = () => (
    <div className="principal-modal-no-logged">
        {MyAccountStatus.currentView === StatusKnowledge.VIEW_SECTIONS.NON_LOGGED.NON_LOGGED_MAIN &&
          <NonLoggedMainView/>}
        {MyAccountStatus.currentView === StatusKnowledge.VIEW_SECTIONS.NON_LOGGED.RECOVERY_PASSWORD &&
          <RecoveryPassword/>}
        {MyAccountStatus.currentView === StatusKnowledge.VIEW_SECTIONS.NON_LOGGED.SET_NEW_PASSWORD &&
          <SetNewPassword/>}
        {MyAccountStatus.currentView === StatusKnowledge.VIEW_SECTIONS.NON_LOGGED.REGISTER &&
          <PrincipalModalRegisterAccount/>}

    </div>
);

export default ModalNoLogged;
