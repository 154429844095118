import React, { FC } from 'react';
import { BookingDto } from "services/openapi";
import { DateUtils } from "lib";
import { useTranslation } from "react-i18next";
import { MyAccountStatus } from "services";
import { WidgetButton } from "components";
import { AccommodationTypePicture } from "components";

interface FutureStayItemProps {
    booking: BookingDto;
}

const FutureStayItem: FC<FutureStayItemProps> = ({booking}) => {

    const {t} = useTranslation();

    const picture = booking.categPictures != null ? booking.categPictures[0] : null;

    const begin: string | null = booking.begin != null ? DateUtils.formatDate(booking.begin) : null;
    const end: string | null = booking.end != null ? DateUtils.formatDate(booking.end) : null;

    const handleDetails = () => {
        if (booking.idOrder != null) {
            MyAccountStatus.goBookingDetails(booking.idOrder)
        } else {
            console.error(`missing booking idOrder: ${JSON.stringify(booking)}`);
        }

    };

    return (
        <div className="future-stay-item">
            <div className="future-stay-item__picture-container">
                <AccommodationTypePicture idOrder={booking.idOrder}/>
            </div>
            <div className="past-stay-item__container">
                {
                    booking.site != null &&
                  <div className="future-stay-item__site my-account-global-title-and-icon">
                      <i className="edm-icon-campground"></i>
                      <strong>{booking.site}</strong>
                  </div>
                }
                {
                    booking.idOrder != null &&
                  <div className="future-stay-item__idOrder my-account-global-title-and-icon">
                      <i className="edm-icon-file"></i>
                      {booking.idOrder}
                  </div>
                }
                {
                    begin != null && end != null &&
                  <div className="future-stay-item__calendar my-account-global-title-and-icon">
                      <i className="edm-icon-date"></i>
                      {t("my-account-main-view.stays.form.calendar", {begin: begin, end: end})}
                  </div>
                }
                {
                    booking.lodging != null &&
                  <div className="future-stay-item__lodging my-account-global-title-and-icon">
                      <i className="edm-icon-home"></i>
                      {booking.lodging}
                  </div>
                }
                {
                    booking.nbPers != null && booking.nbPers > 0 &&
                  <div className="future-stay-item__nbPers my-account-global-title-and-icon">
                      <i className="edm-icon-users2"></i>
                      {t("my-account-main-view.stays.form.persons", {count: booking.nbPers})}
                  </div>
                }
                <div className="future-stay-item__price">
                    <div className="future-stay-item__price-value my-account-global-title-and-icon">
                        <i className="edm-icon-euro"></i>
                        {booking.price ?? 0} {t("my-account-main-view.stays.form.currency")}
                    </div>
                    {booking.deposit != null && booking.deposit > 0 &&
                      <div
                        className="future-stay-item__price-deposit">{t("my-account-main-view.stays.form.deposit-text", {deposit: booking.deposit})}</div>}
                </div>
            </div>
            {
                booking.precheckinStatus != null && <div className="future-stay-item__precheckin-status">
                    {booking.precheckinStatus == 'ok' &&
                      <div className="future-stay-item__precheckin-status correct color-green-2 padding-left-15px">
                          <i className="edm-icon-circle-check-solid"></i>
                          {t("my-account-main-view.stays.future.complete")}
                      </div>}
                    {booking.precheckinStatus == 'ko' &&
                      <div className="future-stay-item__precheckin-status error color-red padding-left-15px">
                          <i className="edm-icon-circle-xmark-solid"></i>
                          {t("my-account-main-view.stays.future.incomplete")}
                      </div>}
              </div>
            }
            <div className="future-stay-item__button ">
                <WidgetButton onClick={handleDetails}
                              className="my-account-global-btn-02">{t("my-account-main-view.stays.future.details")}</WidgetButton>
            </div>
        </div>
    );
};


export default FutureStayItem;
