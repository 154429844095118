import React, { FC, useState } from 'react';
import { useTranslation } from "react-i18next";
import { StatusKnowledge, AssetsKnowledge } from "knowledge";
import { MyAccountStatus, WidgetConfig } from "services";
import { ImageWithValidator } from 'components';

interface ModalNavigationProps {
}

const ModalNavigation: FC<ModalNavigationProps> = () => {

    const [elementoClicado, setElementoClicado] = useState<HTMLElement | null>(null);
    // const [isOpenMenuMobile, setIsOpenMenuMobile] = useState<boolean>(MyAccountStatus.isMenuViewOpen);
    const [isOpenMenuMobile, setIsOpenMenuMobile] = useState<boolean>(true);

    const {t} = useTranslation();
    const handleNavigation = (event, tag) => {
        MyAccountStatus.navigate(tag);
        const clickedElement = event.target as HTMLElement;
        setElementoClicado(clickedElement);
        setIsOpenMenuMobile(!isOpenMenuMobile);

    }
    const handleClick = () => {
        setIsOpenMenuMobile(!isOpenMenuMobile);

    }

    const backgroundColor = WidgetConfig.backgroundColor;
    const style = backgroundColor != null && backgroundColor.length > 0 ? {backgroundColor: backgroundColor} : {};
    const activeClass = 'active';

    return (
        <>
            <button onClick={handleClick} className="principal-modal-header__menu-mobile edm-icon-menu"></button>
             <nav className={`modal-navigation__bar ${MyAccountStatus.isMainViewOpen == false ? ' ' : activeClass} ${!isOpenMenuMobile?'hidden':' '}`} style={style}>
                <li
                    className={`modal-navigation__link ${MyAccountStatus.currentView == 'STAYS' ? activeClass : ''}`}
                    onClick={(event) => handleNavigation(event, StatusKnowledge.VIEW_SECTIONS.LOGGED.STAYS)}
                    >
                    {t("my-account-main-view.navigation.stays")}
                </li>
                <li
                    className={`modal-navigation__link ${MyAccountStatus.currentView == 'PROFILE' ? activeClass : ''}`}
                    onClick={(event) => handleNavigation(event, StatusKnowledge.VIEW_SECTIONS.LOGGED.PROFILE)}
                    >
                    {t("my-account-main-view.navigation.my-account")}
                </li>
                {
                    MyAccountStatus.isFidelityEnabled &&
                    <li
                        className={`modal-navigation__link ${MyAccountStatus.currentView == 'FIDELITY' ? activeClass : ''}`}
                        onClick={(event) => handleNavigation(event, StatusKnowledge.VIEW_SECTIONS.LOGGED.FIDELITY)}
                        >
                        {t("my-account-main-view.navigation.fidelity")}
                    </li>
                }
                <li
                    className={`modal-navigation__link ${MyAccountStatus.currentView == 'CONTACT' ? activeClass : ''}`}
                    onClick={(event) => handleNavigation(event, StatusKnowledge.VIEW_SECTIONS.LOGGED.CONTACT)}
                    >
                    {t("my-account-main-view.navigation.contact")}
                </li>
            </nav>
        </>
    );
}

export default ModalNavigation;
