import React, { FC, useState } from 'react';
import { useTranslation } from "react-i18next";
import { ContactKnowledge, NotificationKnowledge } from "knowledge";
import { useNotification } from "lib";
import { ContactRequestService } from "services";
import { Notification, WidgetButton } from "components";

interface ContactRequestAvailabilityProps {
    handleSuccessSent: () => void;
}

const ContactRequestAvailability: FC<ContactRequestAvailabilityProps> = ({handleSuccessSent}) => {
    const {t} = useTranslation();
    const [selectedAccommodationType, setSelectedAccommodationType] = React.useState(ContactKnowledge.ACCOMMODATION_TYPES.PLACE);
    const [selectedCheckinDate, setSelectedCheckinDate] = useState('');
    const [selectedCheckoutDate, setSelectedCheckoutDate] = useState('');
    const [comment, setComment] = useState('');
    const {notification, setNotification, handleHideNotification} = useNotification();

    const handleAccTypeChange = (event) => {
        setSelectedAccommodationType(event.target.value);
    };

    const handleCheckinDateChange = (event) => {
        setSelectedCheckinDate(event.target.value);
    };
    const handleCheckoutDateChange = (event) => {
        setSelectedCheckoutDate(event.target.value);
    };

    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };

    const formCorrect = selectedCheckinDate !== '' && selectedCheckoutDate !== '' && comment !== '';
    const handleSend = () => {
        ContactRequestService.contactRequestAvailability(selectedAccommodationType,
            selectedCheckinDate, selectedCheckoutDate, comment).then((error) => {
            if (error) {
                setNotification("my-account-main-view.account.save-fail", NotificationKnowledge.NOTIFICATION_LEVELS.ERROR);
            } else {
                setNotification("my-account-main-view.account.save-success", NotificationKnowledge.NOTIFICATION_LEVELS.SUCCESS);
            }
        }).catch((error) => {
            setNotification("my-account-main-view.account.save-fail", NotificationKnowledge.NOTIFICATION_LEVELS.ERROR);
        });
    };

    return (
        <div className="contact-request-availability">
            <div className="contact-request-availability-accommodationType">
                <div
                    className="contact-request-availability-accommodationType_text">{t("my-account-main-view.contact.contact-form.accommodation-type")}
                </div>
                <div>
                    <input
                        type="radio"
                        value={ContactKnowledge.ACCOMMODATION_TYPES.PLACE}
                        checked={selectedAccommodationType === ContactKnowledge.ACCOMMODATION_TYPES.PLACE}
                        onChange={handleAccTypeChange}
                    />
                    <label>{t("my-account-main-view.contact.contact-form.place")}</label>
                </div>
                <div>
                    <input
                        type="radio"
                        value={ContactKnowledge.ACCOMMODATION_TYPES.RENTING}
                        checked={selectedAccommodationType === ContactKnowledge.ACCOMMODATION_TYPES.RENTING}
                        onChange={handleAccTypeChange}
                    />
                    <label>{t("my-account-main-view.contact.contact-form.renting")}</label>
                </div>
            </div>
            <div className="contact-request-availability-dates">
                <div className="contact-request-availability-dates__checkin">
                    <input
                        type="date"
                        id="checkinInput"
                        className="label-active"
                        value={selectedCheckinDate}
                        onChange={handleCheckinDateChange}
                    />
                    <label htmlFor="checkinInput">{t("my-account-main-view.contact.contact-form.checkin")}</label>
                </div>
                <div className="contact-request-availability-dates__checkout">  
                    <input
                        type="date"
                        id="checkoutInput"
                        className="label-active"
                        value={selectedCheckoutDate}
                        onChange={handleCheckoutDateChange}
                    />
                    <label htmlFor="checkoutInput">{t("my-account-main-view.contact.contact-form.checkout")}</label>
                </div>
            </div>
            <div className="contact-request-comment input-text">
                <textarea
                    id="commentInput"
                    value={comment}
                    onChange={handleCommentChange}
                    rows={4}
                    cols={50}
                    className="label-active"
                />
                <label>{t("my-account-main-view.contact.contact-form.comment")}</label>
            </div>
            <WidgetButton disabled={!formCorrect}
                          onClick={handleSend} className="my-account-global-btn-02">{t("my-account-main-view.contact.contact-form.send")}</WidgetButton>
            <Notification input={notification} handleHideNotification={handleHideNotification}/>
        </div>
    );
};

export default ContactRequestAvailability;
