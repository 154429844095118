import React, { FC } from 'react';
import { MyAccountStatus } from "services";
import { StatusKnowledge } from "knowledge";
import ModalNavigation from "./ModalNavigation/ModalNavigation";
import ModalStays from "./ModalStays/ModalStays";
import Account from "./Account/Account";
import Contact from "./Contact/Contact";
import BookingDetail from "./BookingDetail/BookingDetail";
import Fidelity from "./Fidelity/Fidelity";
import BookingDetailEdit from "./BookingDetailEdit/BookingDetailEdit";


interface ModalLoggedProps {
}

const ModalLogged: FC<ModalLoggedProps> = () => (
        <div className="principal-modal">
            <div className="principal-modal__navigation">
                <ModalNavigation/>
            </div>
            <div className="principal-modal__content">
                {MyAccountStatus.currentView === StatusKnowledge.VIEW_SECTIONS.LOGGED.STAYS && <ModalStays/>}
                {MyAccountStatus.currentView === StatusKnowledge.VIEW_SECTIONS.LOGGED.PROFILE && <Account/>}
                {MyAccountStatus.currentView === StatusKnowledge.VIEW_SECTIONS.LOGGED.FIDELITY
                && MyAccountStatus.isFidelityEnabled
                 && <Fidelity/>}
                {MyAccountStatus.currentView === StatusKnowledge.VIEW_SECTIONS.LOGGED.CONTACT && <Contact/>}
                {MyAccountStatus.currentView === StatusKnowledge.VIEW_SECTIONS.LOGGED.BOOKING_DETAILS && <BookingDetail/>}
                {MyAccountStatus.currentView === StatusKnowledge.VIEW_SECTIONS.LOGGED.BOOKING_DETAILS_EDIT && <BookingDetailEdit/>}
            </div>
        </div>
    );

export default ModalLogged;
