import React, { FC } from 'react';
import { useTranslation } from "react-i18next";

interface ModelLoaderProps {}

const ModalLoader: FC<ModelLoaderProps> = () => {
    const {t} = useTranslation();
    return (
        <div className="principal-modal-loader loading"></div>
    );
};

export default ModalLoader;
