import React, { FC, useState } from 'react';
import { useTranslation } from "react-i18next";
import { MyAccountStatus } from "services";
import { Notification, WidgetButton, WidgetHead } from "components";
import { NotificationKnowledge, StatusKnowledge } from "knowledge";
import { useNotification } from "lib";

interface RecoveryPasswordProps {
}

const RecoveryPassword: FC<RecoveryPasswordProps> = () => {
    const {t} = useTranslation();
    const [isOk, setIsOk] = useState(false);
    const [email, setEmail] = useState('');
    const {notification, setNotification, handleHideNotification} = useNotification();

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleOk = () => {
        MyAccountStatus.sendRecoveryEmail(email).then(() => {
            setIsOk(true)
        })
        .catch((error) => {
            setNotification("my-account-main-view.account.save-fail", NotificationKnowledge.NOTIFICATION_LEVELS.ERROR);
        });

    }
    const handleCancel = () => {
        MyAccountStatus.navigate(StatusKnowledge.VIEW_SECTIONS.NON_LOGGED.NON_LOGGED_MAIN);
    }


    return (
        <div className="recovery-password">
            <WidgetHead size={2}>{t("my-account-main-view.recovery-password.title")}</WidgetHead>
            <div>{t("my-account-main-view.recovery-password.text")}</div>

            {!isOk && <>
                <input
                  type="text"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder={t("my-account-main-view.recovery-password.email-to-recover")}
                />
                <div className="recovery-password__buttons">
                    <button onClick={handleCancel}  className="my-account-global-btn-01">{t("my-account-main-view.recovery-password.cancel")}</button>
                    <WidgetButton onClick={handleOk} className="my-account-global-btn-02">{t("my-account-main-view.recovery-password.send")}</WidgetButton>
                </div>
            </>}
            {isOk && <>
                <div className="recovery-password-ok__text" >{t("my-account-main-view.recovery-password.successfully-sent", {email: email})}</div>
                <button  className="recovery-password-ok__back-button" onClick={handleCancel}>
                    {t("my-account-main-view.recovery-password.return")}
                </button>
            </>}
            <Notification input={notification} handleHideNotification={handleHideNotification}/>
        </div>
    );
}

export default RecoveryPassword;
