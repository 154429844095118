import React, { FC } from 'react';
import { useTranslation } from "react-i18next";
import WidgetHead from "../widget-customizable/WidgetHead/WidgetHead";
import { AssetsKnowledge } from 'knowledge';

interface FidelityFormProps {
    fidelity: boolean,
    setFidelity: (value: boolean) => void;
}

const FidelityForm: FC<FidelityFormProps> = ({fidelity, setFidelity}) => {
    const {t}=useTranslation();
    const classLabel = fidelity ? 'switch-button__label  active' : 'switch-button__label';
    const handleChange= () => {
        setFidelity(!fidelity);
    };
    return (
        <div className="fidelity-form">
            <WidgetHead icon = {AssetsKnowledge.IMAGES_PATH.AREA_SECTION_D}>{t("my-account-main-view.account.account-fidelity.title")}</WidgetHead>
            <div className='switch-button'>
                <input
                    id="switch-label"
                    type="checkbox"
                    checked={fidelity}
                    onChange={handleChange}
                />
                <label 
                    htmlFor="switch-label" 
                    className={classLabel}
                />
                <span>{t("my-account-main-view.account.account-fidelity.text")}</span>
            </div>
            
        </div>
    );
};

export default FidelityForm;
