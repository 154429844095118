import React, { FC, useState } from 'react';
import { useTranslation } from "react-i18next";

interface PasswordInputProps {
    tag?: string
    status?: string;
    value: string;
    errors: string [];
    onChange: (string) => void;
}

const PasswordInput: FC<PasswordInputProps> = ({tag, status, value, errors, onChange}) => {
    const {t} = useTranslation();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [text, setText] = useState<string>(value);
    const [isFocused, setFocused] = useState(false);

    if (text != value && text == null) {
        setText(value);
    }
    const handleChange = (event) => {
        const valueEvent: string = event.target.value;
        onChange(valueEvent);
        setText(valueEvent);
    };
    const handleFocus = (event) => {
        setFocused(true);
    };
    const handleBlur = () => {
        setFocused(false);
    };

    const inputClass: string = status ?? '';
    const activeClass = text || isFocused ? ' label-active' : '';
    
    const iconClass = showPassword ? 'edm-icon-eye-slash-regular' : 'edm-icon-eye-regular';

    return (
        <div className="password-input">
            <i 
                className={`password-input__show-icon ${iconClass}`}
                onClick={() => setShowPassword(!showPassword)}
                ></i>
            <input className={inputClass + activeClass}
                   type={showPassword ? "text" : "password"}
                   value={text ?? ''}
                   onChange={handleChange}
                   onFocus={handleFocus}
                   onBlur={handleBlur}
            />
            {tag != null && <label>{t(tag)}</label>}
            {errors != null && errors.length > 0 && text != '' && text != null &&
              <div className="password-input__errors">
                  {errors.map((error) => <div
                      key={error}>{t("my-account-main-view.account.account-password.error." + error)}</div>)}
              </div>
            }
        </div>
    );
};

export default PasswordInput;

