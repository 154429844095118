import React, { FC, useState } from 'react';
import { useTranslation } from "react-i18next";
import type { CustomerFriendDto } from "services/openapi";
import { MyAccountStatus } from "services";
import { NotificationKnowledge } from "knowledge";
import { Notification, WidgetButton, WidgetHead} from "components";
import { useNotification } from "lib";
import AccountFriendItem from "./components/AccountFriendItem/AccountFriendItem";

interface AccountFriendsProps {
}

const fGenKey = (f: CustomerFriendDto, index: number) =>
    [f.birthdate, f.firstName, f.hashFriend, f.idCustFriend, f.lastName, index]
    .map((x) => x == null ? '' : '' + x).join("$");

const AccountFriends: FC<AccountFriendsProps> = () => {
    const {t} = useTranslation();
    const [friendsData, setFriendsData] = useState<CustomerFriendDto[]>(structuredClone(MyAccountStatus.friends));
    const {notification, setNotification, handleHideNotification} = useNotification();
    const handleAddFriend = () => {
        const emptyFriend: CustomerFriendDto = {
            visible: true
        };
        setFriendsData([...friendsData, emptyFriend]);
    };
    const handleDeleteFriend = (id: number) => () => {
        friendsData[id].visible = false;
        setFriendsData([...friendsData]);
    }
    const handleSave = () => {
        MyAccountStatus.saveFriends(friendsData).then((error) => {
            if (error) {
                setNotification("my-account-main-view.account.save-fail", NotificationKnowledge.NOTIFICATION_LEVELS.ERROR);
            } else {
                setNotification("my-account-main-view.account.save-success", NotificationKnowledge.NOTIFICATION_LEVELS.SUCCESS);
            }
        }).catch((error) => {
            setNotification("my-account-main-view.account.save-fail", NotificationKnowledge.NOTIFICATION_LEVELS.ERROR);
        });
    }

    const handleRevert = () => {
        setFriendsData(structuredClone(MyAccountStatus.friends));
    }
    return (
        <div className="account-friends">
            <WidgetHead cssIcon="edm-icon-users color-grey-dark-1">
                {t("my-account-main-view.account.account-friends.title")}
            </WidgetHead>
            <div className="account__advise">
                <i className="edm-icon-info-circled"></i>
                {t("my-account-main-view.account.account-friends.advice")}
            </div>
            {
            friendsData.map(
                (friend, index) => 
                <AccountFriendItem 
                key={fGenKey(friend, index)} 
                friend={friend}
                handleDelete={handleDeleteFriend(index)}
                />
            )
            }
            <div className="account-friends-buttons my-account-margin-30">
                <button 
                    className="account-friends-buttons__add my-account-global-btn-01"
                    onClick={handleAddFriend}>
                    {t("my-account-main-view.account.account-friends.add")}
                </button>
                <WidgetButton 
                className="account-friends-buttons__save my-account-global-btn-02"
                onClick={handleSave}>
                    {t("my-account-main-view.account.save")}
                </WidgetButton>
                <button 
                className="account-friends-buttons__revert my-account-global-btn-01"
                onClick={handleRevert}>
                    {t("my-account-main-view.account.revert")}
                </button>
                
            </div>
            {notification.tag.length > 0 &&
                  <Notification input={notification} handleHideNotification={handleHideNotification}/>
            }
        </div>
    );
};

export default AccountFriends;
