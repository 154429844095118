import React, { FC } from 'react';
import PrincipalModalLogin from "./components/PrincipalModalLogin/PrincipalModalLogin";
import PrincipalModalMyAccountRegister
    from "./components/PrincipalModalMyAccountRegister/PrincipalModalMyAccountRegister";
import { StaticParamsInstance } from "services";
import PrincipalModalCustomerArea from "./components/PrincipalModalCustomerArea/PrincipalModalCustomerArea";

interface NonLoggedMainViewProps {}

const NonLoggedMainView: FC<NonLoggedMainViewProps> = () => (
  <div className="non-logged-main-view">
      <div className="non-logged-main-view-container">
        <PrincipalModalLogin/>
        {StaticParamsInstance.hideWelcomeCustomerArea==false &&  <PrincipalModalMyAccountRegister/>}
      </div>
      <PrincipalModalCustomerArea/>
  </div>
);

export default NonLoggedMainView;

