import React, { FC, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { MyAccountStatus, WidgetConfig } from "services";
import MyAccountModal from "./MyAccountModal";
import { useRefresh } from "lib";

interface MyAccountProps {
    tenantId: string;
    viewMode: string;
    mode: string;
    urlNewBooking: string;
    urlCheckIn: string;
    selectedLanguage: string;
    hideNewBookingButton: boolean;
}

const MyAccountWebComponent: FC<MyAccountProps> = ({
                                                       tenantId,
                                                       viewMode,
                                                       mode,
                                                       urlNewBooking,
                                                       urlCheckIn,
                                                       selectedLanguage,
                                                       hideNewBookingButton
                                                   }) => {

    useRefresh();
    const {t} = useTranslation();
    const myRef:any = useRef(null);
    const textKey = MyAccountStatus.mainButtonText();

    //set tenantId
    useEffect(() => {
        MyAccountStatus.setComponentConfig(tenantId, viewMode,mode, selectedLanguage, urlNewBooking, urlCheckIn, hideNewBookingButton);
    }, [tenantId, viewMode,mode, selectedLanguage, urlNewBooking, urlCheckIn, hideNewBookingButton]);

    const handleClick = () => {
        MyAccountStatus.openMainView();
    }

    const handleOverlayClick = () => {
        MyAccountStatus.closeMainView();
    }



    if(myRef.current!=null) {
      if(WidgetConfig.fontColor2!=null) {
        myRef.current.style.setProperty('--widget-font-color', WidgetConfig.fontColor2);
      }
      if(WidgetConfig.backgroundColor != null){
      myRef.current.style.setProperty('--widget-background-color', WidgetConfig.backgroundColor);
      }
      myRef.current.style.setProperty('--widget-color-green', '#86d993');
      myRef.current.style.setProperty('--widget-color-green-2', '#28a745');
      myRef.current.style.setProperty('--widget-color-red', '#dc3545');
      myRef.current.style.setProperty('--widget-color-grey', '#ccc');
      myRef.current.style.setProperty('--widget-color-grey-dark-1', '#333333');
      myRef.current.style.setProperty('--widget-color-grey-dark-2', '#ededed');
      myRef.current.style.setProperty('--widget-color-grey-dark-3', '#999999');
    }

    return (
        <div ref={myRef} >
            <div
                className={`my-account-overlay-empty-space${MyAccountStatus.isMainViewOpen && ' active'}`}
                onClick={handleOverlayClick}
            ></div>
            <div className="my-account">
                <button onClick={handleClick}>
                    <i className="edm-icon-user"></i>
                    {t(textKey)}
                </button>
            </div>
            <MyAccountModal isOpen={MyAccountStatus.isMainViewOpen} isLogged={MyAccountStatus.isLogged}/>
        </div>
    );
};

export default MyAccountWebComponent;
