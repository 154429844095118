import React, { FC, useState } from 'react';
import { useTranslation } from "react-i18next";
import { ContactKnowledge } from "knowledge";
import { WidgetHead } from "components";
import ContactRequestAvailability from "../ContactRequestAvailability/ContactRequestAvailability";
import ContactRequestBooking from "../ContactRequestBooking/ContactRequestBooking";
import ContactRequestClaim from "../ContactRequestClaim/ContactRequestClaim";
import ContactRequestPhoneNumber from "../ContactRequestPhoneNumber/ContactRequestPhoneNumber";

interface ContactRequestProps {
}

const ContactRequest: FC<ContactRequestProps> = () => {
    const {t} = useTranslation();
    const [selectedOption, setSelectedOption] = useState("");
    const [sent, setSent] = useState<boolean>(false);

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    const handleSendAnother = () => {
        setSent(false);
    }
    const handleSuccessSent = () => {
        setSent(true);
    }

    if (!sent) {
        return (
            <div className="contact-request">
                <WidgetHead cssIcon="edm-icon-mail-bulk color-grey-dark-1">
                    {t("my-account-main-view.contact.contact-form.title")}
                </WidgetHead>
                <div className="contact-request__select__label">
                    {t("my-account-main-view.contact.contact-form.define-request")}
                </div>
                <select 
                    name="selectOption" 
                    id="selectOption" 
                    value={selectedOption} 
                    onChange={handleOptionChange} 
                    className="background-color-grey-dark-2"
                    >
                    <option disabled
                            value="">{t("my-account-main-view.contact.contact-form.selector.choose-option")}</option>
                    <option
                        value={ContactKnowledge.CONTACT_REQUEST_OPTIONS.AVAILABILITY_REQUEST}>{t("my-account-main-view.contact.contact-form.selector.availability-request")}</option>
                    <option
                        value={ContactKnowledge.CONTACT_REQUEST_OPTIONS.BOOKING}>{t("my-account-main-view.contact.contact-form.selector.booking")}</option>
                    <option
                        value={ContactKnowledge.CONTACT_REQUEST_OPTIONS.CLAIM}>{t("my-account-main-view.contact.contact-form.selector.claim")}</option>
                    <option
                        value={ContactKnowledge.CONTACT_REQUEST_OPTIONS.PHONE_NUMBER}>{t("my-account-main-view.contact.contact-form.selector.phone-number")}</option>
                </select>
                {selectedOption == ContactKnowledge.CONTACT_REQUEST_OPTIONS.AVAILABILITY_REQUEST &&
                  <ContactRequestAvailability handleSuccessSent={handleSuccessSent}/>
                }
                {selectedOption == ContactKnowledge.CONTACT_REQUEST_OPTIONS.BOOKING &&
                  <ContactRequestBooking handleSuccessSent={handleSuccessSent}/>
                }
                {selectedOption == ContactKnowledge.CONTACT_REQUEST_OPTIONS.CLAIM &&
                  <ContactRequestClaim handleSuccessSent={handleSuccessSent}/>
                }
                {selectedOption == ContactKnowledge.CONTACT_REQUEST_OPTIONS.PHONE_NUMBER &&
                  <ContactRequestPhoneNumber handleSuccessSent={handleSuccessSent}/>
                }
            </div>
        );
    } else {
        return (<div className="contact-request">
            <WidgetHead>{t("my-account-main-view.contact.contact-form.title")}</WidgetHead>
            <div
                className="contact-request-sent__text">{t("my-account-main-view.contact.contact-form.successfully-sent")}</div>
            <button onClick={handleSendAnother}>{t("my-account-main-view.contact.contact-form.sent-another")}</button>
        </div>)
    }
};

export default ContactRequest;
