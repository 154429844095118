export class UniversalNaming {
    static ADDRESS_1= "address1";
    static ADDRESS_2= "address2";
    static BIRTH_DATE= "birthDate";
    static BIRTH_DATE_m= "birthdate";
    static CITY= "city";
    static CIVILITY= "civility";
    static COUNTRY= "country";
    static EMAIL= "email";
    static FAX= "fax";
    static FIDELITY= "fidelity";
    static FIRST_NAME= "firstName";
    static LANGUAGE= "language";
    static LAST_NAME= "lastName";
    static MOBILE_PHONE= "mobilePhone";
    static PHONE= "phone";
    static POST_CODE= "postCode";
    static SUBSCRIBE_TO_FIDELITY="subscribeToFidelity";
    static LICENSE_PLATE="licensePlate";


}
