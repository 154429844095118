import React from 'react';
import { WidgetConfig } from "services";



const WidgetButton = (props) =>{
    const tempProps = {...props};
    const backgroundColor = WidgetConfig.backgroundColor;
    if(backgroundColor != null && backgroundColor.length > 0) {
        if(tempProps['style'] == null){
            tempProps['style'] = {};
        }
        tempProps['style'] ={...tempProps['style'], backgroundColor: backgroundColor};
    }
    return  (
        <button {...tempProps}   />
    );
};


export default WidgetButton;
