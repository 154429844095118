import { useTranslation } from "react-i18next";
import FormFieldError from "../FormFieldError/FormFieldError";
import { FC, useState } from "react";
import { PersonKnowledge } from "../../../knowledge";


interface InputFormikProps {
    type?: string;
    tag?: string;
    field: string;
    formik: any;

    [otherProp: string]: any; // to allow any prop

}

const InputFormik: FC<InputFormikProps> = (props) => {
    const {t, i18n} = useTranslation();
    const [hasFocus, setHasFocus] = useState(false);
    const tag = props.tag ?? null;
    const isRequired = PersonKnowledge.REQUIRED_FIELDS.includes(props.field);
    const fieldProps = {...props.formik.getFieldProps(props.field)}
    const inputProps = {...props, ...fieldProps};

    const handleFocus = (e) => {
        setHasFocus(true);
    }

    const handleBlur = (e) => {
        setHasFocus(false);
        if (inputProps.onBlur) {
            inputProps.onBlur(e);
        }
    }
    
    if (inputProps.type == 'text') {
        inputProps.className = (inputProps.className ?? '') + " input-text";
    } else if (inputProps.type == 'date') {
        inputProps.className = (inputProps.className ?? '') + " input-date";
    } else if (inputProps.type == 'number') {
        inputProps.className = (inputProps.className ?? '') + " input-number";
    }
    if (hasFocus || (inputProps.value != null && inputProps.value != '')) {
        inputProps.className = (inputProps.className ?? '') + " label-active";
    }

    if(isRequired){
        inputProps.className = (inputProps.className ?? '') + " required";
    }
    const hasError = props.formik.getFieldMeta(props.field).error != null;
    /*
    <div className={`input-text ${hasError && ' label-active'}`}>

    {hasError && (
        <span className="has-error">
    )}
    */
    return (
        <span className={`${hasError ? 'has-error' : ''}`}>
            <div className="input-text">
                <input
                    {...inputProps}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
                {tag != null && i18n.exists(tag) && 
                <label className={`${isRequired?'required':''}`}>
                    {t(tag)}
                    <FormFieldError formik={props.formik} field={props.field}/>
                </label>}
            </div>
        </span>
    );
}

export default InputFormik;
