export class MonCompteApiUtils{
    static processPossibleApiError(response: any) {
        if (response.ok) {
            return; // do nothing
        }
        const errorStatus = response.status ?? 'unknown';
        const errorStatusText = response.statusText ?? 'unknown';

        let bodyStr = '';
        try {
            bodyStr += JSON.stringify(response.body, null, 2);
        } catch (err: any) {
            bodyStr += (err?.message ?? '');
        }
        throw new Error(`Api Error: status: ${errorStatus}; status text: ${errorStatusText}; body: ${bodyStr}`);
    }
}