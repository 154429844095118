import i18next from "i18next";
import {
    BookingDto, type CustomerFriendDto,
    type CustomerVehicleDto,
    PersonDto, RqUpdateCustomerFriends, type RqUpdateCustomerVehicles, RqUpdatePerson,
    RsGetBookingList, RsGetCustomerFriends,
    type RsGetCustomerVehicles, RsGetFidStream,
    type RsGetPerson, type RsUpdateCustomerFriends, type RsUpdateCustomerVehicles, RsUpdatePerson
} from "../openapi";
import {MonCompteApiKnowledge, StatusKnowledge} from "knowledge";
import { MonCompteApiUtils } from "lib";
import { URL_SOURCE } from "../url-source";


class AccountResourcesService {

    private _person: PersonDto;
    private _fidelityData: RsGetFidStream;
    private _tokenFid: string;
    private _bookings: BookingDto[];
    private _vehicles: CustomerVehicleDto[];
    private _friends: CustomerFriendDto[];

    constructor() {
        this._person = {};
        this._fidelityData = {};
        this._tokenFid = '';
        this._bookings = [];
        this._vehicles = [];
        this._friends = [];
    }


    get person(): PersonDto {
        return this._person;
    }


    get fidelityData(): RsGetFidStream {
        return this._fidelityData;
    }

    get bookings(): BookingDto[] {
        return this._bookings;
    }

    get bookingIdOrders(): string[]{
        return this._bookings
        .filter((bo)=>bo.idOrder!=null && bo.idOrder.length>0)
            .map((bo)=>bo.idOrder as string);
    }

    get tokenFid(): string {
        return this._tokenFid;
    }

    get vehicles(): CustomerVehicleDto[] {
        return this._vehicles;
    }

    get friends(): CustomerFriendDto[] {
        return this._friends;
    }

    clearAllData() {
        this._person = {};
        this._fidelityData = {};
        this._tokenFid = '';
        this._bookings = [];
        this._vehicles = [];
    }

    private  setLanguage( selectedLanguage: string) {
        if(selectedLanguage == null){
            return; //do nothign
        }
        if(StatusKnowledge.ALLOWED_LANGUAGES.includes(selectedLanguage)!=true){
            console.log('non valid language '+selectedLanguage);
            return;
        }
        if (i18next.language !== selectedLanguage) {
            i18next.changeLanguage(selectedLanguage);
        }
    }

    async loadPersonData(tenantId: string, sessionId: string) {

        const response: any = await fetch(`${URL_SOURCE}/rest/account`, {
            "headers": {
                [MonCompteApiKnowledge.HTTP_HEADER_XTENANTID]: tenantId,
                "content-type": "application/json",
                "x-session-id": sessionId
            },
            "method": "GET",
        });

        MonCompteApiUtils.processPossibleApiError(response);
        const responsePerson: RsGetPerson = await response.json() as RsGetPerson;
        if (responsePerson.error != 0) {
            throw new Error(responsePerson.messError);
        }
        this._person = responsePerson.person || {};
        this.setLanguage(this._person.language  as string);
        if (responsePerson.tokenFid != '' && responsePerson.tokenFid != null) {
            this._person.fidelity = true;
            this._tokenFid = responsePerson.tokenFid;
        }
    }

    async loadFidelityData(tenantId: string, sessionId: string) {

        const language = i18next.language;
        const response: any = await fetch(`${URL_SOURCE}/rest/fid/stream?lg=${language}`, {
            "headers": {
                [MonCompteApiKnowledge.HTTP_HEADER_XTENANTID]: tenantId,
                "content-type": "application/json",
                "x-session-id": sessionId
            },
            "method": "GET",
        });

        MonCompteApiUtils.processPossibleApiError(response);
        const responseFidelity: RsGetFidStream = await response.json() as RsGetFidStream;
        if (responseFidelity.error != 0) {
            throw new Error(responseFidelity.messError);
        }
        this._fidelityData= responseFidelity;

    }

    async loadBookingsData(tenantId: string, sessionId: string) {
        const language = i18next.language;
        const response = await fetch(`${URL_SOURCE}/rest/bookings?lg=${language}`, {
            "headers": {
                [MonCompteApiKnowledge.HTTP_HEADER_XTENANTID]: tenantId,
                "content-type": "application/json",
                "x-session-id": sessionId
            },
            "method": "GET",
        });

        MonCompteApiUtils.processPossibleApiError(response);

        const responseBookings: RsGetBookingList = await response.json() as RsGetBookingList;
        if (responseBookings.error != 0) {
            throw new Error(responseBookings.messError);
        }
        this._bookings = [...(responseBookings.list || []), ...(responseBookings.shareList || [])];
    }

    async loadVehicleData(tenantId: string, sessionId: string) {

        const response = await fetch(`${URL_SOURCE}/rest/account/vehicles`, {
            "headers": {
                [MonCompteApiKnowledge.HTTP_HEADER_XTENANTID]: tenantId,
                "content-type": "application/json",
                "x-session-id": sessionId
            },
            "method": "GET",
        });

        MonCompteApiUtils.processPossibleApiError(response);

        const responseVehicles: RsGetCustomerVehicles = await response.json() as RsGetCustomerVehicles;
        if (responseVehicles.error != 0) {
            throw new Error(responseVehicles.messError);
        }
        this._vehicles = responseVehicles.list || [];
    }

    async loadFriendsData(tenantId: string, sessionId: string) {

        const response = await fetch(`${URL_SOURCE}/rest/account/friends`, {
            "headers": {
                [MonCompteApiKnowledge.HTTP_HEADER_XTENANTID]: tenantId,
                "content-type": "application/json",
                "x-session-id": sessionId
            },
            "method": "GET",
        });

        MonCompteApiUtils.processPossibleApiError(response);

        const responseFriends: RsGetCustomerFriends = await response.json() as RsGetCustomerFriends;
        if (responseFriends.error != 0) {
            throw new Error(responseFriends.messError);
        }
        this._friends = responseFriends.list || [];
    }

    async savePerson(personData: PersonDto, tenantId: string, sessionId: string): Promise<null | Error> {
        const body: RqUpdatePerson = {
            person: personData,
            subscribeToFidelity: this._person.fidelity ?? false
        };
        const response: any = await fetch(`${URL_SOURCE}/rest/account`, {
            "headers": {
                [MonCompteApiKnowledge.HTTP_HEADER_XTENANTID]: tenantId,
                "content-type": "application/json",
                "x-session-id": sessionId
            },
            "method": "PUT",
            "body": JSON.stringify(body)
        });
        MonCompteApiUtils.processPossibleApiError(response);
        const responseUpdatePerson: RsUpdatePerson = await response.json() as RsUpdatePerson;
        if (responseUpdatePerson.error != 0) {
            return new Error(responseUpdatePerson.messError);
        }
        await this.loadPersonData(tenantId, sessionId);
        return null;
    }

    async saveFriends(friendsData: CustomerFriendDto[], tenantId: string, sessionId: string): Promise<null | Error> {
        const body: RqUpdateCustomerFriends = {
            customerFriend: friendsData
        };
        const response: any = await fetch(`${URL_SOURCE}/rest/account/friends`, {
            "headers": {
                [MonCompteApiKnowledge.HTTP_HEADER_XTENANTID]: tenantId,
                "content-type": "application/json",
                "x-session-id": sessionId
            },
            "method": "PUT",
            "body": JSON.stringify(body)
        });
        MonCompteApiUtils.processPossibleApiError(response);
        const responseUpdatePerson: RsUpdateCustomerFriends = await response.json() as RsUpdateCustomerFriends;
        if (responseUpdatePerson.error != 0) {
            return new Error(responseUpdatePerson.messError);
        }
        await this.loadFriendsData(tenantId, sessionId);
        return null;
    }

    async saveVehicles(vehiclesData: CustomerVehicleDto[], tenantId: string, sessionId: string): Promise<null | Error> {
        const body: RqUpdateCustomerVehicles = {
            customerVehicle: vehiclesData
        };

        const response = await fetch(`${URL_SOURCE}/rest/account/vehicles`, {
            "headers": {
                [MonCompteApiKnowledge.HTTP_HEADER_XTENANTID]: tenantId,
                "content-type": "application/json",
                "x-session-id": sessionId
            },
            "method": "PUT",
            "body": JSON.stringify(body)
        });

        MonCompteApiUtils.processPossibleApiError(response);
        const responseUpdateVehicles: RsUpdateCustomerVehicles = await response.json() as RsUpdateCustomerVehicles;
        if (responseUpdateVehicles.error != 0) {
            return new Error(responseUpdateVehicles.messError);
        }
        await this.loadVehicleData(tenantId, sessionId);
        return null;
    }
}

const AccountResources = new AccountResourcesService();

export { AccountResources };
