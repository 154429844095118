import React, { FC } from 'react';
import { useTranslation } from "react-i18next";
import { WidgetButton, WidgetHead } from "components";
import { MyAccountStatus } from "services";
import { DateUtils } from "lib";

interface FidelityDataProps {
}

const FidelityData: FC<FidelityDataProps> = () => {
    const {t} = useTranslation();

    const fidelityData = MyAccountStatus.fidelityData;
    const from = fidelityData.begin != null && fidelityData.begin != '' ? DateUtils.formatDate(fidelityData.begin) : null;
    const gain = fidelityData.gain != null ? fidelityData.gain : 0;
    const consum = fidelityData.consum != null ? fidelityData.consum : 0;
    const points = gain - consum;
    const handleNewBooking = () => {
        MyAccountStatus.searchNewBooking();
    }
    return (
        <div className="fidelity-data">
            <WidgetHead cssIcon="edm-icon-award-solid color-grey-dark-1">
                {t("my-account-main-view.fidelity.data.title")}
            </WidgetHead>
            <div className="fidelity-data-content">
                {from != null && <div className="fidelity-data-content__from">
                    <strong>{t("my-account-main-view.fidelity.data.from-text") + ' : '}</strong>
                    {t("my-account-main-view.fidelity.data.from", {from: from})}
                </div>}
                {fidelityData.idFid != null && <div className="fidelity-data-content__card">
                    <strong>{t("my-account-main-view.fidelity.data.card-number") + ' : '}</strong>
                    {fidelityData.idFid}
                </div>}
                <div className="fidelity-data-content__card">
                    <strong>{t("my-account-main-view.fidelity.data.number-points") + ' : '}</strong>
                    {points}
                </div>
                <div className="my-account-margin-30 my-account-align-center">
                    <WidgetButton 
                        className="fidelity-data-content__new-booking-button my-account-global-btn-02"
                        onClick={handleNewBooking}>
                            <i className="edm-icon-shopping-cart-solid padding-right-10px" />
                            {t("my-account-main-view.fidelity.data.new-booking-button")}
                    </WidgetButton>
                </div>
            </div>
        </div>
    );
}

export default FidelityData;
