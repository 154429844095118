import React, { FC } from 'react';
import { BookingDetailsDto } from "services/openapi";
import { useTranslation } from "react-i18next";
import { WidgetHead } from "components";

interface BookingDetailCompositionProps {
    bookingDetails: BookingDetailsDto
}

const BookingDetailComposition: FC<BookingDetailCompositionProps> = ({bookingDetails}) => {

    const {t} = useTranslation();
    if (bookingDetails.rubrics == null || bookingDetails.rubrics.length === 0) {
        return null;
    }
    return (
        <div className="booking-detail-composition">
            <div className="booking-detail-wrapper padding-standard-1">
                <div className="booking-detail-wrapper-first">
                    <i className="edm-icon-clipboard-list color-grey-dark-3 font-size-54px"></i>
                </div>
                <div className="booking-detail-composition-data">

                    <WidgetHead size={3}>{t("my-account-main-view.stays.detail.composition")}</WidgetHead>
                    {bookingDetails.rubrics.map((rubric, index) => 
                    <div className="booking-detail-composition-item" key={index}>
                        <span>{rubric.number}</span>
                        <span>x</span>
                        <span>{rubric.label}</span>
                    </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BookingDetailComposition;
