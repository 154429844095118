import React, { FC, useState } from 'react';
import { useTranslation } from "react-i18next";

interface InputTextProps {
    tag?: string;
    disabled?: boolean;
    value: any;
    onChange: (any) => void;
}

const InputText: FC<InputTextProps> = ({tag,disabled, value, onChange}) => {
    
    const [isFocused, setFocused] = useState(false);

    const {t} = useTranslation();
    const [text, setText] = useState<string>(value);
    if (text != value && text ==null) {
        setText(value);
    }
    const handleChange = (event) => {
      const valueEvent = event.target.value;
        onChange(valueEvent);
        setText(valueEvent);
    };
    const handleFocus = (event) => {
        setFocused(true);
    };
    const handleBlur = () => {
        setFocused(false);
    };

    return (
        <div className="input-text">
            <input
                type="text"
                value={text ?? ''}
                disabled={disabled === true}
                className={text || isFocused ? 'label-active' : ''}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
            {tag != null && <label>{t(tag)}</label>}
        </div>
    );
};

export default InputText;
