export class WidgetConfigKnowledge {

    public static readonly VIEW_MODE_DARK: string = 'dark';
    public static readonly VIEW_MODE_LIGHT: string = 'light';
    public static readonly DEFAULT_VIEW_MODE: string = 'light';


    public static readonly URL_SITE_PASS: string = 'URL_SITE_PASS';
    public static readonly LIBELLE_SITE_PASS: string = 'LIBELLE_SITE_PASS';

    public static SUBSCRIPTIONS = {
        FIDELITY: "FIDELITY",
        KPI_PERF: "KPI_PERF",
        GRITCHEN: "gritchen",
        DEDUP: "DEDUP",
        EAXESS: "eaxess",
        PRICING: "PRICING",
        THELIS_UNIXDATA: "thelisunixdata",
        PRECHECKIN: "PRECHECKIN",
        EMAIL_TRANS: "EMAIL_TRANS",
        UNIXDATA_PRECHECKIN: "UNIXDATA_PRECHECKIN",
        QUALITELIS: "qualitelis",
        QUALITELIS_CRM: "QUALITELIS_CRM",
        RETARGETING: "RETARGETING"
    };

    public static WIDGET_MODES={
        MASTER_CAMPING:'masterCamping',
        UNIX_DATA:'unixData'
    }
    public static readonly DEFAULT_MODE: string = 'masterCamping';
}
