import React, { FC } from 'react';
import { BookingDetailsDto, BookingDto } from "services/openapi";
import { useTranslation } from "react-i18next";
import { DateUtils } from "lib";
import { AccommodationTypePicture, WidgetHead } from "components";

interface BookingDetailEditDataProps {
    booking: BookingDto;
    bookingDetails: BookingDetailsDto
}

const BookingDetailEditData: FC<BookingDetailEditDataProps> = ({booking, bookingDetails}) => {
    const {t} = useTranslation();
    const begin: string | null = booking.begin != null ? DateUtils.formatDate(booking.begin) : null;
    const end: string | null = booking.end != null ? DateUtils.formatDate(booking.end) : null;

    return (
        <div className="booking-detail-edit-data">
            <div className="booking-detail-edit-data__wrapper">
                <div className="booking-detail-edit-data__picture-container">
                    <AccommodationTypePicture idOrder={booking.idOrder}/>
                </div>

                <div className="booking-detail-edit-data__info">
                    <WidgetHead size={4}>{t("my-account-main-view.stays.detail.profile-data-edit.profile.details")}</WidgetHead>
                    {
                        bookingDetails.site != null &&
                        <div className="booking-detail-edit-data__site my-account-global-title-and-icon">
                            <i className="edm-icon-campground"></i>
                            <strong>{bookingDetails.site}</strong>{bookingDetails.city != null &&
                            <span> {' > ' + bookingDetails.city}</span>}
                        </div>
                    }
                    {
                        booking.idOrder != null &&
                        <div className="booking-detail-edit-data__idOrder my-account-global-title-and-icon">
                            <i className="edm-icon-file"></i>
                            {booking.idOrder}
                        </div>
                    }
                    {
                        bookingDetails.libcateg != null &&
                        <div className="booking-detail-edit-data__libcateg my-account-global-title-and-icon">
                            <i className="edm-icon-home"></i>
                            {bookingDetails.libcateg}
                        </div>
                    }
                    {
                        begin != null && end != null &&
                        <div className="booking-detail-data__calendar my-account-global-title-and-icon">
                            <i className="edm-icon-date"></i>
                            {t("my-account-main-view.stays.form.calendar", {begin: begin, end: end})}
                        </div>
                    }
                
                    <div className="booking-detail-edit-data__nbPers my-account-global-title-and-icon">
                        <i className="edm-icon-users2"></i>
                        {t("my-account-main-view.stays.form.persons", {count: (bookingDetails.nbPers ?? 0)})}
                    </div>

                    <div
                        className="ooking-detail-edit-data__rice-deposit my-account-global-title-and-icon"><i
                        className="edm-icon-euro"></i>{t("my-account-main-view.stays.detail.profile-data-edit.profile.price-detail",
                        {deposit: booking.deposit ?? 0, price: booking.price ?? 0})}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BookingDetailEditData;
