import React from 'react';
import ReactDOM from 'react-dom/client';
import './services/i18n';
import MyAccountWebComponent from "./view/MyAccountWebComponent";
import { StatusKnowledge, WidgetConfigKnowledge } from "./knowledge";
import { loadAssetsFromSource, StaticParamsInstance, URL_SOURCE } from "./services";


class MyAccountWebcomponent extends HTMLElement {

    async connectedCallback() {

        await loadAssetsFromSource(this);

        const tenantIdAttr: any = this.attributes.getNamedItem('tenant-id');
        if (tenantIdAttr == null) {
            throw new Error('tenantId is required');
        }
        const tenantId=tenantIdAttr.value;
        if(tenantId==null || tenantId==''){
           console.error('tenantId is required');
           return;
        }

        await StaticParamsInstance.loadParams(tenantId);

        let selectedLanguage:string|null = null;
        const selectedLanguageAttr = this.attributes.getNamedItem('language');
        if(selectedLanguageAttr != null){
            const selectedLanguageByAttributes = selectedLanguageAttr.value;
            if (StatusKnowledge.ALLOWED_LANGUAGES.includes(selectedLanguageByAttributes)) {
                selectedLanguage = selectedLanguageAttr.value;
            }
        }
        if(selectedLanguage == null){
            const browserLanguage = navigator.language.slice(0, 2);
            if (StatusKnowledge.ALLOWED_LANGUAGES.includes(browserLanguage)) {
                selectedLanguage = browserLanguage;
            }
        }


        if (selectedLanguage == null && StaticParamsInstance.language != null) {
            if (StatusKnowledge.ALLOWED_LANGUAGES.includes(StaticParamsInstance.language)) {
                selectedLanguage = StaticParamsInstance.language;
            }
        }
        if (selectedLanguage == null) {
            selectedLanguage = StatusKnowledge.DEFAULT_LANGUAGE;
        }

        const mountPoint = document.createElement('div');
        this.attachShadow({mode: 'open'}).appendChild(mountPoint);

        const root = ReactDOM.createRoot(mountPoint);
        const indexHref = URL_SOURCE + '/index.css';
        const leafletHref = URL_SOURCE + '/leaflet.css';

        root.render(<>
            <link rel="stylesheet" href={indexHref}/>
            <link rel="stylesheet" href={leafletHref}/>
            {StaticParamsInstance.customStyle != null && <link rel="stylesheet" href={StaticParamsInstance.customStyle}/>}
            <MyAccountWebComponent tenantId={tenantId}
                                   viewMode={StaticParamsInstance.viewMode}
                                   mode={StaticParamsInstance.mode}
                                   urlNewBooking={StaticParamsInstance.urlNewBooking}
                                   selectedLanguage={selectedLanguage as string}
                                   urlCheckIn={StaticParamsInstance.urlCheckin}
                                   hideNewBookingButton={StaticParamsInstance.hideNewBookingButton}></MyAccountWebComponent></>);
    }

}

customElements.define('my-account', MyAccountWebcomponent);


