import { ContextCampingDto, RsGetContextCamping } from "../openapi";
import { MonCompteApiKnowledge } from "knowledge";
import { MonCompteApiUtils } from "lib";
import { URL_SOURCE } from "../url-source";

class PropertyResourcesService{
    private _propertyInfo:ContextCampingDto

    constructor() {
        this._propertyInfo={};
    }

    get propertyInfo(): ContextCampingDto {
        return this._propertyInfo;
    }

    clearAllData() {
        this._propertyInfo={};
    }

    async loadPropertyInfo(tenantId: string, sessionId: string) {
        const response:any = await fetch(`${URL_SOURCE}/rest/context/camping`, {
            "headers": {
                [MonCompteApiKnowledge.HTTP_HEADER_XTENANTID]:tenantId,
                "content-type": "application/json",
                "x-session-id": sessionId
            },
            "method": "GET",
        });

        MonCompteApiUtils.processPossibleApiError(response);
        const responsePerson: RsGetContextCamping = await response.json() as RsGetContextCamping;
        if(responsePerson.error!=0){
            throw new Error(responsePerson.messError);
        }
        this._propertyInfo = responsePerson.contextCamping || {};
    }


}

const PropertyResources = new PropertyResourcesService();

export { PropertyResources };
