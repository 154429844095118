import React, { FC, useState } from 'react';
import { useTranslation } from "react-i18next";


interface InputDateProps {
    tag?: string;
    value: any;
    disabled?: boolean;
    onChange: (any) => void;
}

const InputDate: FC<InputDateProps> = ({tag, value,disabled, onChange}) => {
    const safeValue = value ?? '';
    const {t} = useTranslation();
    const [dateStr, setDateStr] = useState<string>(safeValue );

    if (dateStr != safeValue && dateStr == '') {
        setDateStr(safeValue);
    }
    const handleChange = (event) => {
        const valueEvent = event.target.value;
        onChange(valueEvent);
        setDateStr(valueEvent);
    };
    return (
        <div className="input-date">
            <input
                type="date"
                disabled={disabled === true}
                className="label-active"
                value={dateStr}
                onChange={handleChange}
            />
            {tag != null && <label>{t(tag)}</label>}
        </div>
    );
};

export default InputDate;
