import React, { FC , useState} from 'react';
import { useTranslation } from "react-i18next";
import { MyAccountStatus, WidgetConfig } from "services";
import { StatusKnowledge, AssetsKnowledge } from "knowledge";
import { ImageWithValidator } from 'components';

interface ModalHeaderProps {
    isLogged: boolean
}

const ModalHeader: FC<ModalHeaderProps> = ({isLogged}) => {
    const {t} = useTranslation();
    const onClose = () => MyAccountStatus.closeMainView();
    const onLogout = () => MyAccountStatus.logout();
    const isRecoveringPassword = (MyAccountStatus.currentView == StatusKnowledge.VIEW_SECTIONS.NON_LOGGED.RECOVERY_PASSWORD);
    const isRegistering = (MyAccountStatus.currentView == StatusKnowledge.VIEW_SECTIONS.NON_LOGGED.REGISTER);

    const backgroundColor = WidgetConfig.backgroundColor;
    const style = backgroundColor != null && backgroundColor.length > 0 ? {backgroundColor: backgroundColor} : {};

   
    return (
        <div className="principal-modal-header" style={style}>
            <button onClick={onClose}><ImageWithValidator picture={AssetsKnowledge.IMAGES_PATH.CLOSE} width="30px"></ImageWithValidator></button>
            {isLogged && <div>
                <span className='principal-modal-header__welcome'>{t("my-account-main-view.header.welcome")}</span>
                <button onClick={onLogout}><ImageWithValidator picture={AssetsKnowledge.IMAGES_PATH.LOGOUT} width="25px"></ImageWithValidator></button>
            </div>}
            {(!isLogged && !isRecoveringPassword && !isRegistering) &&
              <div><span className='principal-modal-header__connect'>{t("my-account-main-view.header.to-connect")}</span></div>}
            {(!isLogged && isRegistering) && <div><span>{t("my-account-main-view.header.register")}</span></div>}
        </div>
    );

}
export default ModalHeader;
