import React, {FC, useState} from 'react';
import {useTranslation} from "react-i18next";
import {MyAccountStatus, PasswordGroup} from "services";
import {Notification, PasswordInput, WidgetButton, WidgetHead} from "components";
import {NotificationKnowledge, StatusKnowledge} from "knowledge";
import {useNotification} from "lib";

interface RecoveryPasswordProps {
}

const SetNewPassword: FC<RecoveryPasswordProps> = () => {
    const {t} = useTranslation();
    const [isOk, setIsOk] = useState(false);
    const [password, setPassword] = useState<PasswordGroup>(PasswordGroup.generateInitialState());
    const {notification, setNotification, handleHideNotification} = useNotification();


    const handleOk = () => {

        const newPassword = password.newPassword;

        MyAccountStatus.setNewPassword(newPassword)
            .then(() => {
                setIsOk(true)
            })
            .catch((error) => {
                setNotification("my-account-main-view.account.save-fail", NotificationKnowledge.NOTIFICATION_LEVELS.ERROR);
            });

    }
    const handleCancel = () => {
        MyAccountStatus.navigate(StatusKnowledge.VIEW_SECTIONS.NON_LOGGED.NON_LOGGED_MAIN);
    }

    const handleNewPasswordChange = (newValue) => {
        const newState = password.setNewPassword(newValue);
        setPassword(newState);
    };

    const handleConfirmPasswordChange = (newValue) => {
        const newState = password.setConfirmPassword(newValue);
        setPassword(newState);
    };

    return (
        <div className="set-new-password">
            <WidgetHead size={2}>{t("my-account-main-view.set-new-password.title")}</WidgetHead>

            {!isOk && <>
                <div className="principal-modal-register-account-password">

                    <div
                        className="account-change-password__advise">{t("my-account-main-view.account.account-password.text")}</div>
                    <div className="account-change-password__inputs">
                        <PasswordInput tag={"my-account-main-view.account.account-password.new-password"}
                                       status={password.newPasswordStatus}
                                       value={password.newPassword} errors={password.newPasswordErrors}
                                       onChange={handleNewPasswordChange}/>

                        <PasswordInput tag={"my-account-main-view.account.account-password.confirm-password"}
                                       status={password.confirmPasswordStatus}
                                       value={password.confirmPassword} errors={password.confirmPasswordErrors}
                                       onChange={handleConfirmPasswordChange}/>
                    </div>
                </div>
                <div className="set-new-password__buttons">
                    <button onClick={handleCancel}
                            className="my-account-global-btn-01">{t("my-account-main-view.set-new-password.cancel")}</button>
                    <WidgetButton onClick={handleOk}
                                  className="my-account-global-btn-02">{t("my-account-main-view.set-new-password.send")}</WidgetButton>
                </div>
            </>}
            {isOk && <>
                <div>{t("my-account-main-view.set-new-password.successfully-sent")}</div>
                <button onClick={handleCancel}>{t("my-account-main-view.set-new-password.return")}</button>
            </>}
            <Notification input={notification} handleHideNotification={handleHideNotification}/>
        </div>
    );
}

export default SetNewPassword;
