import React, { FC, useState } from 'react';
import { useTranslation } from "react-i18next";
import { ContactRequestService } from "services";
import { WidgetButton, Notification } from "components";
import { NotificationKnowledge } from "knowledge";
import { useNotification } from "lib";

interface ContactRequestPhoneNumberProps {
    handleSuccessSent: () => void;
}

const ContactRequestPhoneNumber: FC<ContactRequestPhoneNumberProps> = ({handleSuccessSent}) => {
    const {t} = useTranslation()
    const [comment, setComment] = useState('');
    const {notification, setNotification, handleHideNotification} = useNotification();

    const formCorrect = comment !== '';

    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };

    const handleSend = () => {
        ContactRequestService.contactRequestPhoneNumber(comment).then((error) => {
            if (error) {
                setNotification("my-account-main-view.account.save-fail", NotificationKnowledge.NOTIFICATION_LEVELS.ERROR);
            } else {
                setNotification("my-account-main-view.account.save-success", NotificationKnowledge.NOTIFICATION_LEVELS.SUCCESS);
            }
        }).catch((error) => {
            setNotification("my-account-main-view.account.save-fail", NotificationKnowledge.NOTIFICATION_LEVELS.ERROR);
        });
    };


    return (
        <div className="contact-request-phone-number input-text">
            <div className="contact-request-comment">
                <textarea
                    id="commentInput"
                    value={comment}
                    onChange={handleCommentChange}
                    rows={4}
                    cols={50}
                    className="label-active"
                />
                <label>{t("my-account-main-view.contact.contact-form.comment")}</label>
            </div>
            <WidgetButton disabled={!formCorrect}
                          onClick={handleSend} className="my-account-global-btn-02">{t("my-account-main-view.contact.contact-form.send")}</WidgetButton>
            <Notification input={notification} handleHideNotification={handleHideNotification}/>
        </div>
    );
};

export default ContactRequestPhoneNumber;
