import React, { FC } from 'react';
import { BookingDto } from "services/openapi";
import { useTranslation } from "react-i18next";
import PastStayItem from "./components/PastStayItem/PastStayItem";
import { WidgetHead } from "components";


interface ModalStaysPastStaysProps {
    bookings:BookingDto[];
}

const ModalStaysPastStays: FC<ModalStaysPastStaysProps> = ({bookings}) =>{
    const {t} = useTranslation();
    if (bookings.length === 0) {
        return <></>;
    }
    return  (
        <div className="modal-stays-past-stays">
            <WidgetHead className="modal-stays-past-stays__title">{t("my-account-main-view.stays.past.title")}</WidgetHead>
            <div className="modal-stays-past-stays__list">
                {bookings.map((booking, index) => <PastStayItem key={booking.idOrder ?? index} booking={booking}/>)}
            </div>
        </div>
    );
};

export default ModalStaysPastStays;
